import { Subject, debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { HolidayCalendarService } from '../../features/holiday-calendar/services/holiday-calendar.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatSelectModule } from '@angular/material/select';
import { HolidayTypes } from '../../../assets/holidayTypes';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-holiday',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    AgGridAngular,
  ],
  templateUrl: './holiday.component.html',
  styleUrl: './holiday.component.scss',
})
export class HolidayComponent implements OnInit {
  isDropdownOpen = false;
  rowData: any[] = [];
  public loading: boolean = true;
  public error: any = null;
  private searchSubject = new Subject<string>();

  readonly dialog = inject(MatDialog);

  columnDefs = [
    { headerName: 'Occasion', field: 'holidayName', flex: 1 },
    { headerName: 'Date', field: 'date', flex: 1 },
    { headerName: 'Day', field: 'day', flex: 1 },

    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      cellRenderer: (params: any) => {
        const div = document.createElement('div');
        div.style.display = 'flex';
        div.style.alignItems = 'center';
        div.style.gap = '20px';

        const createButton = (iconSrc: string, onClick: () => void) => {
          const span = document.createElement('span');
          span.style.cursor = 'pointer';
          span.style.display = 'flex';
          span.innerHTML = `<img src="${iconSrc}"/>`;
          span.addEventListener('click', onClick);
          return span;
        };
        div.appendChild(
          createButton('assets/images/icons/icon-edit.svg', () =>
            this.openEditDialog(params?.data)
          )
        );
        div.appendChild(
          createButton('assets/images/icons/icon-delete.svg', () =>
            this.onRemoveHoliday(params.data.id)
          )
        );

        return div;
      },
    },
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  constructor(
    private holidayService: HolidayCalendarService,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.fetchHolidayCalendar();

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchHolidayCalendar('', '', searchTerm);
      });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  private fetchHolidayCalendar(
    holidayDate: string = '',
    year: string = '',
    search: string = ''
  ) {
    this.loading = true;
    this.holidayService
      .getHolidayCalendarList(holidayDate, year, search)
      .subscribe({
        next: (holidays) => {
          this.rowData = holidays.map((holiday: any) => {
            return {
              ...holiday,
              date: this.datePipe.transform(holiday.holidayDate, 'dd/MM/yyyy'),
              day: new Date(holiday.holidayDate).toLocaleDateString('en-US', {
                weekday: 'long',
              }),
            };
          });
          this.loading = false;
        },
        error: (error) => {
          this.error = error;
          this.loading = false;
        },
      });
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadHolidayDialog);
    dialogRef.afterClosed().subscribe((data) => {
      this.fetchHolidayCalendar();
    });
  }

  openAddDialog() {
    const dialogRef = this.dialog.open(AddHolidayDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.fetchHolidayCalendar();
      }
    });
  }

  openEditDialog(holidayId: any) {
    const dialogRef = this.dialog.open(AddHolidayDialog, {
      disableClose: true,
      data: holidayId,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.fetchHolidayCalendar();
      }
    });
  }

  onRemoveHoliday(holidayId: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.removeHoliday(holidayId);
      }
    });
  }

  private removeHoliday(holidayId: number): void {
    this.holidayService.removeHolidayCalendarById(holidayId).subscribe({
      next: () => {
        this.toastr.success('Holiday removed successfully!', 'Success');
        this.fetchHolidayCalendar();
      },
      error: () => {
        this.toastr.error(
          'Failed to remove holiday. Please try again.',
          'Error'
        );
      },
    });
  }

  openConfirm() {
    const dialogRef = this.dialog.open(ConfirmDialog);
  }
}

@Component({
  selector: 'upload-holiday-dialog',
  templateUrl: 'upload-holiday-dialog.html',
  styleUrls: ['./holiday.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule],
})
export class UploadHolidayDialog {
  file: any = null;
  url: any = null;

  constructor(
    private toastr: ToastrService,
    private holidayService: HolidayCalendarService,
    private dialogRef: DialogRef
  ) {}

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }

  uploadDocuments(csvFile: any) {
    this.holidayService.bulkCreateHolidayCalendar(csvFile).subscribe({
      next: (response) => {
        if (response == true) {
          this.toastr.success('Holidays updated successfully!', 'Success');
          this.dialogRef.close();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Bulk holiday creation failed');
        this.dialogRef.close();
      },
    });
  }

  removeFile(): void {
    this.file = null;
    const input = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  onUpload() {
    this.uploadDocuments(this.file);
  }
}

@Component({
  selector: 'add-holiday-dialog',
  templateUrl: 'add-holiday-dialog.html',
  styleUrls: ['./holiday.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatFormField,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class AddHolidayDialog implements OnInit {
  holidayCalendarForm!: FormGroup;
  public holidayTypeData: { label: string; value: string }[] = [];
  holidayData: any;

  constructor(
    private holidayService: HolidayCalendarService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<AddHolidayDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.holidayData = this.data;
    }
    this.loadHolidayTypes();
    this.initForm();
  }

  initForm(): void {
    if (!this.holidayData) {
      this.holidayCalendarForm = new FormGroup({
        holidayName: new FormControl('', Validators.required),
        holidayDate: new FormControl('', Validators.required),
        holidayType: new FormControl('', Validators.required),
        isMoon: new FormControl(false),
        description: new FormControl(''),
      });
    } else {
      this.holidayCalendarForm = new FormGroup({
        id: new FormControl(this.holidayData?.id || ''),
        holidayName: new FormControl(
          this.holidayData?.holidayName || '',
          Validators.required
        ),
        holidayDate: new FormControl(
          this.holidayData?.holidayDate || '',
          Validators.required
        ),
        holidayType: new FormControl(
          this.holidayData?.holidayType || '',
          Validators.required
        ),
        isMoon: new FormControl(this.holidayData?.isMoon || ''),
        description: new FormControl(this.holidayData?.description || ''),
      });
    }
  }

  private loadHolidayTypes() {
    this.holidayTypeData = Object.values(HolidayTypes).map((type) => ({
      label: type.label,
      value: type.value,
    }));
  }

  onSubmit(): void {
    if (!this.holidayData) {
      this.holidayCalendarForm.markAllAsTouched();
      if (this.holidayCalendarForm.valid) {
        const { holidayName, holidayDate, holidayType, isMoon, description } =
          this.holidayCalendarForm.value;
        const createHolidayCalendarInput = {
          holidayName,
          holidayDate,
          holidayType,
          isMoon,
          description,
        };
        this.holidayService
          .createHolidayCalendar(createHolidayCalendarInput)
          .subscribe(
            (response) => {
              this.toastr.success('Holiday created successfully!', 'Success', {
                timeOut: 5000,
                progressBar: true,
                closeButton: true,
              });
              this.dialogRef.close('success');
            },
            (error) => {
              let errorMessage = 'Failed to add holiday. Please try again';
              if (error.error && error.error.message) {
                errorMessage = error.error.message;
              } else if (error.message) {
                errorMessage = error.message;
              }

              this.toastr.error(errorMessage, 'Error', {
                timeOut: 5000,
                progressBar: true,
                closeButton: true,
              });
            }
          );
      }
    } else {
      this.holidayCalendarForm.markAllAsTouched();
      if (this.holidayCalendarForm.valid) {
        const holidayData = { ...this.holidayCalendarForm.value };
        this.holidayService.updateHolidayCalendar(holidayData).subscribe(
          (response) => {
            this.toastr.success('Holiday updated successfully!', 'Success', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true,
            });
            this.dialogRef.close('success');
          },
          (error) => {
            let errorMessage = 'Failed to update holiday. Please try again.';
            if (error.error && error.error.message) {
              errorMessage = error.error.message;
            } else if (error.message) {
              errorMessage = error.message;
            }

            this.toastr.error(
              'Failed to update holiday. Please try again.',
              'Error',
              {
                timeOut: 5000,
                progressBar: true,
                closeButton: true,
              }
            );
          }
        );
      }
    }
  }
}
