import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-chart-accounts-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule
  ],
  templateUrl: './chart-accounts-details.component.html',
  styleUrl: './chart-accounts-details.component.scss'
})
export class ChartAccountsDetailsComponent {

  selectedTabIndex = 0;

  setTab(index: number) {
    this.selectedTabIndex = index;
  }

}
