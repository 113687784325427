<section class="header-wrapper" [ngClass]="{'collapsedClass': sidebarData}">
  <div class="left-set">
    <h2 class="cmn-title">Dashboard</h2>
    <ul class="cmn-sub-title">
      <li>Overview</li>
      <li>Employee Details</li>
    </ul>
  </div>
  <div class="right-set">
    <div class="branch-wrapper">
      <h3>Branch</h3>
      <form [formGroup]="form">
        <mat-form-field>
          <mat-select formControlName="selectedOption">
            <mat-option *ngFor="let option of branches" [value]="option.value">
              {{ option.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div class="right-combo-wrapper">
      <div class="notification-wrapper">
        <div class="notification-box active">
          <img src="assets/images/icons/notification.svg" alt="Notification">
        </div>
      </div>
      <div class="profile-wrapper">
        <button (click)="toggleDropdown($event)" [ngClass]="{'class-clicked': isDropdownOpen}">
          <div class="profile-icon">
            <img src="assets/images/profile.png" alt="Profile">
          </div>
          <div class="details">
            <h2>Robert</h2>
            <h3>Admin</h3>
          </div>
        </button>
      </div>
    </div>
  </div>
  <div [ngClass]="{ show: isDropdownOpen }" class="profile-items" #dropdown>
    <ul>
      <li (click)="handleDropdownClick($event)">
        <img src="assets/images/icons/icon-user.svg" alt="User Icon" /> My
        Profile
      </li>
      <li class="logout" (click)="logout()">
        <img src="assets/images/icons/icon-logout.svg" alt="User Logout" />
        Logout
      </li>
    </ul>
  </div>
</section>