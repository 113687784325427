<div class="form-container">
    <form [formGroup]="documentInfoForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="visaNumber">Visa Number:</label>
        <input
          id="visaNumber"
          formControlName="visaNumber"
          class="form-control"
        />
        <div
          *ngIf="
            documentInfoForm.get('visaNumber')!.invalid &&
            documentInfoForm.get('visaNumber')!.touched
          "
          class="error"
        >
          Visa Number is required.
        </div>
      </div>
  
      <div class="form-group">
        <label for="passportNumber">Passport Number:</label>
        <input
          id="passportNumber"
          formControlName="passportNumber"
          class="form-control"
        />
        <div
          *ngIf="
            documentInfoForm.get('passportNumber')!.invalid &&
            documentInfoForm.get('passportNumber')!.touched
          "
          class="error"
        >
          Passport Number is required.
        </div>
      </div>
  
      <div class="form-group">
        <label for="bankName">Bank Name:</label>
        <input
          id="bankName"
          formControlName="bankName"
          class="form-control"
        />
        <div
          *ngIf="
            documentInfoForm.get('bankName')!.invalid &&
            documentInfoForm.get('bankName')!.touched
          "
          class="error"
        >
          Bank Name is required.
        </div>
      </div>
  
      <div class="form-group">
        <label for="accountNumber">Account Number:</label>
        <input
          id="accountNumber"
          formControlName="accountNumber"
          class="form-control"
        />
        <div
          *ngIf="
            documentInfoForm.get('accountNumber')!.invalid &&
            documentInfoForm.get('accountNumber')!.touched
          "
          class="error"
        >
          Bank Name is required.
        </div>
      </div>
  
      <button
        type="submit"
        [disabled]="documentInfoForm.invalid"
        class="btn btn-primary"
      >
        Save
      </button>
  
      <div *ngIf="errorMessage" class="error">
        {{ errorMessage }}
      </div>
    </form>
  </div>
  