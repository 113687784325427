<app-layout>
  <section class="cmn-innerpage-wrapper dashboard">
    <!-- <div class="head">
      <h2>Welcome back, Robert!</h2>
      <h3>Let's make today productive and successful.</h3>
    </div>
    <div class="box-wrapper">
      <div class="inner-box employees">
        <div class="top">
          <h2>Employees</h2>
        </div>
        <div class="mid">
          <h3>1,235</h3>
        </div>
        <div class="bottom">
          <h4>On Vacation</h4>
        </div>
      </div>
    </div> -->
  </section>
</app-layout>