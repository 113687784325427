import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatTabChangeEvent, MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { SummaryComponent } from './components/summary/summary.component';
import { CurrentVacationComponent } from './components/current-vacation/current-vacation.component';
import { UpcomingVacationComponent } from './components/upcoming-vacation/upcoming-vacation.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-vacation-management',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatTabGroup,
    MatTabsModule,
    SummaryComponent,
    CurrentVacationComponent,
    UpcomingVacationComponent,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './vacation-management.component.html',
  styleUrl: './vacation-management.component.scss'
})
export class VacationManagementComponent {
  @ViewChild('upcomingVacation') upcomingVacationComponent: UpcomingVacationComponent;
  @ViewChild('currentVacation') currentVacationComponent: CurrentVacationComponent;
  searchQuery: string = '';
  isDropdownOpen = false;
  activeTabIndex = 0; 
  filterForm: FormGroup;
  filter: any;

  constructor(
    private fb: FormBuilder
  ){
    this.filterForm = this.fb.group({
      Approved: [''],
      Rejected: ['']
    });
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onSearch(event: Event){
    const input = event.target as HTMLInputElement;
    this.searchQuery = input.value;
  }

  onTabChange(event: MatTabChangeEvent) {
    console.log(event.index);
    if (event.index === 0) {
      this.activeTabIndex = 0;
    } else if (event.index === 1) {
      this.activeTabIndex = 1;
        if (this.upcomingVacationComponent) {
          this.upcomingVacationComponent.fetchVacations();
        }
    } else if (event.index === 2){
      this.activeTabIndex = 2;
      if (this.currentVacationComponent) {
        this.currentVacationComponent.fetchVacations();
      }
    }
  }

  onSubmit() {
    const selectedVacationStatus = [];
    const formValues = this.filterForm.value;
    for (const vacationStatus in formValues) {
      if (formValues[vacationStatus]) {
        selectedVacationStatus.push(vacationStatus);
      }
    }
    this.filter = selectedVacationStatus;
    this.isDropdownOpen = false;
  }

}
