<mat-dialog-content>
  <section class="currency-log-dialog-wrapper">
    <!-- <div class="cmn-innerpage-wrapper currency-adjustments"> -->
      <h2 class="cmn-popup-title">Currency Adjustments</h2>
      <button class="cmn-close" mat-dialog-close></button>
      <div class="inner-content">
        <ag-grid-angular
          style="width: 100%"
          [rowData]="rowData"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [pagination]="true"
          [paginationPageSize]="paginationPageSize"
          [paginationPageSizeSelector]="paginationPageSizeSelector"
          [class]="themeClass"
        ></ag-grid-angular>
      </div>
    <!-- </div> -->
  </section>
</mat-dialog-content>