import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { EmployeeService } from '../../../../features/employees/services/employee.service';
import { MatSelectModule } from '@angular/material/select';
import { numbersOnlyValidator } from '../../../../shared/services/validations';
import { ToastrService } from 'ngx-toastr';
import { ClearanceStatus } from '../../../../../assets/ClearanceStatus';
import { SettlementStatus } from '../../../../../assets/SettlementStatus';
import { UploadFileService } from '../../../../shared/services/file-upload.service';

@Component({
  selector: 'app-exit-employee',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    TextFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
  ],
  templateUrl: './exit-employee.component.html',
  styleUrl: '../../employee-details.component.scss',
})
export class ExitEmployeeComponent implements OnInit {
  employeeExitForm: FormGroup;
  csvFile: any;
  url: string = '';
  today: Date = new Date();
  public employeeExitTypeData: any[] = [];
  public isUpdating: boolean = false;

  public clearanceStatusData: { label: string; value: ClearanceStatus }[] = [];
  public settlementStatusData: { label: string; value: SettlementStatus }[] =
    [];

  @Input() employeeId: any;
  @Input() employeeData: any = {};

  isEditMode = false;
  existingEmployeeExitId: number | null = null;
  selectedFileName: string = ''; // Property to hold the file name
  existingFileUrl: string | null = null; // Property for existing file URL

  constructor(
    private fb: FormBuilder,
    private employeeService: EmployeeService,
    private toastr: ToastrService,
    private documentUploadService: UploadFileService
  ) {
    this.employeeExitForm = this.fb.group({
      exitDate: ['', Validators.required],
      exitType: ['', Validators.required],
      exitReason: ['', Validators.required],
      interviewNotes: ['', Validators.required],
      interviewDate: ['', Validators.required],
      clearanceStatus: ['', Validators.required],
      clearanceDate: ['', Validators.required],
      settlementStatus: ['', Validators.required],
      settlementAmount: ['', [Validators.required, numbersOnlyValidator()]],
      settlementDate: ['', Validators.required],
      lastWorkingDay: ['', Validators.required],
      feedbackScore: ['', [Validators.required, numbersOnlyValidator()]],
      isAssetReturned: [false],
      isNoticePeriod: [false],
      isExitInterview: [false],
      isAccountsDisabled: [false],
      isManagerApproval: [false],
      isHrApproval: [false],
      isFinalApproval: [false],
      isRehireEligibility: [false],
      exitDocuments: [''],
    });
  }

  ngOnInit(): void {
    this.loadEmployeeExitType();
    this.loadClearanceStatus();
    this.loadSettlementStatus();
    this.fetchEmployeeExitData();
  }

  // This filter disables all dates after today
  dateFilter = (d: Date | null): boolean => {
    const currentDate = d || new Date();
    return currentDate <= this.today;
  };

  private loadEmployeeExitType() {
    this.employeeService.getEmployeeExitTypes().subscribe({
      next: (exitType) => {
        this.employeeExitTypeData = exitType;
      },
      error: (error) => console.error(error),
    });
  }

  private loadClearanceStatus() {
    this.clearanceStatusData = Object.values(ClearanceStatus).map((status) => ({
      label: status,
      value: status,
    }));
  }

  private loadSettlementStatus() {
    this.settlementStatusData = Object.values(SettlementStatus).map(
      (status) => ({
        label: status,
        value: status,
      })
    );
  }

  removeFile(): void {
    if (this.csvFile) {
      this.csvFile = null;
    }
    if (this.existingFileUrl) {
      this.existingFileUrl = null;
      this.selectedFileName = '';
    }

    const input = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.csvFile = input.files[0];
    }
  }

  private fetchEmployeeExitData() {
    if (this.employeeData?.employeeExits?.length > 0) {
      const exitId = this.employeeData?.employeeExits[0]?.id;
      if (exitId) {
        this.employeeService.getEmployeeExitById(exitId).subscribe({
          next: (employeeExitData) => {
            if (employeeExitData) {
              this.isEditMode = true;
              this.existingEmployeeExitId = employeeExitData.id;
              this.patchForm(employeeExitData);
              this.existingFileUrl = employeeExitData.exitDocuments || null;
              this.selectedFileName = this.existingFileUrl
                ? this.extractFileName(this.existingFileUrl)
                : '';
            }
          },
          error: (error) => {
            console.error('Error fetching employee exit data:', error);
          },
        });
      }
    }
  }

  private extractFileName(url: string): string {
    return url.split('/').pop() || '';
  }

  private patchForm(data: any): void {
    this.employeeExitForm.patchValue({
      exitDate: new Date(Number(data.exitDate)),
      exitType: data.exitType,
      exitReason: data.exitReason,
      interviewNotes: data.interviewNotes,
      interviewDate: new Date(Number(data.interviewDate)),
      clearanceStatus: data.clearanceStatus,
      clearanceDate: new Date(Number(data.clearanceDate)),
      settlementStatus: data.settlementStatus,
      settlementAmount: data.settlementAmount,
      settlementDate: new Date(Number(data.settlementDate)),
      lastWorkingDay: new Date(Number(data.lastWorkingDay)),
      feedbackScore: data.feedbackScore,
      isAssetReturned: data.isAssetReturned,
      isNoticePeriod: data.isNoticePeriod,
      isExitInterview: data.isExitInterview,
      isAccountsDisabled: data.isAccountsDisabled,
      isManagerApproval: data.isManagerApproval,
      isHrApproval: data.isHrApproval,
      isFinalApproval: data.isFinalApproval,
      isRehireEligibility: data.isRehireEligibility,
      exitDocuments: data.exitDocuments,
    });
  }

  // Update the existing employee exit record
  updateForm() {
    if (this.employeeExitForm.valid) {
      const formData = {
        ...this.employeeExitForm.value,
        id: this.existingEmployeeExitId,
      };

      this.employeeService.updateEmployeeExit(formData).subscribe({
        next: (response) => {
          this.toastr.success('Employee exit updated successfully.', 'Success');
        },
        error: (error) => {
          this.toastr.error('Error updating the employee exit.', 'Error');
        },
      });
    }
  }

  onCancel(): void {
    this.employeeExitForm.reset();
  }

  onSubmit() {
    if (this.csvFile) {
      this.uploadDocuments(this.csvFile);
    } else {
      this.isEditMode ? this.updateForm() : this.submitForm();
    }
  }

  uploadDocuments(csvFile: any) {
    console.log(csvFile);
    this.documentUploadService
      .uploadDocument(csvFile, 'exit-employee')
      .subscribe({
        next: (response) => {
          this.url = response.url || response;
          console.log('Document uploaded, URL:', this.url);
          this.employeeExitForm.patchValue({
            exitDocuments: this.url,
          });
          this.submitForm();
        },
        error: (error) => {
          console.error('File upload failed:', error);
          this.toastr.error('File upload failed', 'Error');
        },
      });
  }

  submitForm() {
    if (!this.employeeExitForm.valid) {
      this.employeeExitForm.markAllAsTouched();
    } else {
      const formData = {
        ...this.employeeExitForm.value,
        employeeId: parseInt(this.employeeId, 10),
      };

      this.employeeService.submitEmployeeExit(formData).subscribe({
        next: (response) => {
          this.toastr.success(
            'Employee exit processed successfully.',
            'Success'
          );
        },
        error: (error) => {
          console.error('Error processing the employee exit:', error);
          this.toastr.error(
            'There was an error processing the employee exit.',
            'Error'
          );
        },
      });
    }
  }
}
