import { Component } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { LeaveService } from '../../../../../../features/leave/services/leave.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-leave-history',
  standalone: true,
  imports: [
    CommonModule,
    AgGridAngular,
  ],
  templateUrl: './leave-history.component.html',
  styleUrl: '../../../../employee-details.component.scss'
})
export class LeaveHistoryComponent {
  public loading: boolean = true;
  rowData: any[] = [];
  filter: any;
  employeeId: number;
  public themeClass: string = 'ag-theme-quartz';

  public columnDefs: ColDef[] = [
    { headerName: 'Date', field: "date", flex: 1 },
    { headerName: 'Duration', field: "duration", flex: 1 },
    {
      headerName: 'Days', field: "days", flex: 1,
      cellRenderer: (params: any) => {
        const days = params.data?.days;
        if (days <= 1) {
          return `<span>${days} Day</span>`;
        } else if (days > 1) {
          return `<span>${days} Days</span>`;
        } else {
          return `<span>${days}</span>`;
        }
      }
    },
    { headerName: 'Reporting Manager', field: "reportingManager", flex: 1 },
    {
      headerName: 'Status', field: "status", flex: 1,
      cellRenderer: (params: any) => {
        const status = params.data?.status;
        const statusClass =
          status === "Pending" ? 'yellow' : status === "Approved" ? 'green' : status === "Rejected" ? 'red' : '';
        return `<span class="${statusClass}">${status}</span>`;
      }
    },
  ];

  public defaultColDef: ColDef = {
    filter: "agTextColumnFilter",
    floatingFilter: true,
  };
  public paginationPageSize = 25;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];

  constructor(
    private leaveService: LeaveService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) { 
      this.route.params.subscribe((params: Params) => {
      this.employeeId = params['id'];
    })
  }

  ngOnInit() {
    this.fetchLeaves();
  }

  ngOnChanges(){
    this.fetchLeaves();
  }

  fetchLeaves() {
    this.filter = { employeeId: Number(this.employeeId) };
    this.loading = true;
    this.leaveService.fetchLeaves(this.filter).subscribe({
      next: (leaves) => {
        this.rowData = leaves
          .map((leaves: any) => {
            return {
              date: this.datePipe.transform((leaves?.createdAt), 'MMM d, yyyy') || 'N/A',
              days: leaves?.totalLeaveDays || 'N/A',
              status: leaves?.leaveStatus || 'N/A',
              duration: this.datePipe.transform((leaves?.startDate), 'MMM d') + ' - ' +
                this.datePipe.transform((leaves?.endDate), 'MMM d') || 'N/A',
                reportingManager: (leaves?.employee?.reportingManager?.firstName && leaves?.employee?.reportingManager?.lastName) 
                ? leaves.employee.reportingManager.firstName + ' ' + leaves.employee.reportingManager.lastName 
                : 'N/A',              
            };  
          });
        this.loading = false;
      },
      error: (error) => {
        this.toastr.error('Failed to load leaves. Please try again.', 'Error');
        this.loading = false;
      },
    });
  }
}