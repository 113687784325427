import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationPopupComponent } from '../../../../shared/confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'app-punchInOut',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './punchInOut.component.html',
  styleUrls: ['./punchInOut.component.scss'],
})
export class PunchInOutComponent {
  isPunchedIn: boolean = false;
  punchTimes: { punchIn: Date | null; punchOut: Date | null }[] = [];

  constructor(private dialog: MatDialog) {}

  togglePunch() {
    if (this.isPunchedIn) {
      const dialogRef = this.dialog.open(ConfirmationPopupComponent);

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.punchTimes[this.punchTimes.length - 1].punchOut = new Date();
          this.isPunchedIn = false;
        }
      });
    } else {
      this.punchTimes.push({ punchIn: new Date(), punchOut: null });
      this.isPunchedIn = true;
    }
  }

  getTotalPunchedInTime(): string {
    let totalMilliseconds = 0;

    this.punchTimes.forEach((punch) => {
      if (punch.punchIn && punch.punchOut) {
        totalMilliseconds += punch.punchOut.getTime() - punch.punchIn.getTime();
      }
    });

    const totalSeconds = Math.floor(totalMilliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours}h ${minutes}m ${seconds}s`;
  }
}
