import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Assets } from '../../models/assets.model';
import { PaginationService } from '../../shared/pagination.service';
import { AssetService } from '../../services/assets.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { SearchComponent } from '../../../../shared/search/search.component';

@Component({
  selector: 'app-assets',
  standalone: true,
  imports: [
    MatSortModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    SearchComponent,
    MatInputModule,
  ],
  templateUrl: './assets.component.html',
  styleUrl: './assets.component.scss',
})
export class AssetsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  displayedColumns: string[] = [
    'assetName',
    'category',
    'branch',
    'type',
    'status',
  ];
  dataSource = new MatTableDataSource<Assets>();
  constructor(
    private paginationService: PaginationService,
    private assetService: AssetService
  ) {}

  ngOnInit(): void {
    this.loadAssetsData();
  }
  loadAssetsData(): void {
    this.assetService.getAssetsData().subscribe((data) => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      const totalRecords = data.length;
      this.paginator.pageSizeOptions =
        this.paginationService.calculatePageSizeOptions(totalRecords);
    });
  }

  onSearchChange(filterValue: string) {
    this.dataSource.filter = filterValue;
  }
}
