<app-layout>
    <section class="cmn-innerpage-wrapper leave-approvals">
        <div class="head">
            <h2>Leave Request</h2>
            <button class="cmn-filter-btn" (click)="toggleDropdown($event)"
                [ngClass]="{ 'class-clicked': isDropdownOpen }"><img src="assets/images/icons/icon-timeline.svg"
                    alt="Icon Timeline"> Show Timeline</button>
            <!-- Filter Popup -->
            <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
                <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                <h6 class="cmn-popup-heading">Timeline</h6>
                <div class="timelinne-wrapper">
                    <div class="timeline-box" *ngFor="let timeline of timelines">
                        <div class="timeline-head" >
                            <ul>
                                <li>{{timeline?.activityType}}</li>
                                <li>{{timeline?.createdAt | date: 'dd/MM/YYYY'}}</li>
                            </ul>
                            <h3>{{ timeline?.createdAt | date: 'hh:mm a' }}
                            </h3>
                        </div>
                        <div class="timeline-content">
                            <h4>{{ timeline?.note    }}</h4>
                        </div>
                    </div>
                    <!-- <div class="timeline-box">
                        <div class="timeline-head">
                            <ul>
                                <li>{{data[1]?.activityType}}</li>
                                <li>24/08/2024</li>
                            </ul>
                            <h3>04:25 am</h3>
                        </div>
                        <div class="timeline-content">
                            <h4>Ronald Richards : Their is no pending works for Robertson so am okay with his leave request. : Their is no pending works for Robertson so am okay with his leave request.</h4>
                        </div>
                    </div>
                    <div class="timeline-box">
                        <div class="timeline-head">
                            <ul>
                                <li>{{data[2]?.activityType}}</li>
                                <li>24/08/2024</li>
                            </ul>
                            <h3>04:25 am</h3>
                        </div>
                        <div class="timeline-content">
                            <h4>Ronald Richards : Their is no pending works for Robertson so am okay with his leave request.</h4>
                        </div>
                    </div>
                    <div class="timeline-box">
                        <div class="timeline-head">
                            <ul>
                                <li>{{data[4]?.activityType}}</li>
                                <li>24/08/2024</li>
                            </ul>
                            <h3>04:25 am</h3>
                        </div>
                        <div class="timeline-content">
                            <h4>Ronald Richards : Their is no pending works for Robertson so am okay with his leave request.</h4>
                        </div>
                    </div>
                    <div class="timeline-box">
                        <div class="timeline-head">
                            <ul>
                                <li>{{data[5]?.activityType}}</li>
                                <li>24/08/2024</li>
                            </ul>
                            <h3>04:25 am</h3>
                        </div>
                        <div class="timeline-content">
                            <h4>Ronald Richards : Their is no pending works for </h4>
                        </div>
                    </div>
                    <div class="timeline-box">
                        <div class="timeline-head">
                            <ul>
                                <li>{{data[6]?.activityType}}</li>
                                <li>24/08/2024</li>
                            </ul>
                            <h3>04:25 am</h3>
                        </div>
                        <div class="timeline-content">
                            <h4>Ronald Richards : Their is no pending works for Robertson so am okay with his leave request. : Their is no pending works for Robertson so am okay with his leave request.</h4>
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- Filter Popup -->
        </div>
        <div class="content-wrapper">
            <div class="left-set">
                <div class="employee-details">
                    <div class="box">
                        <h3>Name</h3>
                        <h4>{{leaveData?.employee?.firstName}} {{leaveData?.employee?.lastName}}</h4>
                    </div>
                    <div class="box">
                        <h3>Designation</h3>
                        <h4>{{leaveData?.employee?.designation?.designationName}}</h4>
                    </div>
                    <div class="box">
                        <h3>From</h3>
                        <h4>{{leaveData?.startDate | date: 'MMMM dd yyyy'}}</h4>
                    </div>
                    <div class="box">
                        <h3>To</h3>
                        <h4>{{leaveData?.endDate | date: 'MMMM dd yyyy'}}</h4>
                    </div>
                    <div class="box">
                        <h3>Leave Type</h3>
                        <h4>{{leaveData?.leaveType}}</h4>
                    </div>
                    <div class="box">
                        <h3>Remarks</h3>
                        <h4>{{leaveData?.employeeComment}}</h4>
                    </div>
                    <!-- <div class="box">
                        <h3>Session</h3>
                        <h4>20th Session 2 to 22nd Session 1</h4>
                    </div> -->
                </div>
                <div class="approver-details">
                    <h3>Approvers</h3>
                    <div class="approver-box" *ngFor="let approver of leaveApprovers">
                        <div class="approver-item" *ngIf ="approver?.levelOneData">
                            <div class="approver">
                                <img src="/assets/images/profile.png" alt="">
                                <h4>{{approver?.levelOneData?.firstName}} {{approver?.levelOneData?.lastName}}</h4>
                            </div>
                            <h5 [ngClass]="{
                                'green': levelOneApproverStatus === 'APPROVED',
                                'yellow': levelOneApproverStatus === 'PENDING',
                                'red': levelOneApproverStatus === 'REJECTED'
                              }">
                                {{ levelOneApproverStatus }}
                              </h5>
                        </div>
                        <div class="approver-item" *ngIf ="approver?.levelTwoData">
                            <div class="approver">
                                <img src="/assets/images/profile.png" alt="">
                                <h4>{{approver?.levelTwoData?.firstName}} {{approver?.levelTwoData?.lastName}}</h4>
                            </div>
                            <h5 [ngClass]="{
                                'green': levelTwoApproverStatus === 'APPROVED',
                                'yellow': levelTwoApproverStatus === 'PENDING',
                                'red': levelTwoApproverStatus === 'REJECTED'
                              }">
                                {{ levelTwoApproverStatus }}
                              </h5>
                        </div>
                        <div class="approver-item" *ngIf ="approver?.levelThreeData">
                            <div class="approver">
                                <img src="/assets/images/profile.png" alt="">
                                <h4>{{approver?.levelThreeData?.firstName}} {{approver?.levelThreeData?.lastName}}</h4>
                            </div>
                            <h5 [ngClass]="{
                                'green': levelThreeApproverStatus === 'APPROVED',
                                'yellow': levelThreeApproverStatus === 'PENDING',
                                'red': levelThreeApproverStatus === 'REJECTED'
                              }">
                                {{ levelThreeApproverStatus }}
                              </h5>
                        </div>
                        <div class="approver-item" *ngIf ="approver?.levelFourData">
                            <div class="approver">
                                <img src="/assets/images/profile.png" alt="">
                                <h4>{{approver?.levelFourData?.firstName}} {{approver?.levelFourData?.lastName}}</h4>
                            </div>
                            <h5 [ngClass]="{
                                'green': levelFourApproverStatus === 'APPROVED',
                                'yellow': levelFourApproverStatus === 'PENDING',
                                'red': levelFourApproverStatus === 'REJECTED'
                              }">
                                {{ levelFourApproverStatus }}
                              </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-set">
                <table>
                    <tr class="no-border">
                        <th>Type</th>
                        <th>Used</th>
                        <th>Pending</th>
                    </tr>
                    <tr>
                        <td>Casual Leave</td>
                        <td>{{casualLeave?.applied}}</td>
                        <td>{{casualLeave?.pending}}</td>
                    </tr>
                    <tr>
                        <td>Earned Leave</td>
                        <td>{{earnedLeave?.applied}}</td>
                        <td>{{earnedLeave?.pending}}</td>
                    </tr>
                    <tr>
                        <td>Marriage Leave</td>
                        <td>{{marriageLeave?.applied}}</td>
                        <td>{{marriageLeave?.pending}}</td>
                    </tr>
                    <tr>
                        <td>Emergency Leave</td>
                        <td>{{emergencyLeave?.applied}}</td>
                        <td>{{emergencyLeave?.pending}}</td>
                    </tr>
                    <tr>
                        <td>Loss Of Pay</td>
                        <td>{{lossOfPay?.applied}}</td>
                        <td>{{lossOfPay?.pending}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="submit-btn-wrapper">
            <button class="cmn-reject-btn" (click)="onReject()">Reject</button>
            <button class="cmn-next-btn" type="submit" (click)="onApprove()">Approve</button>
        </div>

        <div class="leave-history-wrapper">
            <h3>Leave History</h3>
            <app-leave-history [employeeId]="employeeId"></app-leave-history>
        </div>
    </section>
</app-layout>