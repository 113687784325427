import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import {
  alphabeticValidator,
  alternatePhoneValidator,
  customEmailValidator,
  numbersOnlyValidator,
} from '../../../../shared/services/validations';
import { UploadFileService } from '../../../../shared/services/file-upload.service';
import { ToastrService } from 'ngx-toastr';
import { ImageService } from '../../../../shared/services/image.service';

@Component({
  selector: 'app-personal-info',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './personal-info.component.html',
  styleUrl: '../../add-employee.component.scss',
})
export class PersonalInfoComponent implements OnChanges {
  profilePhoto: any = null;
  personalInfoForm: FormGroup;
  countries: any[] = [];
  today: Date = new Date();
  updatedImage: any = null;
  url: any = null;

  // This filter disables all dates after today
  dateFilter = (d: Date | null): boolean => {
    const currentDate = d || new Date();
    return currentDate <= this.today;
  };

  @Input() employee: any;
  @Input() isEditMode: boolean = false;
  @Input() employeeData: any = {};
  @Output() formSubmit = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private documentUploadService: UploadFileService,
    private toastr: ToastrService,
    private imageService: ImageService
  ) {
    this.personalInfoForm = this.fb.group({
      profilePicture: [''],
      firstName: ['', [Validators.required, alphabeticValidator()]],
      middleName: [''],
      lastName: ['', [Validators.required, alphabeticValidator()]],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(15),
          numbersOnlyValidator(),
        ],
      ],
      alternatePhoneNumber: ['', [
      Validators.minLength(7),
      Validators.maxLength(15),alternatePhoneValidator()]],
      email: ['', [Validators.required, customEmailValidator()]],
      dateOfBirth: ['', Validators.required],
      maritalStatus: ['', Validators.required],
      gender: ['', Validators.required],
      nationality: ['', Validators.required],
      address: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['employee'] && this.isEditMode && this.employee) {
      this.populateFormWithEmployeeData();
    }
  }

  async populateFormWithEmployeeData() {
    if (this.employee) {
      if (this.employee?.profilePicture) {
        let profilePicture;
        if (this.employee?.profilePicture) {
          try {
            profilePicture = await this.imageService.getImageAsBase64(
              this.employee?.profilePicture
            );
          } catch (error) {
            profilePicture = 'assets/images/dummy-profile-image.jpg';
          }
        } else {
          profilePicture = 'assets/images/dummy-profile-image.jpg';
        }
        this.updatedImage = profilePicture;
      }
      this.personalInfoForm.patchValue({
        profilePicture: this.employee?.profilePicture,
        firstName: this.employee.firstName,
        middleName: this.employee.middleName,
        lastName: this.employee.lastName,
        phoneNumber: this.employee.phoneNumber,
        alternatePhoneNumber: this.employee.alternatePhoneNumber,
        email: this.employee.email,
        dateOfBirth: this.employee.dateOfBirth
          ? new Date(Number(this.employee.dateOfBirth))
          : null,
        maritalStatus: this.employee.maritalStatus,
        gender: this.employee.gender,
        nationality: this.employee.nationality,
        address: this.employee.address,
      });
      console.log('Form value after patch:', this.personalInfoForm.value);
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      // Handle the file, e.g., upload it or read it
      console.log('Selected file:', file);
      this.profilePhoto = file;
      const reader = new FileReader();
      reader.readAsDataURL(this.profilePhoto);
      reader.onload = (event: any) => {
        this.updatedImage = event.target.result;
      };
    }
  }

  ngOnInit(): void {
    this.loadCountries();
    this.populateFormWithEmployeeData();
  }

  loadCountries(): void {
    this.http.get<any[]>('assets/json/countries.json').subscribe(
      (data) => {
        this.countries = data;
      },
      (error) => {
        console.log('Error loading country data----', error);
      }
    );
  }

  onNext(): void {
    if (this.personalInfoForm.invalid) {
      this.personalInfoForm.markAllAsTouched();
      console.log('Form is Invalid');
    } else {
      if (this.profilePhoto) {
        this.documentUploadService
          .uploadDocument(this.profilePhoto, 'profile-images')
          .subscribe({
            next: (url) => {
              this.url = url?.url;
              if (this.url) {
                console.log(
                  'this.personalInfoForm',
                  this.personalInfoForm.controls
                );

                console.log(this.url);
                this.personalInfoForm.patchValue({
                  profilePicture: this.url,
                });
                this.formSubmit.emit(this.personalInfoForm.value);
              }
            },
            error: (error) => {
              console.error(error);
              this.toastr.error('Profile upload failed', error);
            },
          });
      } else {
        console.log('Personal Info Submitted:', this.personalInfoForm.value);
        this.formSubmit.emit(this.personalInfoForm.value);
      }
    }
  }

  onCancel(): void {
    this.personalInfoForm.reset();
  }
}
