import { gql } from "apollo-angular";

export const EMPLOYEE_SALARIES = gql`
  query employeeSalaries {
    employeeSalaries {
    id
    employeeId
    employee {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      profilePicture
      employeePayrolls{
        payroll{
          month
        }
      }
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      departmentId
    }
    basicSalary
    hra
    allowances
    deductions
    grossSalary
    netSalary
    remarks
  }
  }
`;

export const EMPLOYEE_PAYROLL_BY_ID = gql`
  query employeePayroll($id: Int!) {
    employeePayroll(id: $id) {
    id
    payrollId
    payroll {
      id
      month
      year
      grossPay
      netPay
      deductions
      workDays
      totalEmployees
      additions
      separations
      lockPayroll
      lockInputs
      isProcessed
    }
    employeeId
    employees {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      isPortalUser
      departmentId
    }
    payPeriodStart
    payPeriodEnd
    basicSalary
    overtimePay
    bonuses
    deductions
    netPay
    paymentDate
    paymentMethod
    payslipURL
    payrollDeductions {
      id
      description
      amount
    }
    payrollBonuses {
      id
      description
      amount
      employeePayrollId
    }
  }
  }
`;

export const GET_PAYROLLS = gql`
  query payrolls($search: String, $filter: PayrollFilter) {
    payrolls (search: $search, filter: $filter) {
    id
    month
    year
    grossPay
    netPay
    deductions
    workDays
    totalEmployees
    additions
    separations
    lockPayroll
    lockInputs
    isProcessed
    employeePayrolls {
      id
      payrollId
      employeeId
      empoyee{
        id
        firstName
        lastName
        salary
        profilePicture
      }
      payPeriodStart
      payPeriodEnd
      basicSalary
      overtimePay
      bonuses
      deductions
      netPay
      paymentDate
      paymentMethod
      payslipURL
    }
  }
  }
`;

export const EMPLOYEE_PAYROLL = gql`
  query getPayrollByMonthYear($year: Int!, $month: PayrollMonths!) {
    getPayrollByMonthYear(year: $year, month: $month) {
    id
    month
    year
    grossPay
    netPay
    deductions
    workDays
    totalEmployees
    additions
    separations
    lockPayroll
    lockInputs
    isProcessed
    employeePayrolls {
      id
      payrollId
      employeeId
      employees{
        id
        firstName
        lastName
        profilePicture
        salary
      }
      payPeriodStart
      payPeriodEnd
      basicSalary
      overtimePay
      bonuses
      deductions
      netPay
      paymentDate
      paymentMethod
      payslipURL
    }
  }
  }
`;

export const FIND_ALL_PAYROLL_AG_GRID = gql`
  query findAllPayrollWithAGGrid ($startRow: Float!, $endRow: Float!, $sortModel: [SortModelInput!], $filterModel: FilterModel, $search: String) {
    findAllPayrollWithAGGrid (startRow : $startRow, endRow: $endRow, sortModel: $sortModel, filterModel: $filterModel, search: $search){
    payrolls {
      id
      month
      year
      grossPay
      netPay
      deductions
      workDays
      totalEmployees
      additions
      seperations
      lockPayroll
      lockInputs
      isProcessed
      employeePayrolls {
        empoyee{
          id
          firstName
          lastName
          salary
          employeeStatus
        }
        bonuses
      }
    }
    total
    limit
    offset
  }
  }
`;

