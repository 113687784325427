import { Component } from '@angular/core';

@Component({
  selector: 'app-leaves',
  standalone: true,
  imports: [],
  templateUrl: './leaves.component.html',
  styleUrl: './leaves.component.scss'
})
export class LeavesComponent {

}
