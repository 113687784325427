import { Component } from '@angular/core';
import { CoreModule } from '../../core/core.module';
import { HeaderComponent } from '../../core/header/header.component';
import { SidebarComponent } from '../../core/sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';



@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CoreModule,
    HeaderComponent,
    SidebarComponent,
    CommonModule,
    LayoutComponent
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  // isCollapsed = false;

  // toggleSidebar() {
  //   this.isCollapsed = !this.isCollapsed;
  // }
}
