import {
  Component,
  inject,
  HostListener,
  ElementRef,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { EmployeeService } from '../../features/employees/services/employee.service';
import { Subscription } from 'rxjs';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { ImageService } from '../../shared/services/image.service';
import { UploadFileService } from '../../shared/services/file-upload.service';
import { DialogRef } from '@angular/cdk/dialog';
import { FileExportService } from '../../shared/services/file-export.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-employees',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    AgGridAngular,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './employees.component.html',
  styleUrl: './employees.component.scss',
})
export class EmployeesComponent implements OnInit {
  isDropdownOpen = false;

  selectedCount: number = 0;

  isExportOpen = false;
  rowData: any[] = [];
  public loading: boolean = true;
  public error: any = null;
  public branchData: any[] = [];
  public employeeTypeData: any[] = [];
  public designationData: any[] = [];

  exportFormat: string = 'pdf';

  selectedFields: string[] = [];

  filterForm: FormGroup;
  exportForm: FormGroup;
  userAvatar: any;

  columnDefs = [
    {
      headerName: 'Employee Name',
      field: 'employeeName',
      flex: 1,
      cellRenderer: (params: any) => {
        return `
          <div style="display: flex; align-items: center;">
            <img src="${params.data.profilePicture}" alt="Profile Image" style="width: 35px; height: 35px; margin-right: 10px; border-radius: 50%; object-fit: cover;">
            <span>${params.data.employeeName}</span>
          </div>
        `;
      },
    },
    { headerName: 'Employee ID', field: 'employeeId', flex: 1 },
    { headerName: 'Branch Name', field: 'branchName', flex: 1 },
    { headerName: 'Designation', field: 'designation', flex: 1 },
    { headerName: 'Type', field: 'employeeType', flex: 1 },
    {
      headerName: 'Status',
      field: 'employeeStatus',
      flex: 1,
      cellRenderer: (params: any) => {
        const status = params.data.employeeStatus;
        const statusClass = status === 'Working' ? 'working' : 'on-leave';
        return `<span class="${statusClass}">${status}</span>`;
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      cellRenderer: (params: any) => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.style.gap = '20px';

        const deleteIcon = document.createElement('img');
        deleteIcon.src = 'assets/images/icons/icon-delete.svg';
        deleteIcon.style.cursor = 'pointer';
        deleteIcon.addEventListener('click', (event) => {
          event.stopPropagation();
          this.onDeleteEmployee(params.data.employeeId);
        });

        container.appendChild(deleteIcon);
        return container;
      },
    },
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50, 100];
  public themeClass: string = 'ag-theme-quartz';

  readonly dialog = inject(MatDialog);
  private dataSubscription: Subscription;

  searchTerm: string = '';

  constructor(
    private elementRef: ElementRef,
    private employeeService: EmployeeService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private imageService: ImageService,
    private fileExportService: FileExportService,
    private authService: AuthService
  ) {
    this.filterForm = this.fb.group({
      branches: this.fb.array([]),
      employeeTypes: this.fb.array([]),
    });

    this.exportForm = this.fb.group({
      fields: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.loadEmployees();

    this.filterForm = this.fb.group({
      branches: this.fb.array([]),
      employeeTypes: this.fb.array([]),
      designations: this.fb.array([]),
    });

    this.loadBranchData();
    this.loadEmployeeTypeData();
  }

  updateSelectedCount(): void {
    const formValue = this.filterForm.value;
    const branchesSelected = formValue.branches.filter(
      (selected: boolean) => selected
    ).length;
    const employeeTypesSelected = formValue.employeeTypes.filter(
      (selected: boolean) => selected
    ).length;

    // Update selectedCount based on the number of selected checkboxes
    this.selectedCount = branchesSelected + employeeTypesSelected;
  }

  onCheckboxChange() {
    this.updateSelectedCount();
  }

  onSearch() {
    this.loadEmployees(this.searchTerm);
  }

  clearFilter() {
    this.filterForm.reset();
    this.selectedCount = 0;
    this.loadEmployees();
    this.toggleDropdown();
  }

  private loadEmployees(search: string = '', filter: any = {}) {
    this.loading = true;

    this.employeeService.getEmployees(search, filter).subscribe({
      next: (employees) => {
        const filteredEmployees = employees.filter((employee: any) => {
          const branchMatch =
            !filter.branchNames?.length ||
            filter.branchNames.includes(employee?.branch?.branchName);
          const employeeTypeMatch =
            !filter.employeeTypeNames?.length ||
            filter.employeeTypeNames.includes(employee?.employeeType);

          return branchMatch && employeeTypeMatch;
        });

        const employeePromises = filteredEmployees.map(
          async (employee: any) => {
            let profilePicture =
              employee?.profilePicture ||
              'assets/images/dummy-profile-image.jpg';

            if (employee?.profilePicture) {
              try {
                profilePicture = await this.imageService.getImageAsBase64(
                  employee.profilePicture
                );
              } catch (error) {
                profilePicture = 'assets/images/dummy-profile-image.jpg';
              }
            }

            return {
              employeeName: `${employee?.firstName || 'N/A'} ${
                employee?.lastName || ''
              }`,
              employeeId: employee?.id || 'N/A',
              profilePicture: profilePicture,
              branchName: employee?.branch?.branchName || 'N/A',
              designation: employee?.designation?.designationName || 'N/A',
              employeeType: employee?.employeeType || 'N/A',
              employeeStatus: employee?.employeeStatus || 'N/A',
            };
          }
        );

        Promise.all(employeePromises).then((resolvedEmployees) => {
          this.rowData = resolvedEmployees;
          this.loading = false;
        });
      },

      error: (error) => {
        this.toastr.error(
          'Failed to load employees. Please try again.',
          'Error'
        );
        this.loading = false;
      },
    });
  }

  onDeleteEmployee(employeeId: number): void {
    const loggedInUser = this.authService.getLoggedInUser();
    const loggedInUserId = loggedInUser.employeeData.id;
    if (employeeId === loggedInUserId) {
      this.toastr.error('You cannot delete your own account.', 'Error');
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteEmployee(employeeId);
      }
    });
  }

  private deleteEmployee(employeeId: number): void {
    this.employeeService.removeEmployee(employeeId).subscribe({
      next: () => {
        this.toastr.success('Employee deleted successfully!', 'Success');
        this.router.navigate(['/employees']);
        this.loadEmployees();
      },
      error: () => {
        this.toastr.error(
          'Failed to delete employee. Please try again.',
          'Error'
        );
      },
    });
  }

  onRowClicked(event: any) {
    const employeeId = event.data.employeeId;
    // this.router.navigate([`/employee-details/${employeeId}`]);
    this.router.navigate([`/employees/detail/${employeeId}`]);
  }

  get branchesFormArray() {
    return this.filterForm.get('branches') as FormArray;
  }

  // Create a getter for the export form fields array
  get exportFieldsArray() {
    return this.exportForm.get('fields') as FormArray;
  }

  onFieldCheckboxChange(event: any, fieldName: string) {
    const fieldsArray: FormArray = this.exportForm.get('fields') as FormArray;

    if (event.target.checked) {
      fieldsArray.push(new FormControl(fieldName));
    } else {
      const index = fieldsArray.controls.findIndex(
        (control) => control.value === fieldName
      );
      if (index >= 0) {
        fieldsArray.removeAt(index);
      }
    }
  }
  // Method to trigger export functionality
  exportEmployees() {
    const selectedFields = this.exportForm.value.fields;

    if (selectedFields.length === 0) {
      this.toastr.error('Please select at least one field to export.', 'Error');
      return;
    }

    let exportObservable;

    // Choose the export API based on the selected format
    switch (this.exportFormat) {
      case 'pdf':
        exportObservable =
          this.employeeService.exportEmployeesToPDF(selectedFields);
        break;
      case 'xls':
        exportObservable =
          this.employeeService.exportEmployeesToXLSX(selectedFields);
        break;
      case 'csv':
        exportObservable =
          this.employeeService.exportEmployeesToCSV(selectedFields);
        break;
      default:
        this.toastr.error('Invalid export format selected.', 'Error');
        return;
    }

    // Call the export service method
    exportObservable.subscribe({
      next: (response: Blob) => {
        this.getFile(response, this.exportFormat);
        // this.exportForm.reset();
      },
      error: (error) => {
        this.toastr.error(
          'Failed to export employees. Please try again.',
          'Error'
        );
      },
    });
  }

  async getFile(response: any, format: string) {
    try {
      const file = await this.fileExportService.getFileUrlPost(
        response,
        format
      );
    } catch (error) {
      console.error('Error exporting file', error);
    }
  }

  private addControls(formArrayName: string, data: any[]) {
    const formArray = this.filterForm.get(formArrayName) as FormArray;
    formArray.clear();

    data.forEach(() => {
      formArray.push(new FormControl(false));
    });
  }

  private loadBranchData() {
    this.employeeService.getBranches().subscribe({
      next: (branches) => {
        this.branchData = branches;
        this.addControls('branches', this.branchData);
      },
      error: (error) => console.error(error),
    });
  }

  private loadEmployeeTypeData() {
    this.employeeService.getEmployeeTypes().subscribe({
      next: (employeeTypes) => {
        this.employeeTypeData = employeeTypes;
        this.addControls('employeeTypes', this.employeeTypeData);
      },
      error: (error) => console.error('Error fetching employee types:', error),
    });
  }

  onSubmit() {
    const selectedBranches = this.filterForm.value.branches
      .map((checked: boolean, i: number) =>
        checked ? this.branchData[i].branchName : null
      )
      .filter((value: string | null) => value !== null);

    const selectedEmployeeTypes = this.filterForm.value.employeeTypes
      .map((checked: boolean, i: number) =>
        checked ? this.employeeTypeData[i].employeeType : null
      )
      .filter((value: string | null) => value !== null);

    const filters = {
      branch: selectedBranches,
      employeeType: selectedEmployeeTypes,
    };

    if (selectedBranches.length === 0 && selectedEmployeeTypes.length === 0) {
      this.toastr.error('Please select at least one filter to apply.', 'Error');
      return;
    }

    this.loadEmployees('', filters);
    this.toggleDropdown();
  }

  navigateToAddEmployee() {
    this.router.navigate(['/add-employee']);
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadEmployeesDialog);
    dialogRef.afterClosed().subscribe((data) => {
      this.loadEmployees();
    });
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleExport(event: MouseEvent) {
    event.stopPropagation();
    this.isExportOpen = !this.isExportOpen;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
      this.isExportOpen = false;
    }
  }

  handleDropdownClick(event: MouseEvent) {
    event.stopPropagation(); // Prevents click from propagating to the document
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe(); // Clean up subscription
    }
  }
}

const gridDiv = document.querySelector<HTMLElement>('#myGrid')!;

@Component({
  selector: 'upload-employees-dialog',
  templateUrl: 'upload-employees-dialog.html',
  styleUrls: ['./employees.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadEmployeesDialog {
  file: any = null;
  url: any = null;

  constructor(
    private documentUploadService: UploadFileService,
    private toastr: ToastrService,
    private employeeService: EmployeeService,
    private dialogRef: DialogRef
  ) {}

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }

  uploadDocuments(csvFile: any) {
    this.employeeService.bulkCreateEmployees(csvFile).subscribe({
      next: (response) => {
        if (response?.data) {
          this.toastr.success('Employees updated successfully!', 'Success');
          this.dialogRef.close();
        } else {
          this.toastr.error(response.errors[0].message, 'Error');
          this.dialogRef.close();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Bulk employee creation failed');
        this.dialogRef.close();
      },
    });
  }

  removeFile(): void {
    this.file = null;
    const input = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  onUpload() {
    this.uploadDocuments(this.file);
  }
}
