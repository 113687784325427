import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EMPLOYEE_PAYROLL, EMPLOYEE_PAYROLL_BY_ID, EMPLOYEE_SALARIES, FIND_ALL_PAYROLL_AG_GRID, GET_PAYROLLS } from '../graphql/query/payroll.queries';
import { EMPLOYEE_PROCESS_PAYROLL, PROCESS_PAYROLL, UPDATE_PAYROLL } from '../graphql/mutation/payroll.mutation';

@Injectable({
  providedIn: 'root',
})
export class PayrollService {
  constructor(private apollo: Apollo) {}

  employeeSalaries(): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: EMPLOYEE_SALARIES
      })
      .valueChanges.pipe(
        map((result) => result.data?.employeeSalaries || [])
      );
  }

  employeePayrollById(id: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: EMPLOYEE_PAYROLL_BY_ID,
        variables:{
          id
        }
      })
      .valueChanges.pipe(
        map((result) => result.data?.employeePayroll || [])
      );
  }
  
  getPayrolls(search: any, filter: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_PAYROLLS,
        variables: {
          search,
          filter
        }
      })
      .valueChanges.pipe(
        map((result) => result.data?.payrolls || [])
      );
  }

  updatePayroll(updatePayrollInput: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: UPDATE_PAYROLL,
        variables: {
          updatePayrollInput
        }
      })
      .pipe(
        map((result: any) => result.data?.updatePayroll || [])
      );
  }

  processPayrollForEmployee(updatePayrollInput: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: EMPLOYEE_PROCESS_PAYROLL,
        variables: {
          employeeId : updatePayrollInput.id,
          year : updatePayrollInput.year,
          month : updatePayrollInput.month,
        }
      })
      .pipe(
        map((result: any) => result.data?.processPayrollForEmployee || [])
      );
  }

  processPayroll(processPayroll: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: PROCESS_PAYROLL,
        variables: {
          year : processPayroll.year,
          month : processPayroll.month,
        }
      })
      .pipe(
        map((result: any) => result.data?.processPayroll || [])
      );
  }

  findAllPayrollWithAGGrid(startRow: any, endRow: any, sortModel: any, filterModel: any, search: String): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: FIND_ALL_PAYROLL_AG_GRID,
        variables: {
          startRow,
          endRow,
          sortModel,
          filterModel,
          search
        }
      })
      .valueChanges.pipe(
        map((result) => result.data?.findAllPayrollWithAGGrid || [])
      );
  }

  getPayrollByMonthYear(year: any, month: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: EMPLOYEE_PAYROLL,
        variables: {
          year,
          month
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.getPayrollByMonthYear || [])
      );
  }

}