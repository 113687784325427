import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile-documents',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './profile-documents.component.html',
  styleUrl: './profile-documents.component.scss',
})
export class ProfileDocumentsComponent {
  documentInfoForm: FormGroup;
  errorMessage: string | null = null;
  @Output() formSubmitted = new EventEmitter<void>();
  constructor(private fb: FormBuilder, private toastr: ToastrService) {
    this.documentInfoForm = this.fb.group({
      visaNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      passportNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      bankName: ['', Validators.required],
      accountNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
    });
  }

  onSubmit(): void {
    if (this.documentInfoForm.valid) {
      console.log('Document Info------', this.documentInfoForm.value);
      this.toastr.success('Document details submitted successfully');
      this.formSubmitted.emit();
    } else {
      this.toastr.error('Something went wrong.');
    }
  }
}
