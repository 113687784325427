import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-profile-personal-info',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './profile-personal-info.component.html',
  styleUrl: './profile-personal-info.component.scss',
})
export class ProfilePersonalInfoComponent implements OnInit {
  personalInfoForm: FormGroup;
  errorMessage: string | null = null;
  maxDate: string;
  nationalities: { code: string; name: string }[] = [];

  @Output() formSubmitted = new EventEmitter<void>();

  constructor(private fb: FormBuilder, private toastr: ToastrService, private http: HttpClient) {
    this.personalInfoForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobileNumber: [
        '',
        [Validators.required, Validators.pattern(/^[0-9]{10}$/)],
      ],
      emailAddress: ['', [Validators.required, Validators.email]],
      dateOfBirth: ['', Validators.required],
      maritalStatus: ['', Validators.required],
      gender: ['', Validators.required],
      nationality: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', [Validators.required, Validators.pattern(/^[0-9]{5}$/)]],
    });
    this.maxDate = this.getTodayDate();
  }

  ngOnInit(): void {
    this.loadNationalities();
  }

  loadNationalities(): void {
    this.http.get<{ code: string, name: string }[]>('assets/rose-json/nationalities.json').subscribe(
      data => {
        this.nationalities = data;
      },
      error => {
        console.error('Error loading nationalities:', error);
      }
    );
  }

  private getTodayDate(): string {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = (today.getMonth() + 1).toString().padStart(2, '0');
    const dd = today.getDate().toString().padStart(2, '0');

    return `${yyyy}-${mm}-${dd}`;
  }

  onSubmit(): void {
    if (this.personalInfoForm.valid) {
      console.log('Personal Info------', this.personalInfoForm.value);
      this.toastr.success('Personal Info submitted successfully');
      this.formSubmitted.emit();
    } else {
      this.toastr.error('Something went wrong');
    }
  }
}
