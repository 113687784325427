<section class="leave-apply-sec">
  <form [formGroup]="leaveApplyForm">
    <div class="leave-apply-wrapper">
      <div class="form-wrapper">
        <label for="">From Date</label>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="pickerFrom"
            formControlName="startDate"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="pickerFrom"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>

        <div
        *ngIf="
          leaveApplyForm.get('startDate')!.invalid &&
          leaveApplyForm.get('startDate')!.touched
        "
        class="error"
      >
        From date is required.
      </div>
      </div>
      <div class="form-wrapper">
        <label for="">To Date</label>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="pickerTo"
            formControlName="endDate"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="pickerTo"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>
        <div
        *ngIf="
          leaveApplyForm.get('endDate')!.invalid &&
          leaveApplyForm.get('endDate')!.touched
        "
        class="error"
      >
        To date is required.
      </div>
      </div>
      <div class="form-wrapper">
        <label for="">Leave type</label>
        <mat-form-field>
          <mat-select placeholder="Select Type" formControlName="leaveType">
            <mat-option value="CL">Casual Leave</mat-option>
            <mat-option value="EL">Earned Leave</mat-option>
            <mat-option value="EML">Emergency Leave</mat-option>
            <mat-option value="ML">Marriage Leave</mat-option>
            <mat-option value="LOP">Loss Of Pay</mat-option>
          </mat-select>
        </mat-form-field>
        <div
        *ngIf="
          leaveApplyForm.get('leaveType')!.invalid &&
          leaveApplyForm.get('leaveType')!.touched
        "
        class="error"
      >
      Leave type is required.
      </div>
      </div>
      <div class="form-wrapper">
        <label for="">Reason for Leave</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Reason"
            formControlName="employeeComment"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="documents-wrapper">
      <h2>Documents</h2>
      <div class="upload-wrapper">
        <div class="cmn-upload-box">
          <h4>Upload Documents (optional)</h4>
          <input
            #fileInput
            type="file"
            (change)="onFileSelected($event)"
            style="display: none"
          />
          <button class="cmn-choose-file-btn" (click)="fileInput.click()">
            <img
              src="assets/images/icons/icon-add-color.svg"
              alt="Icon Add"
            />Choose a File
          </button>
        </div>
      </div>
      <div class="uploaded-file" *ngIf="csvFile.length > 0">
        <div *ngFor="let file of csvFile; let i = index">
          <h6>
            {{ file.name }}
            <img
              src="assets/images/icons/icon-close.svg"
              alt="Close Icon"
              (click)="removeFile(i)"
            />
          </h6>
        </div>
      </div>
    </div>

    <div class="submit-btn-wrapper">
      <button (click)="onCancel()" class="cmn-cancel-btn">Cancel</button>
      <button (click)="onApplyLeave()" class="cmn-next-btn">Submit</button>
    </div>
  </form>
</section>
