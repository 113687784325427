import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { ProfilePersonalInfoComponent } from '../profile-components/profile-personal-info/profile-personal-info.component';
import { ProfileProffesionalInfoComponent } from '../profile-components/profile-proffesional-info/profile-proffesional-info.component';
import { ProfileDocumentsComponent } from '../profile-components/profile-documents/profile-documents.component';
import { ProfileBranchAccessComponent } from '../profile-components/profile-branch-access/profile-branch-access.component';
import { ProfileSalaryDetailsComponent } from '../profile-components/profile-salary-details/profile-salary-details.component';

@Component({
  selector: 'app-profile-settings',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTabsModule,
    ProfilePersonalInfoComponent,
    ProfileProffesionalInfoComponent,
    ProfileDocumentsComponent,
    ProfileBranchAccessComponent,
    ProfileSalaryDetailsComponent,
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent {
  tabsVisible: boolean = false;

  showTabs(): void {
    this.tabsVisible = true;
  }
  selectedIndex = 0;

  onFormSubmitted(): void {
    if (this.selectedIndex < 4) { 
      this.selectedIndex++;
    }
  }
}
