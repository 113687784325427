export const environment = {
  production: false,
  apiUrl: 'https://your-production-api-url.com/api',
  authUrl: 'https://api-access-gateway.techlabz.in/munawel-be-staging',

  url: {
    defaultUrl:
      'https://api-access-gateway.techlabz.in/munawel-be-staging/graphql',

    hrmsModuleUrl:
      'https://api-access-gateway.techlabz.in/munawel-hrms-staging/graphql',
    accountsUrl:
      'https://api-access-gateway.techlabz.in/munawel-accounting-staging/graphql',
    backendUrl:
      'https://api-access-gateway.techlabz.in/munawel-be-staging/graphql',
    projectModuleUrl:
      'https://api-access-gateway.techlabz.in/munawel-project-staging/graphql',
    rinoj: 'https://rinoj-81.tw-workspace.techwarelab.com/graphql',
    shahid: 'https://shahid-82.tl-workspace.techwarelab.com/graphql',
  },

  wsUrl: {
    defaultWsUrl:
      'wss://api-access-gateway.techlabz.in/munawel-hrms-staging/graphql',
    hrmsModuleWsUrl:
      'wss://api-access-gateway.techlabz.in/munawel-hrms-staging/graphql',
    shahidWsUrl: 'wss://shahid-82.tl-workspace.techwarelab.com/graphql',
  },
};
