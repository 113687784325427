import { Component, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  errorMessage: string | null = null;
  successMessage: string | null = null;
  loading: boolean = false;
  private verifyPasswordUrl = `${environment.authUrl}/auth/forgotPasswordVerify`;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router
  ) {
    this.resetPasswordForm = this.fb.group(
      {
        newPassword: ['', [Validators.required, Validators.minLength(5)]],
        confirmPassword: ['', Validators.required],
      },
      {
        validators: this.passwordMatchValidator,
      }
    );
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const token = params['tk'];
      if (token) {
        this.authService.setToken(token);
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { tk: null },
          queryParamsHandling: 'merge',
        });
      }
    });
  }
  private initForm(): void {
    this.resetPasswordForm = this.fb.group(
      {
        newPassword: ['', [Validators.required, Validators.minLength(5)]],
        confirmPassword: ['', Validators.required],
      },
      {
        validators: this.passwordMatchValidator,
      }
    );
  }

  passwordMatchValidator(group: FormGroup) {
    const newPassword = group.get('newPassword')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;
    return newPassword === confirmPassword ? null : { passwordMismatch: true };
  }

  onSubmit() {
    if (this.resetPasswordForm.hasError('passwordMismatch')) {
      this.toastr.error('Passwords do not match, please try again');
      return;
    }

    if (this.resetPasswordForm.valid) {
      const newPassword = this.resetPasswordForm.value.newPassword;
      const token = this.authService.getToken();
      const headers = new HttpHeaders().set('resetToken', token || '');

      this.loading = true;
      this.http
        .post(this.verifyPasswordUrl, { password: newPassword }, { headers })
        .subscribe({
          next: (response: any) => {
            this.successMessage =
              'Password successfully reset. You can now log in with your new password';
            this.errorMessage = null;
            this.toastr.success(response.message || this.successMessage);
            this.router.navigate(['/login']);
            this.loading = false;
          },
          error: (error) => {
            console.error('Error occurred:', error);
            const backendMessage =
              error.error?.message || 'Something went wrong.';
            const statusCode = error.error?.statusCode || 500;

            if (statusCode === 400 && backendMessage === 'Token is required') {
              this.toastr.error(backendMessage, 'Bad Request');
            } else if (backendMessage === 'Invalid Token') {
              this.toastr.error('Please try it again', 'Invalid Token');
              this.router.navigate(['/forgot-password']);
            } else {
              this.toastr.error('Please try it again', 'Something went wrong.');
              this.router.navigate(['/forgot-password']);
            }

            this.loading = false;
          },
        });
    }
  }

  hide = signal(true);
  clickEvent(event: MouseEvent) {
    this.hide.set(!this.hide());
    event.stopPropagation();
  }
}
