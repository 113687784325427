<div class="form-container">
  <form [formGroup]="branchInfoForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="branch">Branch:</label>
      <select id="branch" formControlName="branch" class="form-control">
        <option value="" disabled>Select Branch</option>
        <option value="office">Office</option>
        <option value="contract">Contract</option>
        <option value="part-time">Part-Time</option>
        <option value="temporary">Temporary</option>
      </select>
      <div
        *ngIf="
          branchInfoForm.get('branch')!.invalid &&
          branchInfoForm.get('branch')!.touched
        "
        class="error"
      >
        Branch is required.
      </div>
    </div>

    <div class="form-group">
      <label for="designation">Designation:</label>
      <select
        id="designation"
        formControlName="designation"
        class="form-control"
      >
        <option value="" disabled>Select Designation</option>
        <option value="office">Office</option>
        <option value="contract">Contract</option>
        <option value="part-time">Part-Time</option>
        <option value="temporary">Temporary</option>
      </select>
      <div
        *ngIf="
          branchInfoForm.get('designation')!.invalid &&
          branchInfoForm.get('designation')!.touched
        "
        class="error"
      >
        Designation is required.
      </div>
    </div>

    <div class="form-group">
      <label for="userName">Username:</label>
      <input id="userName" formControlName="userName" class="form-control" />
      <div
        *ngIf="
          branchInfoForm.get('userName')!.invalid &&
          branchInfoForm.get('userName')!.touched
        "
        class="error"
      >
        Username is required.
      </div>
    </div>

    <div class="form-group">
      <label for="password">Password:</label>
      <input id="password" formControlName="password" type="password" class="form-control" />
      <div
        *ngIf="
          branchInfoForm.get('password')!.invalid &&
          branchInfoForm.get('password')!.touched
        "
        class="error"
      >
        Password is required.
      </div>
    </div>

    <button
      type="submit"
      [disabled]="branchInfoForm.invalid"
      class="btn btn-primary"
    >
      Save
    </button>

    <div *ngIf="errorMessage" class="error">
      {{ errorMessage }}
    </div>
  </form>
</div>
