<app-layout>
    <section class="cmn-innerpage-wrapper employee-details">
        <div class="employee-details-wrapper">
            <div class="head">
                <div class="img-wrapper">
                    <img src="assets/images/profile.png">
                </div>
                <div class="content">
                    <h2>Brooklyn Simmons <span><img src="assets/images/icons/icon-edit-profile.svg" alt="Edit Profile">
                            Edit Profile</span></h2>
                    <h3>Project Manager</h3>
                    <h4><img src="assets/images/icons/icon-mail.svg" alt="Icon Mail"> brooklyn.s&#64;example.com</h4>
                </div>
            </div>

            <div class="main-content-wrapper">
                <div class="left-set">
                    <div class="employee-details-tab-wrapper">
                        <mat-tab-group animationDuration="0ms" [(selectedIndex)]="selectedTabIndex">
                            <mat-tab label="Profile" (click)="setTab(0)">
                                <ng-template mat-tab-label>
                                    <h2 class="tab-1">Profile</h2>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Settings" (click)="setTab(1)">
                                <ng-template mat-tab-label>
                                    <h2 class="tab-2">Attendance</h2>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Messages" (click)="setTab(2)">
                                <ng-template mat-tab-label>
                                    <h2 class="tab-3">Projects</h2>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Messages" (click)="setTab(3)">
                                <ng-template mat-tab-label>
                                    <h2 class="tab-4">Leaves</h2>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Messages" (click)="setTab(4)">
                                <ng-template mat-tab-label>
                                    <h2 class="tab-5">Exit Employee</h2>
                                </ng-template>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
                <div class="right-set">
                    <div *ngIf="selectedTabIndex === 0">
                        <h2>Profile Details</h2>
                    </div>
                    <div *ngIf="selectedTabIndex === 1">
                        <div class="tab-content-wrapper">
                            <!-- The AG Grid component -->
                            <ag-grid-angular style="width: 100%;" 
                                [rowData]="rowData"
                                [columnDefs]="columnDefs" 
                                [defaultColDef]="defaultColDef"
                                [pagination]="true" 
                                [paginationPageSize]="paginationPageSize"
                                [paginationPageSizeSelector]="paginationPageSizeSelector" 
                                [class]="themeClass" />
                        </div>
                    </div>
                    <div *ngIf="selectedTabIndex === 2">
                        <h2>Messages Content</h2>
                        <p>Content 3...</p>
                    </div>
                    <div *ngIf="selectedTabIndex === 3">
                        <h2>Messages Content</h2>
                        <p>Content 3...</p>
                    </div>
                    <div *ngIf="selectedTabIndex === 4">
                        <h2>Messages Content</h2>
                        <p>Content 3...</p>
                    </div>
                </div>
            </div>



        </div>
    </section>