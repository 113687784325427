<app-layout>
  <section class="cmn-innerpage-wrapper employee-details">
    <div class="employee-details-wrapper">
      <div class="head">
        <div class="employee-head">
          <div class="img-wrapper">
            <img
              [src]="
                profilePicture
                  ? profilePicture
                  : 'assets/images/dummy-profile-image.jpg'
              "
              alt="Profile Photo"
            />
          </div>
          <div class="content">
            <h2>
              {{ firstName }} {{ lastName }}
              <span
                *ngIf="source === 'employee'"
                (click)="navigateToEditProfile()"
              >
                <img
                  src="assets/images/icons/icon-edit-profile.svg"
                  alt="Edit Profile"
                />
                Edit Profile
              </span>
            </h2>
            <h3>{{ employeeData.designation?.designationName }}</h3>
            <h4>
              <img src="assets/images/icons/icon-mail.svg" alt="Icon Mail" />
              {{ employeeData.email }}
            </h4>
          </div>
        </div>
      </div>

      <div class="main-content-wrapper">
        <div class="left-set">
          <div class="employee-details-tab-wrapper">
            <mat-tab-group
              animationDuration="0ms"
              [(selectedIndex)]="selectedTabIndex"
              (selectedTabChange)="setTab($event.index)"
            >
              <mat-tab label="Profile">
                <ng-template mat-tab-label>
                  <h2 class="tab-1">Profile</h2>
                </ng-template>
              </mat-tab>

              <mat-tab label="Attendance">
                <ng-template mat-tab-label>
                  <h2 class="tab-2">Attendance</h2>
                </ng-template>
              </mat-tab>

              <mat-tab label="Projects">
                <ng-template mat-tab-label>
                  <h2 class="tab-3">Projects</h2>
                </ng-template>
              </mat-tab>

              <mat-tab label="Leaves">
                <ng-template mat-tab-label>
                  <h2 class="tab-4">Leaves</h2>
                </ng-template>
              </mat-tab>

              <mat-tab label="Exit Employee" *ngIf="source === 'employee'">
                <ng-template mat-tab-label>
                  <h2 class="tab-5">Exit Employee</h2>
                </ng-template>
              </mat-tab>

              <!-- Wrap Assets tab in ng-container -->
              <ng-container *ngIf="source === 'myProfile'">
                <mat-tab label="Assets">
                  <ng-template mat-tab-label>
                    <h2 class="tab-6">Assets</h2>
                  </ng-template> </mat-tab
                ><mat-tab label="Security">
                  <ng-template mat-tab-label>
                    <h2 class="tab-7">Security</h2>
                  </ng-template> </mat-tab
                ><mat-tab label="Vacation">
                  <ng-template mat-tab-label>
                    <h2 class="tab-8">Vacation</h2>
                  </ng-template>
                </mat-tab>
              </ng-container>
            </mat-tab-group>
          </div>
        </div>

        <div class="right-set">
          <div *ngIf="selectedTabIndex === 0">
            <app-profile></app-profile>
          </div>
          <div *ngIf="selectedTabIndex === 1">
            <div class="tab-content-wrapper">
              <app-attendance></app-attendance>
            </div>
          </div>
          <div *ngIf="selectedTabIndex === 2">
            <div class="tab-content-wrapper">
              <app-projects></app-projects>
            </div>
          </div>
          <div *ngIf="selectedTabIndex === 3">
            <div class="tab-content-wrapper">
              <app-leaves></app-leaves>
            </div>
          </div>
          <div *ngIf="selectedTabIndex === 4 && source === 'employee'">
            <div class="tab-content-wrapper">
              <app-exit-employee
                [employeeId]="employeeId"
                [employeeData]="employeeData"
              ></app-exit-employee>
            </div>
          </div>
          <div *ngIf="selectedTabIndex === 4 && source === 'myProfile'">
            <div class="tab-content-wrapper">
              <app-assets></app-assets>
            </div>
          </div>
          <div *ngIf="selectedTabIndex === 5 && source === 'myProfile'">
            <div class="tab-content-wrapper">
              <app-security></app-security>
            </div>
          </div>
          <div *ngIf="selectedTabIndex === 6 && source === 'myProfile'">
            <div class="tab-content-wrapper">
              <app-vacation></app-vacation>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</app-layout>
