<app-layout>
    <section class="reports-main-section">
        <div class="reports-list-wrapper">
            <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search" />
            </div>
            <div class="reports-tab-wrapper">
                <mat-tab-group animationDuration="0ms" [(selectedIndex)]="selectedTabIndex">
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Profit & Loss</h2>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Cash Flow Statement</h2>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Balance Sheet</h2>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Sales by customer</h2>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Vendor Balance summary</h2>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Accounts Receivable</h2>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Accounts Payable</h2>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Project-wise</h2>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Accounting Transactions</h2>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Journal Voucher</h2>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Ongoing Projects</h2>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Bank Reconciliation Report</h2>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Post-dated Checks</h2>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Fixed Assets Module/Register</h2>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Inventory Report</h2>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        <div class="reports-main-content-wrapper">
            <div class="head">
                <h4><img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" /> Filters</h4>
                <div class="branch-wrapper">
                    <h3>Date Range</h3>
                    <form>
                        <mat-form-field>
                            <mat-select [(value)]="selectedOption">
                                <mat-option value="today"> Today </mat-option>
                                <mat-option value="week"> This Week </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <div *ngIf="selectedTabIndex === 0">
                <div class="tab-inner-content">
                    <h2>Profit & Loss</h2>
                    <h5>From 12/07/24 to 28/08/24</h5>
                    <table>
                        <tr>
                            <th>Account</th>
                            <th></th>
                            <th>Total</th>
                        </tr>
                        <tr class="title">
                            <td>Operating Income</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr class="child">
                            <td>Sales</td>
                            <td></td>
                            <td>3,500</td>
                        </tr>
                        <tr class="sub-total">
                            <td>Total for Cost of Goods Sold</td>
                            <td></td>
                            <td>3,500</td>
                        </tr>
                        <tr class="title">
                            <td>Cost of Goods Sold</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr class="child">
                            <td>Cost of Goods </td>
                            <td></td>
                            <td>2,700</td>
                        </tr>
                        <tr class="sub-total">
                            <td>Total for Cost of Goods Sold</td>
                            <td></td>
                            <td>2,700</td>
                        </tr>
                        <tr class="total">
                            <td></td>
                            <td>Gross Profit</td>
                            <td>6200.00</td>
                        </tr>
                        <tr class="title">
                            <td>Operating Expense</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr class="sub-total">
                            <td>Total for Operating Expense</td>
                            <td></td>
                            <td>5400</td>
                        </tr>
                        <tr class="total">
                            <td></td>
                            <td>Operating Profit</td>
                            <td>800.00</td>
                        </tr>
                        <tr class="title">
                            <td>Non Operating Income</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr class="sub-total">
                            <td>Total for Non Operating Income</td>
                            <td></td>
                            <td>0</td>
                        </tr>
                        <tr class="title">
                            <td>Non Operating Expense</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr class="sub-total">
                            <td>Total for Non Operating Expense</td>
                            <td></td>
                            <td>0</td>
                        </tr>
                        <tr class="total">
                            <td></td>
                            <td>Net Profit/Loss</td>
                            <td>AED 800.00</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div *ngIf="selectedTabIndex === 1">
                2
            </div>
            <div *ngIf="selectedTabIndex === 2">
                <div class="tab-inner-content">
                    <table>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- Loop through each row and apply padding based on the level -->
                            <tr *ngFor="let node of getTableRows(data[0])" [ngClass]="getNodeClass(node)">
                                <!-- Use data[0] to start from the root node -->
                                <td [ngStyle]="{ 'padding-left': ((node.level ?? 0) * 20) + 'px' }">{{ node.name }}</td>
                                <td>{{ node.details }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
</app-layout>