<div class="tab-content-wrapper">
  <form [formGroup]="branchAccessForm" (ngSubmit)="onNext()">
    <div class="employee-form-wrapper">
      <div class="form-wrapper">
        <label for="">Select Branch</label>
        <mat-form-field>
          <mat-select placeholder="Select Branch" formControlName="branchName">
            <mat-option *ngFor="let branch of branchData" [value]="branch.id">
              {{ branch.branchName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              branchAccessForm.get('branchName')!.touched &&
              branchAccessForm.get('branchName')!.hasError('required')
            "
            >Branch Name is required.</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <div class="form-wrapper">
      <label class="checkbox-wrapper">
        <input type="checkbox" formControlName="isPortalUser" />Is Portal User
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="employee-form-wrapper">
      <div
        *ngIf="branchAccessForm.get('isPortalUser')?.value"
        class="form-wrapper"
      >
        <label for="">Select User Role</label>
        <mat-form-field>
          <mat-select placeholder="Select User Role" formControlName="userRole">
            <mat-option *ngFor="let role of userRolesData" [value]="role.id">
              {{ role.profileName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        *ngIf="
          branchAccessForm.get('isPortalUser')?.value &&
          (!isEditMode || isPortalUserEnabled)
        "
        class="form-wrapper"
      >
        <label for="">Password</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Password"
            formControlName="password"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
      <button class="cmn-next-btn" type="submit">Next</button>
    </div>
  </form>
</div>
