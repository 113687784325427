<div class="tab-content-wrapper">
    <div class="employee-form-wrapper">
        <div class="form-wrapper">
            <label for="">Select User Role</label>
            <mat-form-field>
                <mat-select placeholder="Select Select User Role" formControlName="country">
                    <mat-option value="option1">Option 1</mat-option>
                    <mat-option value="option2">Option 2</mat-option>
                    <mat-option value="option3">Option 3</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="form-wrapper">
            <label for="">Select Branch</label>
            <mat-form-field>
                <mat-select placeholder="Select Select Branch" formControlName="country">
                    <mat-option value="option1">Option 1</mat-option>
                    <mat-option value="option2">Option 2</mat-option>
                    <mat-option value="option3">Option 3</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="form-wrapper">
            <label for="">Username</label>
            <mat-form-field>
                <input matInput placeholder="Enter Username" formControlName="branchName" />
            </mat-form-field>
        </div>
        <div class="form-wrapper">
            <label for="">Password</label>
            <mat-form-field>
                <input matInput placeholder="Enter Password" formControlName="branchName" />
            </mat-form-field>
        </div>
    </div>
    <div class="submit-btn-wrapper">
        <button class="cmn-cancel-btn">Cancel</button>
        <button class="cmn-next-btn">Next</button>
    </div>
</div>