import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PersonalInfoComponent } from './components/personal-info/personal-info.component';
import { ProfessionalInfoComponent } from './components/professional-info/professional-info.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { BranchAccessComponent } from './components/branch-access/branch-access.component';
import { SalaryDetailsComponent } from './components/salary-details/salary-details.component';
import { EmployeeService } from '../../features/employees/services/employee.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-employee',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatTabsModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    PersonalInfoComponent,
    ProfessionalInfoComponent,
    DocumentsComponent,
    BranchAccessComponent,
    SalaryDetailsComponent,
  ],
  templateUrl: './add-employee.component.html',
  styleUrl: './add-employee.component.scss',
})
export class AddEmployeeComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  employeeForm: FormGroup;
  employeeData: any = {};

  selectedEmployee: any;
  selectedEmployeeSalaryDetails: any;
  isEditMode: boolean = false;

  selectedTabIndex = 0;
  tabStates = [true, false, false, false, false];

  constructor(
    private employeeService: EmployeeService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        const employeeId = +id;

        // Fetch employee details
        this.employeeService.getEmployeeById(employeeId).subscribe(
          (employee) => {
            this.selectedEmployee = employee;
            this.isEditMode = true;
            console.log('Selected employee----', this.selectedEmployee);

            // Check if employeeSalaries exists and has at least one item
            if (this.selectedEmployee?.employeeSalaries?.length > 0) {
              const salaryId = this.selectedEmployee.employeeSalaries[0].id;
              console.log('salaryId-------', salaryId);
              this.employeeService.getEmployeeSalaryById(salaryId).subscribe(
                (salaryDetails) => {
                  this.selectedEmployeeSalaryDetails = salaryDetails;
                  console.log(
                    'Fetched employee salary details----',
                    salaryDetails
                  );
                },
                (error) => {
                  console.error('Error fetching salary details:', error);
                }
              );
            } else {
              console.warn(
                'No salary details found for the selected employee.'
              );
            }
          },
          (error) => {
            console.error('Error fetching employee details:', error);
          }
        );
      }
    });
  }

  collectPersonalInfo(data: any, currentTabIndex: number) {
    this.employeeData.personalInfo = data;
    console.log(
      'Collected Personal Data--------',
      this.employeeData.personalInfo
    );
    if (currentTabIndex < this.tabStates.length) {
      this.tabStates[currentTabIndex] = true; // Enable the next tab
      this.selectedTabIndex = currentTabIndex; // Move to the next tab
    }
    // this.tabGroup.selectedIndex = 1;
  }

  collectProfessionalInfo(data: any, currentTabIndex: number) {
    this.employeeData.professionalInfo = data;
    console.log(
      'Collected professional data------',
      this.employeeData.professionalInfo
    );
    if (currentTabIndex < this.tabStates.length) {
      this.tabStates[currentTabIndex] = true; // Enable the next tab
      this.selectedTabIndex = currentTabIndex; // Move to the next tab
    }
    // this.tabGroup.selectedIndex = 2;
  }

  collectDocuments(data: any, currentTabIndex: number) {
    this.employeeData.documents = data;
    console.log('Collected documents data-----', this.employeeData.documents);
    if (currentTabIndex < this.tabStates.length) {
      this.tabStates[currentTabIndex] = true; // Enable the next tab
      this.selectedTabIndex = currentTabIndex; // Move to the next tab
    }
    // this.tabGroup.selectedIndex = 3;
  }

  collectBranchAccess(data: any, currentTabIndex: number) {
    this.employeeData.branchAccess = data;
    console.log(
      'Collected branch access data-------',
      this.employeeData.branchAccess
    );
    if (currentTabIndex < this.tabStates.length) {
      this.tabStates[currentTabIndex] = true; // Enable the next tab
      this.selectedTabIndex = currentTabIndex; // Move to the next tab
    }
    // this.tabGroup.selectedIndex = 4;
  }

  collectSalaryDetails(data: any, currentTabIndex: number) {
    this.employeeData.salaryDetails = data;
    console.log(
      'Collected salary data-------',
      this.employeeData.salaryDetails
    );
    if (currentTabIndex < this.tabStates.length) {
      this.tabStates[currentTabIndex] = true; // Enable the next tab
      this.selectedTabIndex = currentTabIndex; // Move to the next tab
    }
    this.submitEmployeeData();
  }

  submitEmployeeData() {
    console.log('IsEdited------', this.isEditMode);

    const employeeInput = {
      firstName: this.employeeData.personalInfo.firstName || '',
      middleName: this.employeeData.personalInfo.middleName || '',
      lastName: this.employeeData.personalInfo.lastName || '',
      profilePicture: this.employeeData.personalInfo.profilePicture || '',
      dateOfBirth: this.employeeData.personalInfo.dateOfBirth || '',
      gender: this.employeeData.personalInfo.gender || '',
      email: this.employeeData.personalInfo.email || '',
      phoneNumber: this.employeeData.personalInfo.phoneNumber || '',
      alternatePhoneNumber:
        this.employeeData.personalInfo.alternatePhoneNumber || '',
      address: this.employeeData.personalInfo.address || '',
      maritalStatus: this.employeeData.personalInfo.maritalStatus || '',
      nationality: this.employeeData.personalInfo.nationality || '',

      visaType: this.employeeData.documents.visaType || '',
      visaIssueDate: this.employeeData.documents.visaIssueDate || '',
      visaExpiryDate: this.employeeData.documents.visaExpiryDate || '',
      passportNumber: this.employeeData.documents.passportNumber || '',
      bankName: this.employeeData.documents.bankName || '',
      bankAccountNumber: this.employeeData.documents.accountNumber || '',
      IFSCCode: this.employeeData.documents.IFSCCode || '',

      employeeType: this.employeeData.professionalInfo.employeeType || '',
      employmentType: this.employeeData.professionalInfo.employmentType || '',
      employeeStatus: this.employeeData.professionalInfo.employeeStatus || '',
      departmentId: this.employeeData.professionalInfo.department || '',
      designationId: this.employeeData.professionalInfo.designation || '',
      dateOfJoining: this.employeeData.professionalInfo.joiningDate || '',
      salary: this.employeeData.professionalInfo.currentCTC || '',

      branchId: this.employeeData.branchAccess.branchName || '',
      isPortalUser: this.employeeData.branchAccess.isPortalUser || false,
    };

    const createUserDTO = {
      password: this.employeeData.branchAccess.password || '',
      profileId: this.employeeData.branchAccess.userRole,
    };

    const employeeAction = this.isEditMode
      ? this.employeeService.updateEmployee(
          this.selectedEmployee.id,
          employeeInput,
          createUserDTO
        )
      : this.employeeService.createEmployee(employeeInput, createUserDTO);

    employeeAction
      .pipe(
        switchMap((employeeResponse: any) => {
          const employeeId = this.isEditMode
            ? this.selectedEmployee.id
            : employeeResponse?.data?.createEmployee?.id;

          // Create the employee salary input object
          const createEmployeeSalaryInput: any = {
            employeeId: employeeId,
            basicSalary: this.employeeData.salaryDetails.basicSalary || 0,
            hra: this.employeeData.salaryDetails.hra || 0,
            allowances: this.employeeData.salaryDetails.allowances || 0,
            deductions: this.employeeData.salaryDetails.deductions || 0,
            grossSalary: this.employeeData.salaryDetails.grossSalary || 0,
            netSalary: this.employeeData.salaryDetails.netSalary || 0,
            remarks: this.employeeData.salaryDetails.remarks || '',
          };

          if (
            this.isEditMode &&
            this.selectedEmployee.employeeSalaries?.[0]?.id
          ) {
            createEmployeeSalaryInput.id =
              this.selectedEmployee.employeeSalaries[0].id;
          }

          // Call create or update salary depending on whether the salary exists
          return this.isEditMode && this.selectedEmployee.employeeSalaries?.[0]
            ? this.employeeService.updateEmployeeSalary(
                createEmployeeSalaryInput
              )
            : this.employeeService.createEmployeeSalary(
                createEmployeeSalaryInput
              );
        })
      )
      .subscribe({
        next: (response) => {
          console.log('Employee processed successfully:', response);
          this.toastr.success(
            this.isEditMode
              ? 'Employee updated successfully'
              : 'Employee created successfully'
          );
          this.router.navigate(['/employees']);
        },
        error: (error) => {
          console.error('Error processing employee: ', error);
          this.toastr.error('Error processing employee', error);
        },
      });
  }
}
