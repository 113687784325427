import { gql } from 'apollo-angular';

export const GET_MY_ATTENDANCES = gql`
  query getMyAttendances($filter: AttendanceFilterInput) {
    getMyAttendances(filter: $filter) {
      id
      date
      checkInTime
      checkOutTime
      totalHoursWorked
      overtimeHours
      attendanceStatus
      markedBy
      shift
      notes
      employeeId
      employee {
        id
        firstName
        lastName
        middleName
        dateOfBirth
        gender
        email
        phoneNumber
        alternatePhoneNumber
        address
        permanentAddress
        dateOfJoining
        employmentType
        employeeType
        employeeStatus
        profilePicture
        maritalStatus
        nationality
        passportNumber
        visaType
        visaIssueDate
        visaExpiryDate
        salary
        bankName
        bankAccountNumber
        IFSCCode
        departmentId
      }
    }
  }
`;
