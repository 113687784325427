import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile-salary-details',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './profile-salary-details.component.html',
  styleUrl: './profile-salary-details.component.scss',
})
export class ProfileSalaryDetailsComponent {
  salaryInfoForm: FormGroup;
  errorMessage: string | null = null;
  @Output() formSubmitted = new EventEmitter<void>();
  constructor(private fb: FormBuilder, private toastr: ToastrService) {
    this.salaryInfoForm = this.fb.group({
      annualPay: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      housingAllowance: ['', Validators.required],
      transportAllowance: ['', Validators.required],
      medicalInsurance: ['', Validators.required],
      overtimePay: ['', Validators.required],
      allowances: ['', Validators.required],
    });
  }

  onSubmit(): void {
    if (this.salaryInfoForm) {
      this.toastr.success('Salary Info submitted succesfully');
      console.log('Salary Info------', this.salaryInfoForm.value);
      this.formSubmitted.emit();
    } else {
      this.toastr.error('Something went wrong');
    }
  }
}
