<app-layout>
    <section class="cmn-innerpage-wrapper new-chart-accounts">
        <h2>Create Account</h2>
        <form>
            <div class="chart-account-form-wrapper">
                <div class="form-wrapper">
                    <label for="">Account type</label>
                    <mat-form-field>
                        <mat-select placeholder="Select Gender">
                            <mat-option value="Male">Male</mat-option>
                            <mat-option value="Female">Female</mat-option>
                            <mat-option value="Others">Others</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Account name</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter name" />
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Account Code</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter code" />
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Description</label>
                    <mat-form-field>
                        <textarea matInput placeholder="max 500 characters"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="submit-btn-wrapper">
                <button class="cmn-cancel-btn">Cancel</button>
                <button class="cmn-next-btn">Save</button>
            </div>
        </form>
    </section>
</app-layout>