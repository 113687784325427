<div class="dashboard-container">
  <button (click)="togglePunch()">
    {{ isPunchedIn ? "Punch-Out" : "Punch-In" }}
  </button>

  <div class="punch-info">
    <!-- <div *ngFor="let punch of punchTimes">
      <p *ngIf="punch.punchIn">
        Punch-In Time: {{ punch.punchIn | date : "shortTime" }}
      </p>
      <p *ngIf="punch.punchOut">
        Punch-Out Time: {{ punch.punchOut | date : "shortTime" }}
      </p>
    </div> -->
    <p class="total-time">Total Punched-In Time: {{ getTotalPunchedInTime() }}</p>
  </div>
</div>
