import {
  Component,
  HostListener,
  OnDestroy,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatButtonModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnDestroy, OnInit {
  form = new FormGroup({
    selectedOption: new FormControl('option2'), // Default value
  });

  branches = [
    { value: 'option1', viewValue: 'Creative Spaces' },
    { value: 'option2', viewValue: 'Artisan Interiors' },
    { value: 'option3', viewValue: 'Elysian Designs' },
    { value: 'option4', viewValue: 'Luxury Spaces' },
  ];

  isDropdownOpen = false;

  @Input() sidebarData: any;

  constructor(
    private elementRef: ElementRef,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    console.log('ngOnInit:', this.sidebarData);
  }

  toggleDropdown(event: MouseEvent) {
    event.stopPropagation();
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  handleDropdownClick(event: MouseEvent) {
    event.stopPropagation(); // Prevents click from propagating to the document
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
    window.location.reload();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  ngOnDestroy() {
    // Cleanup if needed
  }
}
