import { gql } from 'apollo-angular';

export const GET_CURRENCIES = gql`
  query currencies($search: String, $filter: CurrencyFilter) {
    currencies(search: $search, filter: $filter) {
      id
      currencyCode
      currencyName
      currencySymbol
      exchangeRate
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
