<app-layout>
    <section class="cmn-innerpage-wrapper chart-accounts">
        <div class="head">
            <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search" />
            </div>
            <button class="cmn-add-btn">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New Account
            </button>
        </div>
        <div class="sub-head">
            <ul>
                <li class="main">All Accounts</li>
                <li class="edit"><img src="../../../assets/images/icons/icon-edit-accounts.svg" alt="Edit"> Edit</li>
            </ul>
        </div>
        <div class="chart-accounts-tab-wrapper">
            <div class="tab-left">
                <mat-tab-group animationDuration="0ms" [(selectedIndex)]="selectedTabIndex">
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Petty Cash</h2>
                            <h3>Cash</h3>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Furniture and equipments</h2>
                            <h3>Fixed Asset</h3>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Accounts receivable</h2>
                            <h3>Accounts Receivable</h3>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Reimbursements Payable</h2>
                            <h3>Other Current Liability</h3>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Profile">
                        <ng-template mat-tab-label>
                            <h2>Net Salary Payable</h2>
                            <h3>Other Current Liability</h3>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <div class="chart-main-content-wrapper">
                <div *ngIf="selectedTabIndex === 0">
                    <div class="chart-tab-content">
                        <h4>AED 2,800 <span>Closing Balance</span></h4>
                        <p>Description : It is a small amount of cash that is used to pay your minor or casual expenses
                            rather than writing a check.</p>
                        <h5>Recent Transactions</h5>
                        <table>
                            <tr>
                                <th>Date</th>
                                <th>Transaction Details</th>
                                <th>Type</th>
                                <th>Debit</th>
                                <th>Credit</th>
                            </tr>
                            <tr>
                                <td>14/08/2024</td>
                                <td>Mr. Jacob Jones</td>
                                <td>Expense</td>
                                <td></td>
                                <td>AED 950</td>
                            </tr>
                            <tr>
                                <td>14/08/2024</td>
                                <td>Dans Design</td>
                                <td>Customer Payment</td>
                                <td>AED 1,210</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>14/08/2024</td>
                                <td>Mr. Jacob Jones</td>
                                <td>Invoice Payment</td>
                                <td>AED 1000</td>
                                <td></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 1">
                    <div class="chart-tab-content">
                        <h4>AED 2,800 <span>Closing Balance</span></h4>
                        <p>Description : It is a small amount of cash that is used to pay your minor or casual expenses
                            rather than writing a check.</p>
                        <h5>Recent Transactions</h5>
                        <table>
                            <tr>
                                <th>Date</th>
                                <th>Transaction Details</th>
                                <th>Type</th>
                                <th>Debit</th>
                                <th>Credit</th>
                            </tr>
                            <tr>
                                <td>14/08/2024</td>
                                <td>Mr. Jacob Jones</td>
                                <td>Expense</td>
                                <td></td>
                                <td>AED 950</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 2">
                    <div class="chart-tab-content">
                        <h4>AED 2,800 <span>Closing Balance</span></h4>
                        <p>Description : It is a small amount of cash that is used to pay your minor or casual expenses
                            rather than writing a check.</p>
                        <h5>Recent Transactions</h5>
                        <table>
                            <tr>
                                <th>Date</th>
                                <th>Transaction Details</th>
                                <th>Type</th>
                                <th>Debit</th>
                                <th>Credit</th>
                            </tr>
                            <tr>
                                <td>14/08/2024</td>
                                <td>Mr. Jacob Jones</td>
                                <td>Expense</td>
                                <td></td>
                                <td>AED 950</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 3">
                    <div class="chart-tab-content">
                        <h4>AED 2,800 <span>Closing Balance</span></h4>
                        <p>Description : It is a small amount of cash that is used to pay your minor or casual expenses
                            rather than writing a check.</p>
                        <h5>Recent Transactions</h5>
                        <table>
                            <tr>
                                <th>Date</th>
                                <th>Transaction Details</th>
                                <th>Type</th>
                                <th>Debit</th>
                                <th>Credit</th>
                            </tr>
                            <tr>
                                <td>14/08/2024</td>
                                <td>Mr. Jacob Jones</td>
                                <td>Expense</td>
                                <td></td>
                                <td>AED 950</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 4">
                    <div class="chart-tab-content">
                        <h4>AED 2,800 <span>Closing Balance</span></h4>
                        <p>Description : It is a small amount of cash that is used to pay your minor or casual expenses
                            rather than writing a check.</p>
                        <h5>Recent Transactions</h5>
                        <table>
                            <tr>
                                <th>Date</th>
                                <th>Transaction Details</th>
                                <th>Type</th>
                                <th>Debit</th>
                                <th>Credit</th>
                            </tr>
                            <tr>
                                <td>14/08/2024</td>
                                <td>Mr. Jacob Jones</td>
                                <td>Expense</td>
                                <td></td>
                                <td>AED 950</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</app-layout>