<section class="cmn-innerpage-wrapper branch">
  <div class="date-filter">
    <label for="startDate">Start Date:</label>
    <input
      type="date"
      id="startDate"
      [(ngModel)]="startDate"
      (change)="onDateFilterChange()"
    />

    <label for="endDate">End Date:</label>
    <input
      type="date"
      id="endDate"
      [(ngModel)]="endDate"
      (change)="onDateFilterChange()"
    />
  </div>

  <div class="inner-content">
    <div *ngIf="loading">Loading...</div>
    <div *ngIf="error" class="error">Error loading data</div>
    <ag-grid-angular
      *ngIf="!loading && !error"
      style="width: 100%; height: calc(100vh - 235px)"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowSelection]="rowSelection"
      [suppressRowClickSelection]="true"
      [pagination]="true"
      [paginationPageSize]="paginationPageSize"
      [paginationPageSizeSelector]="paginationPageSizeSelector"
      [class]="themeClass"
    ></ag-grid-angular>
  </div>
</section>
