<div class="layout-container">
    <div class="sidebar" [class.collapsed]="isCollapsed">
        <button (click)="toggleSidebar()" class="toggle-btn"></button>
        <nav class="sidebar-nav">
            <app-sidebar [sidebarData]="dataFromLayout" (triggerSidebarAction)="triggerSidebarOnce()"></app-sidebar>
        </nav>
    </div>
    <div class="main-content" [class.collapsedMain]="isCollapsed">
        <app-header [sidebarData]="dataFromLayout"></app-header>
        <ng-content></ng-content>
    </div>
</div>

<button (click)="toggleSidebar()" class="responsive-nav-btn"></button>