<app-layout>
  <section class="cmn-innerpage-wrapper employees">
    <div class="head">
      <div class="left-set">
        <div class="search-wrapper">
          <input type="text" class="cmn-header-search" placeholder="Search" />
        </div>
        <button
          class="cmn-filter-btn"
          (click)="toggleDropdown($event)"
          [ngClass]="{ 'class-clicked': isDropdownOpen }"
        >
          <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
        </button>
      </div>
      <div class="right-set">
        <button
          class="cmn-filter-btn"
          (click)="toggleDropdown($event)"
          [ngClass]="{ 'class-clicked': isDropdownOpen }"
        >
          <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
        </button>
        <div
          [ngClass]="{ show: isDropdownOpen }"
          class="common-dropdown filter-position"
          #dropdown
        >
          <button
            class="close-dropdown"
            (click)="toggleDropdown($event)"
          ></button>
          <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
            <h2>Branch</h2>
            <div
              formArrayName="branches"
              *ngIf="branchData.length > 0"
              class="check-wrapper"
            >
              <div
                class="check-box"
                *ngFor="let branch of branchData; let i = index"
              >
                <label class="checkbox-wrapper">
                  <input type="checkbox" [formControlName]="i" />
                  {{ branch.branchName }}
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <h2>Employee Type</h2>
            <div
              formArrayName="employeeTypes"
              *ngIf="employeeTypeData.length > 0"
              class="check-wrapper"
            >
              <div
                class="check-box"
                *ngFor="let employeeType of employeeTypeData; let i = index"
              >
                <label class="checkbox-wrapper">
                  <input type="checkbox" [formControlName]="i" />
                  {{ employeeType.employeeType }}
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <div class="btn-wrapper">
              <button class="cmn-apply-btn" type="submit">Apply</button>
            </div>
          </form>
        </div>
        <button class="cmn-upload-btn" (click)="openDialog()">
          <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
          <span>Upload</span>
        </button>
        <button class="cmn-add-btn">
          <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Add New
          Employee
        </button>
        <button class="cmn-export-btn" (click)="toggleExport($event)">
          <img src="assets/images/icons/icon-export.svg" alt="Filter Icon" />
          <!-- <span>Export</span> -->
        </button>
        <div
          [ngClass]="{ show: isExportOpen }"
          class="common-dropdown export-position"
          #export
        >
          <button
            class="close-dropdown"
            (click)="toggleExport($event)"
          ></button>
          <!-- <h2>Export Table</h2>
          <div class="check-wrapper">
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input type="checkbox" />Employee Name
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input type="checkbox" />Employee ID
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input type="checkbox" />Branch Name
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input type="checkbox" />Designation
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input type="checkbox" />Type
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input type="checkbox" />Status
                <span class="checkmark"></span>
              </label>
            </div>
          </div> -->
          <h2>Export Table</h2>
          <div class="check-wrapper">
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input
                  type="checkbox"
                  (change)="onFieldCheckboxChange($event, 'firstName')"
                />Employee Name
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input
                  type="checkbox"
                  (change)="onFieldCheckboxChange($event, 'employeeId')"
                />Employee ID
                <span class="checkmark"></span>
              </label>
            </div>
            <!-- <div class="check-box">
              <label class="checkbox-wrapper">
                <input
                  type="checkbox"
                  (change)="onFieldCheckboxChange($event, 'branchName')"
                />Branch Name
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input
                  type="checkbox"
                  (change)="onFieldCheckboxChange($event, 'designation')"
                />Designation
                <span class="checkmark"></span>
              </label>
            </div> -->
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input
                  type="checkbox"
                  (change)="onFieldCheckboxChange($event, 'employeeType')"
                />Type
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input
                  type="checkbox"
                  (change)="onFieldCheckboxChange($event, 'employeeStatus')"
                />Status
                <span class="checkmark"></span>
              </label>
            </div>
          </div>

          <div class="btn-wrapper">
            <button
              class="cmn-apply-btn"
              type="submit"
              (click)="exportEmployees()"
            >
              Export
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="inner-content">
      <ag-grid-angular
        style="width: 100%"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowSelection]="rowSelection"
        [suppressRowClickSelection]="true"
        [pagination]="true"
        [paginationPageSize]="paginationPageSize"
        [paginationPageSizeSelector]="paginationPageSizeSelector"
        [class]="themeClass"
      />
    </div>
  </section>
</app-layout>
