import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { PersonalInfoProfileComponent } from './components/personal-info/personal-info-profile';
import { ProfessionalInfoProfileComponent } from './components/professional-info/professional-info-profile';
import { DocumentsProfileComponent } from './components/documents/documents-profile';
import { BranchAccessProfileComponent } from './components/branch-access/branch-access-profile';
import { SalaryDetailsProfileComponent } from './components/salary-details/salary-details-profile';
import { EmployeeService } from '../../../../features/employees/services/employee.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    CommonModule,
    MatTabGroup,
    MatTabsModule,
    PersonalInfoProfileComponent,
    ProfessionalInfoProfileComponent,
    DocumentsProfileComponent,
    BranchAccessProfileComponent,
    SalaryDetailsProfileComponent,
  ],
  templateUrl: './profile.component.html',
  styleUrl: '../../employee-details.component.scss',
})
export class ProfileComponent implements OnInit {

  employeeData: any = {}; 

  constructor(
    private employeeService: EmployeeService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const employeeId = this.route.snapshot.paramMap.get('id');
    if (employeeId) {
      this.loadEmployeeData(+employeeId);
    }
  }

  loadEmployeeData(employeeId: number): void {
    this.employeeService.getEmployeeById(employeeId).subscribe((data) => {
      this.employeeData = data;
    });
  }

}
