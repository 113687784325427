import { Component } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { VacationService } from '../../../../../../features/vacation/services/vacation.service';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'app-vacation-apply',
    standalone: true,
    imports: [
      CommonModule,
      MatInputModule,
      MatDatepickerModule,
      MatSelectModule,
      ReactiveFormsModule,
      FormsModule
    ],
    templateUrl: './vacation-apply.component.html',
    styleUrl: '../../../../employee-details.component.scss'
  })
  export class VacationApplyComponent {
    vacationApplyForm: FormGroup;
  employeeId: any;

    constructor(
      private fb: FormBuilder,
      private datePipe: DatePipe,
      private route: ActivatedRoute,
      private vacationService: VacationService,
      private toastr: ToastrService,
    ) { 
      this.route.params.subscribe((params: Params) => {
        this.employeeId = params['id'];
      })
    }

    ngOnInit() {
      this.initForm();
    }
  
    initForm() {
      this.vacationApplyForm = this.fb.group({
        employeeId: [''],
        startDate: ['', Validators.required],
        // firstSession: ['', Validators.required],
        endDate: ['', Validators.required],
        requestDate: [''],  
        vacationStatus: [''],
        status: [''],
        // secondSession: ['', Validators.required],
        // notes: ['']
      });
    }

    onApplyVacation() {
      if (this.vacationApplyForm.invalid) {
        this.vacationApplyForm.markAllAsTouched();
        return;
      }
  
      const startDate = this.datePipe.transform(this.vacationApplyForm.value.startDate, "yyyy-MM-dd");
      const endDate = this.datePipe.transform(this.vacationApplyForm.value.endDate, "yyyy-MM-dd");
      
      const isDateValid = (startDate && endDate)
      ? new Date(endDate) >= new Date(startDate)
      ? true
      : this.toastr.error('To date cannot be earlier than from date', 'Error')
      : this.toastr.error('Choose a valid date', 'Error');
      
      if (isDateValid === true) {
        this.vacationApplyForm.patchValue({
          startDate: startDate,
          endDate: endDate,
          requestDate: this.datePipe.transform(new Date(), "yyyy-MM-dd"),
          employeeId: Number(this.employeeId),
          vacationStatus: 'Upcoming',
          status: 'Pending'
        });
        
        const vacationData = this.vacationApplyForm.value;
        if(vacationData){
        this.vacationService.createVacationRequest(vacationData).subscribe(
          (response: any) => {
            if(response){
            this.toastr.success('Vacation applied successfully!', 'Success');
            this.vacationApplyForm.reset();
            } else if(response?.errors){
              this.toastr.error(response?.errors[0]?.message, 'Error');
            }
          },
          (error: Error) => {
            this.toastr.error(error?.message, 'Error');
          }
        );
        }
      }
  
    }
  
    onCancel() {
      this.vacationApplyForm.reset();
    }
  }