import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { BranchService } from '../../features/branch/services/branch.service';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { MatOptionModule } from '@angular/material/core';
import { numericValidator, uaePhoneNumberValidator } from '../../shared/components/validations';

@Component({
  selector: 'app-branch',
  standalone: true,
  imports: [LayoutComponent, AgGridAngular, MatButtonModule, MatDialogModule],
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss'],
})
export class BranchComponent {
  public rowData: any[] | null = null;
  public columnDefs: ColDef[] = [
    { field: 'branchName', headerName: 'Branch Name', flex: 1 },
    { field: 'location', headerName: 'Location', flex: 1 },
    {
      field: 'branchCode',
      headerName: 'Branch Code',
      filter: 'agNumberColumnFilter',
      flex: 1,
    },
    {
      field: 'employeeCount',
      headerName: 'Total Employees',
      filter: 'agNumberColumnFilter',
      flex: 1,
    },
    {
      field: 'projectInProgress',
      headerName: 'Projects In Progress',
      filter: 'agNumberColumnFilter',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      cellRenderer: (params: any) => {
        return `
          <div style="display: flex; align-items: center; gap: 20px;">
            <span style="cursor: pointer;"><img src="assets/images/icons/icon-edit.svg"/></span>
            <span style="cursor: pointer;"><img src="assets/images/icons/icon-delete.svg"/></span>
          </div>
        `;
      }
    },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';
  public loading: boolean = true;
  public error: any = null;

  private searchSubject = new Subject<string>();

  constructor(private branchService: BranchService) {}

  ngOnInit() {
    this.fetchBranchData();

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchBranchData(searchTerm);
      });
  }

  private fetchBranchData(search: string = '') {
    this.loading = true;
    this.branchService.fetchBranches(search).subscribe({
      next: (branches) => {
        let inProgressCount = 0;

        this.rowData = branches.map((branch: any) => {
          const projectsInProgress = Array.isArray(branch?.projects)
            ? branch.projects.filter(
                (project: any) => project.status === 'In-Progress'
              )
            : [];

          inProgressCount += projectsInProgress.length;

          return {
            branchName: branch?.branchName || 'N/A',
            location: branch?.city || 'N/A',
            employeeCount: Array.isArray(branch?.employee)
              ? branch.employee.length
              : 0,
            branchCode: branch?.branchCode || '--',
            projectInProgress: projectsInProgress.length,
          };
        });
        this.loading = false;
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
      },
    });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  readonly dialog = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(AddBranchDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.fetchBranchData();  
      }
    });
  }
}

const gridDiv = document.querySelector<HTMLElement>('#myGrid')!;

@Component({
  selector: 'add-branch-dialog',
  templateUrl: './add-branch-dialog.html',
  styleUrls: ['./branch.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    CommonModule,
  ],
})
export class AddBranchDialog implements OnInit {
  @Output() branchAdded = new EventEmitter<any>();
  branchForm!: FormGroup;
  countries: any[] = [];

  constructor(
    private brancService: BranchService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<AddBranchDialog>,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadCountries();
    this.generateBranchCode();
  }

  initForm(): void {
    this.branchForm = new FormGroup({
      branchName: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      zipCode: new FormControl('', [Validators.required, numericValidator()]),
      phoneNumber: new FormControl('', [Validators.required, uaePhoneNumberValidator()]),
      email: new FormControl('', [Validators.required, Validators.email]),
      branchCode: new FormControl('BR' + Date.now(), Validators.required),
    });
  }

  loadCountries(): void {
    this.http.get<any[]>('assets/countries.json').subscribe(
      (data) => {
        this.countries = data;
      },
      (error) => {
        console.log('Error loading country data----', error);
      }
    );
  }

  generateBranchCode(): void {
    const randomSuffix = Math.floor(1000 + Math.random() * 9000);
    const branchCode = `BR${randomSuffix}`;
    this.branchForm.patchValue({ branchCode });
  }

  onAddBranch(): void {
    if (this.branchForm.valid) {
      const branchData = { ...this.branchForm.value, isHeadOffice: 0 };

      this.brancService.createBranch(branchData).subscribe(
        (response) => {
          this.toastr.success('Branch added successfully!', 'Success', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true,
          });
          this.dialogRef.close('success');
        },
        (error) => {
          let errorMessage = 'Failed to add branch. Please try again.';
          if (error.error && error.error.message) {
            errorMessage = error.error.message;
          } else if (error.message) {
            errorMessage = error.message;
          }

          this.toastr.error(errorMessage, 'Error', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true,
          });
        }
      );
    } else {
      this.toastr.warning('Please fill all the required fields!', 'Warning', {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
      });
    }
  }
}
