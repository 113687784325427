import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabChangeEvent, MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { LeaveHistoryComponent } from './components/leave-history/leave-history.component';
import { LeaveApplyComponent } from './components/leave-apply/leave-apply.component';

@Component({
  selector: 'app-leaves',
  standalone: true,
  imports: [
    CommonModule,
    LeaveHistoryComponent,
    LeaveApplyComponent,
    MatTabGroup,
    MatTabsModule
  ],
  templateUrl: './leaves.component.html',
  styleUrl: '../../employee-details.component.scss',
})
export class LeavesComponent {
  @ViewChild('leaveHistory') leaveHistoryComponent: any;
  public themeClass: string = 'ag-theme-quartz';
  public paginationPageSize = 25;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  activeTabIndex = 0; 

  constructor( ) { }

  ngOnInit() {
  }

  onTabChange(event: MatTabChangeEvent) {
    if (event.index === 0) {
        if (this.leaveHistoryComponent) {
          this.leaveHistoryComponent.fetchLeaves();
        }
    }
  }

}
