<div class="mat-elevation-z8">
  <app-search (searchChange)="onSearchChange($event)"></app-search>
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="assetName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset Name</th>
      <td mat-cell *matCellDef="let element">{{ element.assetName }}</td>
    </ng-container>

    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
      <td mat-cell *matCellDef="let element">{{ element.category }}</td>
    </ng-container>

    <ng-container matColumnDef="branch">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Branch</th>
      <td mat-cell *matCellDef="let element">{{ element.branch }}</td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let element">{{ element.type }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.status }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSize]="10"></mat-paginator>
</div>
