<mat-dialog-content class="mat-typography">
  <section class="add-branch-dialog-wrapper">
    <h2 class="cmn-popup-title">New Branch</h2>
    <button class="close" mat-dialog-close></button>
    <form [formGroup]="branchForm" (ngSubmit)="onAddBranch()">
      <div class="branch-form-wrapper">
        <div class="form-wrapper">
          <label for="">Branch Name</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter Name"
              formControlName="branchName"
            />
            <mat-error
              *ngIf="branchForm.get('branchName')!.touched && branchForm.get('branchName')!.hasError('required')"
              >*Branch Name is required.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Address </label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter Address"
              formControlName="address"
            />
            <mat-error
              *ngIf="branchForm.get('address')!.touched && branchForm.get('address')!.hasError('required')"
              >*Address is required.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">City</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter City Name"
              formControlName="city"
            />
            <mat-error
              *ngIf="branchForm.get('city')!.touched && branchForm.get('city')!.hasError('required')"
              >City is required.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">State</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter State Name"
              formControlName="state"
            />
            <mat-error
              *ngIf="branchForm.get('state')!.touched && branchForm.get('state')!.hasError('required')"
              >*State is required.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Country</label>
          <mat-form-field>
            <mat-select placeholder="Select country" formControlName="country">
              @for (country of countries; track country) {
              <mat-option [value]="country.name">{{country.name}}</mat-option>
              }
              <!-- <mat-option value="option1">Option 1</mat-option>
              <mat-option value="option2">Option 2</mat-option>
              <mat-option value="option3">Option 3</mat-option> -->
            </mat-select>
            <mat-error
              *ngIf="branchForm.get('country')!.touched && branchForm.get('country')!.hasError('required')"
              >*Country is required.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Zip Code</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter Zip Code"
              formControlName="zipCode"
            />
            <mat-error
              *ngIf="branchForm.get('zipCode')!.touched && branchForm.get('zipCode')!.hasError('required')"
              >*Zip Code is required.</mat-error
            >
            <mat-error
              *ngIf="branchForm.get('zipCode')!.touched && branchForm.get('zipCode')!.hasError('invalidZipCode')"
              >*Zip Code must be numeric.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Phone Number</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter Number"
              formControlName="phoneNumber"
            />
            <mat-error
              *ngIf="branchForm.get('phoneNumber')!.touched && branchForm.get('phoneNumber')!.hasError('required')"
              >*Phone Number is required.</mat-error
            >
            <mat-error
              *ngIf="branchForm.get('phoneNumber')!.touched && branchForm.get('phoneNumber')!.hasError('invalidPhoneNumber')"
              >*Invalid format.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Email Address</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter email id"
              formControlName="email"
            />
            <mat-error
              *ngIf="branchForm.get('email')!.touched && branchForm.get('email')!.hasError('required')"
              >*Email Address is required.</mat-error
            >
            <mat-error
              *ngIf="branchForm.get('email')!.touched && branchForm.get('email')!.hasError('email')"
              >*Invalid email format.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Branch Code</label>
          <mat-form-field>
            <input matInput formControlName="branchCode" readonly />
            <mat-error
              *ngIf="branchForm.get('branchCode')!.touched && branchForm.get('branchCode')!.hasError('required')"
              >*Branch Code is required.</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="btn-wrapper">
        <button type="submit" class="cmn-popup-btn">Add Branch</button>
      </div>
    </form>
  </section>
</mat-dialog-content>
