import { gql } from "apollo-angular";

export const GET_ATTENDANCE = gql`
  query attendances($search: String, $filter: AttendanceFilterInput) {
    attendances(search: $search, filter: $filter) {
    id
    date
    checkInTime
    checkOutTime
    totalHoursWorked
    overtimeHours
    attendanceStatus
    markedBy
    shift
    notes
    employeeId
    employee {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      departmentId
      reportingManagerId
      branchId
    }
    }
  }
`;

export const GET_ATTENDANCE_WITH_PAGINATION = gql`
  query findAllAttendancesWithPagination($limit: Float!, $offset: Float!, $search: String, $filter: AttendanceFilterInput) {
    findAllAttendancesWithPagination(limit: $limit, offset: $offset, search: $search, filter: $filter){
    attendances {
      id
      date
      checkInTime
      checkOutTime
      totalHoursWorked
      overtimeHours
      attendanceStatus
      markedBy
      shift
      notes
      employeeId
      employee{
        firstName
        lastName
        middleName
        employeeStatus
        employeeType
        designation{
          designationName
        }
      }
    }
    total
    limit
    offset
  }
  }
`;

export const GET_ATTENDANCE_BY_ID = gql`
  query attendance($id: Int!) {
    attendance(id: $id) {
    id
    date
    checkInTime
    checkOutTime
    totalHoursWorked
    overtimeHours
    attendanceStatus
    markedBy
    shift
    notes
    employeeId
    employee {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      employeeApprovers{
        employee{
          id
          firstName
          lastName
        }
      }
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      departmentId
    }
  }
  }
`;