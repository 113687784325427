import { CurrencyAdjustmentService } from './../../features/currency-adjustments/services/currency-adjustment.service';
import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-currency-adjustments',
  standalone: true,
  imports: [CommonModule, LayoutComponent, AgGridAngular],
  templateUrl: './currency-adjustments.component.html',
  styleUrl: './currency-adjustments.component.scss',
})
export class CurrencyAdjustmentsComponent implements OnInit {
  constructor(
    private router: Router,
    private currencyService: CurrencyAdjustmentService,
    private toastr: ToastrService
  ) {}

  isDropdownOpen = false;
  public rowData: any[] | null = null;
  public loading: boolean = true;
  public error: any = null;
  private searchSubject = new Subject<string>();

  readonly dialog = inject(MatDialog);

  public columnDefs = [
    {
      headerName: 'Currency Code',
      field: 'currencyCode',
      flex: 1,
    },
    { headerName: 'Currency Name', field: 'currencyName', flex: 1 },
    { headerName: 'Currency Symbol', field: 'currencySymbol', flex: 1 },
    { headerName: 'Exchange Rate', field: 'exchangeRate', flex: 1 },
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  ngOnInit(): void {
    this.fetchCurrencyData();

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchCurrencyData(searchTerm);
      });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  fetchCurrencyData(search: string = '', filter: any = {}) {
    this.loading = true;
    this.currencyService.fetchCurrencies(search, filter).subscribe({
      next: (currencies) => {
        this.rowData = currencies.map((currency: any) => {
          return {
            currencyCode: currency?.currencyCode,
            currencyName: currency?.currencyName,
            currencySymbol: currency?.currencySymbol,
            exchangeRate: currency?.exchangeRate,
          };
        });
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching currencies:', err);
        this.loading = false;
      },
    });
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadAdjustmentsDialog);
  }

  onRowClicked(event: any): void {
    this.dialog.open(CurrencyLogDialog, {
      width: '600px',
      data: event.data,
    });
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }
}

@Component({
  selector: 'upload-adjustments-dialog',
  templateUrl: 'upload-adjustments-dialog.html',
  styleUrls: ['./currency-adjustments.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadAdjustmentsDialog {
  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }
}

@Component({
  selector: 'upload-adjustments-dialog',
  templateUrl: 'currency-logs.component.html',
  styleUrls: ['./currency-adjustments.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, AgGridAngular],
})
export class CurrencyLogDialog {
  constructor(
    private router: Router,
    private currencyService: CurrencyAdjustmentService,
    private toastr: ToastrService
  ) {}

  isDropdownOpen = false;
  public rowData: any[] | null = null;
  public loading: boolean = true;
  public error: any = null;
  private searchSubject = new Subject<string>();

  readonly dialog = inject(MatDialog);

  public columnDefs = [
    {
      headerName: 'Currency Code',
      field: 'currencyCode',
      flex: 1,
    },
    { headerName: 'Currency Name', field: 'currencyName', flex: 1 },
    { headerName: 'Currency Symbol', field: 'currencySymbol', flex: 1 },
    { headerName: 'Exchange Rate', field: 'exchangeRate', flex: 1 },
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  ngOnInit(): void {
    this.fetchCurrencyData();

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchCurrencyData(searchTerm);
      });
  }

  fetchCurrencyData(search: string = '', filter: any = {}) {
    this.loading = true;
    this.currencyService.fetchCurrencies(search, filter).subscribe({
      next: (currencies) => {
        this.rowData = currencies.map((currency: any) => {
          return {
            currencyCode: currency?.currencyCode,
            currencyName: currency?.currencyName,
            currencySymbol: currency?.currencySymbol,
            exchangeRate: currency?.exchangeRate,
          };
        });
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching currencies:', err);
        this.loading = false;
      },
    });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadAdjustmentsDialog);
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }
}
