import { gql } from 'apollo-angular';

export const GET_EMPLOYEES = gql`
  query getEmployees($search: String, $filter: EmployeeFilter) {
    getEmployees(search: $search, filter: $filter) {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      departmentId
      department {
        id
        departmentName
        status
        createdAt
        updatedAt
        deletedAt
      }
      designationId
      designation {
        id
        designationName
        status
        createdAt
        updatedAt
        deletedAt
      }
      reportingManagerId
      reportingManager {
        id
        firstName
        lastName
        middleName
        dateOfBirth
        gender
        email
        phoneNumber
        alternatePhoneNumber
        address
        permanentAddress
        dateOfJoining
        employmentType
        employeeType
        employeeStatus
        profilePicture
        maritalStatus
        nationality
        passportNumber
        visaType
        visaIssueDate
        visaExpiryDate
        salary
        bankName
        bankAccountNumber
        IFSCCode
        departmentId
        designationId
        reportingManagerId
        branchId
      }
      branchId
      branch {
        id
        branchName
        address
        city
        state
        country
        zipCode
        phoneNumber
        branchCode
        isHeadOffice
        email
        createdAt
        updatedAt
        deletedAt
      }
      leaves {
        id
        employeeId
        leaveType
        startDate
        endDate
        totalLeaveDays
        leaveStatus
        employeeComment
        supportingDocuments
        createdAt
        updatedAt
        deletedAt
      }
      vacationRequests {
        id
        employeeId
        requestDate
        startDate
        endDate
        status
        additionalSalary
      }
      employeeDocuments {
        id
        employeeId
        documentType
        documentNumber
        issueDate
        expiryDate
        issuingAuthority
        filePath
        isVerified
        remarks
      }
      user {
        id
        email
        employeeId
        profileId
      }
      employeeSalaries {
        id
        employeeId
        basicSalary
        hra
        allowances
        deductions
        grossSalary
        netSalary
        remarks
      }
      employeeExits {
        id
        employeeId
        exitDate
        exitType
        exitReason
        isNoticePeriod
        isExitInterview
        interviewDate
        interviewNotes
        isAssetReturned
        clearanceStatus
        clearanceDate
        settlementStatus
        settlementAmount
        settlementDate
        exitDocuments
        isAccountsDisabled
        lastWorkingDay
        isManagerApproval
        isHrApproval
        isFinalApproval
        feedbackScore
        isRehireEligibility
      }
      employeePayrolls {
        id
        payrollId
        employeeId
        payPeriodStart
        payPeriodEnd
        basicSalary
        overtimePay
        bonuses
        deductions
        netPay
        paymentDate
        paymentMethod
        payslipURL
      }
    }
  }
`;

export const GET_EMPLOYEE_SALARY_BY_ID = gql`
  query employeeSalary($id: Int!) {
    employeeSalary(id: $id) {
      id
      employeeId
      basicSalary
      hra
      allowances
      deductions
      grossSalary
      netSalary
      remarks
    }
  }
`;

export const GET_EMPLOYEE_EXIT_BY_ID = gql`
  query employeeExit($id: Int!) {
    employeeExit(id: $id) {
      id
      employeeId
      exitDate
      exitType
      exitReason
      isNoticePeriod
      isExitInterview
      interviewDate
      interviewNotes
      isAssetReturned
      clearanceStatus
      clearanceDate
      settlementStatus
      settlementAmount
      settlementDate
      exitDocuments
      isAccountsDisabled
      lastWorkingDay
      isManagerApproval
      isHrApproval
      isFinalApproval
      feedbackScore
      isRehireEligibility
    }
  }
`;

export const GET_EMPLOYEE_BY_ID = gql`
  query getEmployee($id: Int!) {
    getEmployee(id: $id) {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      isPortalUser
      departmentId
      department {
        id
        departmentName
        status
      }
      designationId
      designation {
        id
        designationName
        status
      }
      reportingManagerId
      branchId
      branch {
        id
        branchName
      }
      employeeSalaries {
        id
        employeeId
        basicSalary
        hra
        allowances
        deductions
        grossSalary
        netSalary
        remarks
      }

      user {
        id
        email
        employeeId
        profileId
      }
      employeeExits {
        id
        employeeId
        exitDate
        exitType
        exitReason
        isNoticePeriod
        isExitInterview
        interviewDate
        interviewNotes
        isAssetReturned
        clearanceStatus
        clearanceDate
        settlementStatus
        settlementAmount
        settlementDate
        exitDocuments
        isAccountsDisabled
        lastWorkingDay
        isManagerApproval
        isHrApproval
        isFinalApproval
        feedbackScore
        isRehireEligibility
      }
    }
  }
`;

export const GET_BRANCH_NAMES = gql`
  query branches($search: String) {
    branches(search: $search) {
      id
      branchName
      address
      city
      state
      country
      zipCode
      phoneNumber
      branchCode
      isHeadOffice
      email
    }
  }
`;

export const GET_EMPLOYEE_TYPES = gql`
  query getEmployeeTypes {
    getEmployeeTypes {
      employeeType
    }
  }
`;

export const GET_DESIGNATIONS = gql`
  query designations($search: String, $status: Int) {
    designations(search: $search, status: $status) {
      id
      designationName
      status
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const GET_DEPARTMENTS = gql`
  query departments($search: String, $status: Int) {
    departments(search: $search, status: $status) {
      id
      departmentName
      status
    }
  }
`;

export const GET_USER_ROLES = gql`
  query profiles($search: String) {
    profiles(search: $search) {
      id
      profileName
      description
      rolePermissions {
        id
        profileId
        moduleId
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const GET_USER_ROLE_BY_ID = gql`
  query profile($id: Int!) {
    profile(id: $id) {
      id
      profileName
      description
    }
  }
`;
