<div class="form-container">
  <form [formGroup]="personalInfoForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="firstName">First Name:</label>
      <input id="firstName" formControlName="firstName" class="form-control" />
      <div
        *ngIf="
          personalInfoForm.get('firstName')!.invalid &&
          personalInfoForm.get('firstName')!.touched
        "
        class="error"
      >
        First Name is required.
      </div>
    </div>

    <div class="form-group">
      <label for="lastName">Last Name:</label>
      <input id="lastName" formControlName="lastName" class="form-control" />
      <div
        *ngIf="
          personalInfoForm.get('lastName')!.invalid &&
          personalInfoForm.get('lastName')!.touched
        "
        class="error"
      >
        Last Name is required.
      </div>
    </div>

    <div class="form-group">
      <label for="mobileNumber">Mobile Number:</label>
      <input
        id="mobileNumber"
        formControlName="mobileNumber"
        class="form-control"
      />
      <div
        *ngIf="
          personalInfoForm.get('mobileNumber')!.invalid &&
          personalInfoForm.get('mobileNumber')!.touched
        "
        class="error"
      >
        Invalid Mobile Number.
      </div>
    </div>

    <div class="form-group">
      <label for="emailAddress">Email Address:</label>
      <input
        id="emailAddress"
        formControlName="emailAddress"
        type="email"
        class="form-control"
      />
      <div
        *ngIf="
          personalInfoForm.get('emailAddress')!.invalid &&
          personalInfoForm.get('emailAddress')!.touched
        "
        class="error"
      >
        Invalid Email Address.
      </div>
    </div>

    <div class="form-group">
      <label for="dateOfBirth">Date of Birth:</label>
      <input
        id="dateOfBirth"
        formControlName="dateOfBirth"
        type="date"
        class="form-control"
        [max]="maxDate"
      />
      <div
        *ngIf="
          personalInfoForm.get('dateOfBirth')!.invalid &&
          personalInfoForm.get('dateOfBirth')!.touched
        "
        class="error"
      >
        Date of Birth is required.
      </div>
    </div>

    <div class="form-group">
      <label for="maritalStatus">Marital Status:</label>
      <select
        id="maritalStatus"
        formControlName="maritalStatus"
        class="form-control"
      >
        <option value="" disabled>Select Status</option>
        <option value="single">Single</option>
        <option value="married">Married</option>
        <option value="divorced">Divorced</option>
      </select>
      <div
        *ngIf="
          personalInfoForm.get('maritalStatus')!.invalid &&
          personalInfoForm.get('maritalStatus')!.touched
        "
        class="error"
      >
        Marital Status is required.
      </div>
    </div>

    <div class="form-group">
      <label for="gender">Gender:</label>
      <select id="gender" formControlName="gender" class="form-control">
        <option value="" disabled>Select Gender</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="other">Other</option>
      </select>
      <div
        *ngIf="
          personalInfoForm.get('gender')!.invalid &&
          personalInfoForm.get('gender')!.touched
        "
        class="error"
      >
        Gender is required.
      </div>
    </div>

    <div class="form-group">
      <label for="nationality">Nationality:</label>
      <select
        id="nationality"
        formControlName="nationality"
        class="form-control"
      >
        <option value="" disabled>Select Nationality</option>
        <option
          *ngFor="let nationality of nationalities"
          [value]="nationality.code"
        >
          {{ nationality.name }}
        </option>
      </select>
      <div
        *ngIf="
          personalInfoForm.get('nationality')!.invalid &&
          personalInfoForm.get('nationality')!.touched
        "
        class="error"
      >
        Nationality is required.
      </div>
    </div>

    <div class="form-group">
      <label for="address">Address:</label>
      <input id="address" formControlName="address" class="form-control" />
      <div
        *ngIf="
          personalInfoForm.get('address')!.invalid &&
          personalInfoForm.get('address')!.touched
        "
        class="error"
      >
        Address is required.
      </div>
    </div>

    <div class="form-group">
      <label for="city">City:</label>
      <input id="city" formControlName="city" class="form-control" />
      <div
        *ngIf="
          personalInfoForm.get('city')!.invalid &&
          personalInfoForm.get('city')!.touched
        "
        class="error"
      >
        City is required.
      </div>
    </div>

    <div class="form-group">
      <label for="state">State:</label>
      <input id="state" formControlName="state" class="form-control" />
      <div
        *ngIf="
          personalInfoForm.get('state')!.invalid &&
          personalInfoForm.get('state')!.touched
        "
        class="error"
      >
        State is required.
      </div>
    </div>

    <div class="form-group">
      <label for="zipCode">Zip Code:</label>
      <input id="zipCode" formControlName="zipCode" class="form-control" />
      <div
        *ngIf="
          personalInfoForm.get('zipCode')!.invalid &&
          personalInfoForm.get('zipCode')!.touched
        "
        class="error"
      >
        Invalid Zip Code.
      </div>
    </div>

    <button
      type="submit"
      [disabled]="personalInfoForm.invalid"
      class="btn btn-primary"
    >
      Save
    </button>

    <div *ngIf="errorMessage" class="error">
      {{ errorMessage }}
    </div>
  </form>
</div>
