import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-salary-details',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './salary-details.component.html',
  styleUrl: '../../add-employee.component.scss',
})
export class SalaryDetailsComponent implements OnChanges {
  salaryDetailForm: FormGroup;

  @Input() employee: any;
  @Input() isEditMode: boolean = false;
  @Input() employeeData: any = {};
  @Input() salaryDetails: any;
  @Output() formSubmit = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {
    this.salaryDetailForm = this.fb.group({
      basicSalary: ['', Validators.required],
      hra: ['', Validators.required],
      allowances: ['', Validators.required],
      deductions: ['', Validators.required],
      grossSalary: [{ value: '', disabled: true }, Validators.required],
      netSalary: [{ value: '', disabled: true }, Validators.required],
    });
    this.salaryDetailForm.valueChanges.subscribe(() => {
      this.updateSalaries();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['salaryDetails'] && this.isEditMode && this.employee) {
      this.populateFormWithEmployeeData();
    }
  }

  populateFormWithEmployeeData() {
    if (this.salaryDetails) {
      this.salaryDetailForm.patchValue({
        basicSalary: this.salaryDetails.basicSalary || 0,
        hra: this.salaryDetails.hra || 0,
        allowances: this.salaryDetails.allowances || 0,
        deductions: this.salaryDetails.deductions || 0,
        grossSalary: this.salaryDetails.grossSalary || 0,
        netSalary: this.salaryDetails.netSalary || 0,
        remarks: this.salaryDetails.remarks || '',
      });
    }
  }
  

  // Calculate and update gross and net salary
  updateSalaries(): void {
    const basicSalary = +this.salaryDetailForm.get('basicSalary')?.value || 0;
    const hra = +this.salaryDetailForm.get('hra')?.value || 0;
    const allowances = +this.salaryDetailForm.get('allowances')?.value || 0;
    const deductions = +this.salaryDetailForm.get('deductions')?.value || 0;

    const grossSalary = basicSalary + hra + allowances;
    const netSalary = grossSalary - deductions;

    // Set calculated values to the respective form controls
    this.salaryDetailForm.patchValue(
      {
        grossSalary,
        netSalary,
      },
      { emitEvent: false }
    );
  }

  onNext(): void {
    this.salaryDetailForm.get('grossSalary')?.enable();
    this.salaryDetailForm.get('netSalary')?.enable();

    this.salaryDetailForm.markAllAsTouched();

    if (this.salaryDetailForm.valid) {
      console.log('Salary Info SUbmitted--------', this.salaryDetailForm.value);
      this.formSubmit.emit(this.salaryDetailForm.value);
    } else {
      console.log('Form is Invalid');
    }

    this.salaryDetailForm.get('grossSalary')?.disable();
    this.salaryDetailForm.get('netSalary')?.disable();
  }

  onCancel(): void {
    this.salaryDetailForm.reset();
  }
}
