<app-layout>
    <section class="cmn-innerpage-wrapper payroll-settings">
        <h2>Pay Schedule</h2>
        <div class="toggle-box">
            <h4 class="setting-title">Select your work week</h4>
            <h3>Payroll Inputs</h3>
            <mat-button-toggle-group name="" aria-label="" multiple>
                <mat-button-toggle value="sunday">Sun</mat-button-toggle>
                <mat-button-toggle value="monday">Mon</mat-button-toggle>
                <mat-button-toggle value="tuesday">Tue</mat-button-toggle>
                <mat-button-toggle value="wednessday">Wed</mat-button-toggle>
                <mat-button-toggle value="thursday">Thu</mat-button-toggle>
                <mat-button-toggle value="friday">Fri</mat-button-toggle>
                <mat-button-toggle value="saturday">Sat</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="radio-parent-wrapper">
            <h4 class="setting-title">Calculate monthly salary base on</h4>
            <div class="radio-box">
                <label class="radiobtn-wrapper">
                    <input type="radio" name="exportFormat" value="pdf" />
                    <span class="radiomark"></span>
                    Actual days in amonth
                </label>
            </div>
            <div class="radio-box">
                <label class="radiobtn-wrapper">
                    <input type="radio" name="exportFormat" value="pdf" />
                    <span class="radiomark"></span>
                    Organisation working days
                </label>
                <input type="text">
                <h6>days per month</h6>
            </div>
        </div>
        <div class="radio-parent-wrapper">
            <h4 class="setting-title">Pay on</h4>
            <div class="radio-box">
                <label class="radiobtn-wrapper">
                    <input type="radio" name="payon" value="pdf" />
                    <span class="radiomark"></span>
                    The last working day of every month
                </label>
            </div>
            <div class="radio-box">
                <label class="radiobtn-wrapper">
                    <input type="radio" name="payon" value="pdf" />
                    <span class="radiomark"></span>
                    Day
                </label>
                <input type="text">
                <h6>of every month</h6>
            </div>
            <h5>Note : <span>When payday falls on a non-working day or a holiday, employees will get paid on the
                    previous working day.</span></h5>
        </div>
        <div class="form-wrapper">
            <label for="">Start your first payroll from</label>
            <mat-form-field>
                <mat-select placeholder="Select a start month">
                    <mat-option value="Single">Single</mat-option>
                    <mat-option value="Married">Married</mat-option>
                    <mat-option value="Divorced">Divorced</mat-option>
                    <mat-option value="Widowed">Widowed</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="submit-btn-wrapper">
            <button class="cmn-cancel-btn">Cancel</button>
            <button class="cmn-next-btn" type="submit">Save</button>
        </div>
    </section>
</app-layout>