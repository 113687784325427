import { EmployeeService } from './../../../../features/employees/services/employee.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-professional-info',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './professional-info.component.html',
  styleUrl: '../../add-employee.component.scss',
})
export class ProfessionalInfoComponent implements OnInit, OnChanges {
  professionalInfoForm: FormGroup;
  public designationData: any[] = [];
  public departmentData: any[] = [];
  public employeeTypeData: any[] = [];
  public employmentTypeData: any[] = [];
  public employeeStatusData: any[] = [];
  today: Date = new Date();

  // This filter disables all dates after today
  dateFilter = (d: Date | null): boolean => {
    const currentDate = d || new Date();
    return currentDate <= this.today;
  };
  
  @Input() employee: any;
  @Input() isEditMode: boolean = false;
  @Input() employeeData: any = {};
  @Output() formSubmit = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private employeeService: EmployeeService
  ) {
    this.professionalInfoForm = this.fb.group({
      // employeeId: ['', Validators.required],
      employeeType: ['', Validators.required],
      employmentType: ['', Validators.required],
      employeeStatus: ['', Validators.required],
      department: [
        '',
        [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)],
      ],
      designation: ['', Validators.required],
      joiningDate: ['', Validators.required],
      currentCTC: ['', Validators.required],
      // officeLocation: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.loadDesignationData();
    this.loadDepartmentData();
    this.loadEmployeeTypeData();
    this.loadEmploymentTypeData();
    this.loadEmployeeStatusData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['employee'] && this.isEditMode && this.employee) {
      this.populateFormWithEmployeeData();
    }
  }

  populateFormWithEmployeeData(): void {
    this.professionalInfoForm.patchValue({
      employeeType: this.employee.employeeType,
      employmentType: this.employee.employmentType,
      employeeStatus: this.employee.employeeStatus,
      department: this.employee.department?.id,
      designation: this.employee.designation?.id,
      joiningDate: this.employee.dateOfJoining
      ? new Date(Number(this.employee.dateOfJoining))
      : null,
      currentCTC: this.employee.salary,
    })
  }

  private loadDesignationData() {
    this.employeeService.getDesignations().subscribe({
      next: (designations) => {
        this.designationData = designations;
      },
      error: (error) => console.error(error),
    });
  }

  private loadDepartmentData() {
    this.employeeService.getDepartments().subscribe({
      next: (dept) => {
        this.departmentData = dept;
      },
      error: (error) => console.error(error),
    });
  }

  private loadEmployeeTypeData() {
    this.employeeService.getEmployeeTypes().subscribe({
      next: (employeeTypes) => {
        this.employeeTypeData = employeeTypes;
      },
      error: (error) => console.error(error),
    });
  }

  private loadEmploymentTypeData() {
    this.employeeService.getEmploymentTypes().subscribe({
      next: (employmentType) => {
        this.employmentTypeData = employmentType;
      },
      error: (error) => console.error(error),
    });
  }

  private loadEmployeeStatusData() {
    this.employeeService.getEmployeeStatus().subscribe({
      next: (empStatus) => {
        this.employeeStatusData = empStatus;
      },
      error: (error) => console.error(error),
    });
  }

  onNext(): void {
    if (this.professionalInfoForm.invalid) {
      this.professionalInfoForm.markAllAsTouched();
      console.log('Form is Invalid');
    } else {
      console.log('professional Info SUbmitted-----', this.professionalInfoForm.value);
      this.formSubmit.emit(this.professionalInfoForm.value);
    }
  }

  onCancel(): void {
    this.professionalInfoForm.reset();
  }
}
