import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import { APOLLO_NAMED_OPTIONS, ApolloModule } from 'apollo-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  provideHttpClient,
  withInterceptors,
  HttpClientModule,
} from '@angular/common/http';
import { MatNativeDateModule } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { routes } from './app.routes';
import { authInterceptorFn } from './core/interceptors/auth.interceptor-fn';
import { HttpLink } from 'apollo-angular/http';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { apolloOptions } from './core/utils/apollo-option';

export const appConfig: ApplicationConfig = {
  
  providers: [
    provideRouter(routes),
    provideToastr(),
    importProvidersFrom(
      BrowserAnimationsModule,
      ApolloModule,
      HttpClientModule,
      NgxPermissionsModule.forRoot(),
      MatNativeDateModule
    ),
    provideHttpClient(withInterceptors([authInterceptorFn])),
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: apolloOptions,
      deps: [HttpLink],
    },
    DatePipe,
    AuthInterceptor,
  ],
};