import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { GET_MY_ATTENDANCES } from '../querry/attendanceQuery';

@Injectable({
  providedIn: 'root',
})
export class AttendanceDataService {
  constructor(private apollo: Apollo) {}

  fetchAttendances(filter: any = {}): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_MY_ATTENDANCES,
        variables: { filter },
      })
      .valueChanges.pipe(map((result: any) => result.data.getMyAttendances));
  }
}
