import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    HeaderComponent,
    SidebarComponent,
    CommonModule
  ],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  dataFromLayout = false;
  isCollapsed = false;

  @ViewChild(SidebarComponent) sidebarComponent: SidebarComponent;

  ngOnInit(): void {
    this.checkWindowSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkWindowSize();
  }

  toggleSidebar() {
    this.isCollapsed = !this.isCollapsed;
    this.dataFromLayout = !this.dataFromLayout;
    // Close all expansion panels when toggling the sidebar
    this.sidebarComponent.toggleExpansionPanel();
  }

  checkWindowSize(): void {
    const width = window.innerWidth;
    this.dataFromLayout = width > 768 && width < 1100;
    this.isCollapsed = width > 768 && width < 1100;
  }

  triggerSidebarOnce() {
    if (this.isCollapsed) { // Check if sidebar is collapsed
      this.isCollapsed = false;
      this.dataFromLayout = false;
    }
  }
}
