<div class="mat-elevation-z8">
  <app-search (searchChange)="onSearchChange($event)"></app-search>

  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="projectName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Project Name</th>
      <td mat-cell *matCellDef="let element">{{ element.projectName }}</td>
    </ng-container>

    <ng-container matColumnDef="startDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
      <td mat-cell *matCellDef="let element">{{ element.startDate }}</td>
    </ng-container>

    <ng-container matColumnDef="finishDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Finish date</th>
      <td mat-cell *matCellDef="let element">{{ element.finishDate }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.status }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSize]="10"></mat-paginator>
</div>
