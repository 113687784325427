import { Component, EventEmitter, Inject, OnInit, Output, inject, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { BranchService } from '../../features/branch/services/branch.service';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef, } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormControl, FormGroup, ReactiveFormsModule, Validators, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { MatOptionModule } from '@angular/material/core';
import { customEmailValidator, phoneNumberValidator, } from '../../shared/services/validations';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-branch',
  standalone: true,
  imports: [LayoutComponent, AgGridAngular, MatButtonModule, MatDialogModule],
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss'],
})
export class BranchComponent {
  public rowData: any[] | null = null;
  public columnDefs: ColDef[] = [
    { field: 'branchName', headerName: 'Branch Name', flex: 1 },
    { field: 'location', headerName: 'Location', flex: 1 },
    {
      field: 'branchCode',
      headerName: 'Branch Code',
      filter: 'agNumberColumnFilter',
      flex: 1,
    },
    {
      field: 'employeeCount',
      headerName: 'Total Employees',
      filter: 'agNumberColumnFilter',
      flex: 1,
    },
    {
      field: 'projectInProgress',
      headerName: 'Projects In Progress',
      filter: 'agNumberColumnFilter',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      cellRenderer: (params: any) => {
        const div = document.createElement('div');
        div.style.display = 'flex';
        div.style.alignItems = 'center';
        div.style.gap = '20px';

        const createButton = (iconSrc: string, onClick: () => void) => {
          const span = document.createElement('span');
          span.style.cursor = 'pointer';
          span.style.display = 'flex';
          span.innerHTML = `<img src="${iconSrc}"/>`;
          span.addEventListener('click', onClick);
          return span;
        };
        div.appendChild(
          createButton('assets/images/icons/icon-edit.svg', () =>
            this.openEditDialog(params?.data)
          )
        );
        div.appendChild(
          createButton('assets/images/icons/icon-delete.svg', () =>
            this.onDeleteBranch(params.data.id)
          )
        );

        return div;
      },
    },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';
  public loading: boolean = true;
  public error: any = null;

  private searchSubject = new Subject<string>();

  constructor(
    private branchService: BranchService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.fetchBranchData();

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchBranchData(searchTerm);
      });
  }

  onDeleteBranch(branchId: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteBranch(branchId);
      }
    });
  }

  private deleteBranch(branchId: number): void {
    this.branchService.removeBranchById(branchId).subscribe({
      next: () => {
        this.toastr.success('Branch deleted successfully!', 'Success');
        this.fetchBranchData();
      },
      error: () => {
        this.toastr.error(
          'Failed to delete employee. Please try again.',
          'Error'
        );
      },
    });
  }

  private fetchBranchData(search: string = '') {
    this.loading = true;
    this.branchService.fetchBranches(search).subscribe({
      next: (branches) => {
        let inProgressCount = 0;

        // Sort the branches by 'id' in descending order so the newest branch comes first
        this.rowData = branches
          .map((branch: any) => {
            const projectsInProgress = Array.isArray(branch?.projects)
              ? branch.projects.filter(
                (project: any) => project.status === 'In-Progress'
              )
              : [];

            inProgressCount += projectsInProgress.length;

            return {
              id: branch?.id || 'N/A',
              branchName: branch?.branchName || 'N/A',
              location: branch?.city || 'N/A',
              employeeCount: Array.isArray(branch?.employee)
                ? branch.employee.length
                : 0,
              branchCode: branch?.branchCode || '--',
              projectInProgress: projectsInProgress.length,
              address: branch?.address,
              city: branch?.city,
              state: branch?.state,
              country: branch?.country,
              email: branch?.email,
              zipCode: branch?.zipCode,
              phoneNumber: branch?.phoneNumber,
            };
          })
          .sort((a: any, b: any) => b.id - a.id); // Sort by ID in descending order

        this.loading = false;
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
      },
    });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  readonly dialog = inject(MatDialog);

  openConfirm() {
    const dialogRef = this.dialog.open(ConfirmDialog);
  }

  openDialog() {
    const dialogRef = this.dialog.open(AddBranchDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.fetchBranchData();
      }
    });
  }

  openEditDialog(branchId: any) {
    const dialogRef = this.dialog.open(AddBranchDialog, {
      disableClose: true,
      data: branchId,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.fetchBranchData();
      }
    });
  }
}

const gridDiv = document.querySelector<HTMLElement>('#myGrid')!;

@Component({
  selector: 'add-branch-dialog',
  templateUrl: './add-branch-dialog.html',
  styleUrls: ['./branch.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    CommonModule,
  ],
})
export class AddBranchDialog implements OnInit {
  @Output() branchAdded = new EventEmitter<any>();
  branchForm!: FormGroup;
  countries: any[] = [];
  branchData: any;

  constructor(
    private branchService: BranchService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<AddBranchDialog>,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.branchData = this.data;
    }
    this.initForm();
    this.loadCountries();
    this.generateBranchCode();
  }

  initForm(): void {
    if (!this.branchData) {
      this.branchForm = new FormGroup({
        branchName: new FormControl('', Validators.required),
        address: new FormControl('', Validators.required),
        city: new FormControl('', Validators.required),
        state: new FormControl('', Validators.required),
        country: new FormControl('', Validators.required),
        zipCode: new FormControl('', [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(9),
        ]),
        phoneNumber: new FormControl('', [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(15),
          phoneNumberValidator(),
        ]),
        email: new FormControl('', [
          Validators.required,
          customEmailValidator(),
        ]),
        branchCode: new FormControl('BR' + Date.now(), Validators.required),
      });
    } else {
      this.branchForm = new FormGroup({
        id: new FormControl(this.branchData?.id || '', Validators.required),
        branchName: new FormControl(
          this.branchData?.branchName || '',
          Validators.required
        ),
        address: new FormControl(
          this.branchData?.address || '',
          Validators.required
        ),
        city: new FormControl(this.branchData?.city || '', Validators.required),
        state: new FormControl(
          this.branchData?.state || '',
          Validators.required
        ),
        country: new FormControl(
          this.branchData?.country || '',
          Validators.required
        ),
        zipCode: new FormControl(
          this.branchData?.zipCode || '',
          Validators.required
        ),
        phoneNumber: new FormControl(
          this.branchData?.phoneNumber || '',
          Validators.required
        ),
        email: new FormControl(this.branchData?.email || '', [
          Validators.required,
          customEmailValidator(),
        ]),
        branchCode: new FormControl(
          this.branchData?.branchCode || '',
          Validators.required
        ),
      });
    }
  }

  loadCountries(): void {
    this.http.get<any[]>('assets/json/countries.json').subscribe(
      (data) => {
        this.countries = data;
      },
      (error) => {
        console.log('Error loading country data----', error);
      }
    );
  }

  generateBranchCode(): void {
    const randomSuffix = Math.floor(1000 + Math.random() * 9000);
    const branchCode = `BR${randomSuffix}`;
    this.branchForm.patchValue({ branchCode });
  }

  onAddBranch(): void {
    if (!this.branchData) {
      this.branchForm.markAllAsTouched();
      if (this.branchForm.valid) {
        const branchData = { ...this.branchForm.value, isHeadOffice: 0 };

        this.branchService.createBranch(branchData).subscribe(
          (response) => {
            this.toastr.success('Branch added successfully!', 'Success', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true,
            });
            this.dialogRef.close('success');
          },
          (error) => {
            let errorMessage = 'Failed to add branch. Please try again.';
            if (error.error && error.error.message) {
              errorMessage = error.error.message;
            } else if (error.message === 'Branch is not created!') {
              errorMessage = 'Branch already exist with this email!';
            } else if (error.message) {
              errorMessage = error.message;
            }

            this.toastr.error(errorMessage, 'Error', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true,
            });
          }
        );
      }
    } else {
      this.branchForm.markAllAsTouched();
      if (this.branchForm.valid) {
        const branchData = { ...this.branchForm.value, isHeadOffice: 0 };
        this.branchService.updateBranch(branchData).subscribe(
          (response) => {
            this.toastr.success('Branch updated successfully!', 'Success', {
              timeOut: 5000,
              progressBar: true,
              closeButton: true,
            });
            this.dialogRef.close('success');
          },
          (error) => {
            let errorMessage = 'Failed to update branch. Please try again.';
            if (error.error && error.error.message) {
              errorMessage = error.error.message;
            } else if (error.message) {
              errorMessage = error.message;
            }

            this.toastr.error(
              'Failed to update branch. Please try again.',
              'Error',
              {
                timeOut: 5000,
                progressBar: true,
                closeButton: true,
              }
            );
          }
        );
      }
    }
  }
}
