import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatTabChangeEvent, MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { PendingComponent } from './components/pending/pending.component';
import { ApprovedComponent } from './components/approved/approved.component';
import { RejectedComponent } from './components/rejected/rejected.component';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-leave-management',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatTabGroup,
    MatTabsModule,
    PendingComponent,
    ApprovedComponent,
    RejectedComponent,
    ReactiveFormsModule
  ],
  templateUrl: './leave-management.component.html',
  styleUrl: './leave-management.component.scss'
})
export class LeaveManagementComponent {
  @ViewChild('approvedLeave') ApprovedComponent: ApprovedComponent;
  @ViewChild('rejectedLeave') RejectedComponent: RejectedComponent;
  searchQuery: string = '';
  isDropdownOpen = false;
  filter: any;
  filterForm: FormGroup;
  activeTabIndex = 0; 
  constructor(
    private fb: FormBuilder
  ){
    this.filterForm = this.fb.group({
      CL: [false], 
      EL: [false], 
      EML: [false], 
      ML: [false],  
      LOP: [false]  
    });
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onSearch(event: any) {
    this.searchQuery = event.target.value;
  }

  onSubmit() {
    const selectedLeaveTypes = [];
    const formValues = this.filterForm.value;
    for (const leaveType in formValues) {
      if (formValues[leaveType]) {
        selectedLeaveTypes.push(leaveType);
      }
    }
    this.filter = selectedLeaveTypes;
    this.isDropdownOpen = false;
  }

  onTabChange(event: MatTabChangeEvent) {
    console.log(event.index);
    if (event.index === 0) {
      this.activeTabIndex = 0;
    } else if (event.index === 1) {
      this.activeTabIndex = 1;
        if (this.ApprovedComponent) {
          this.ApprovedComponent.fetchApprovedLeaves();
        }
    } else if (event.index === 2){
      this.activeTabIndex = 2;
      if (this.RejectedComponent) {
        this.RejectedComponent.fetchRejectedLeaves();
      }
    }
  }
}
