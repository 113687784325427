<div class="tab-content-wrapper">
    <div class="employee-form-wrapper">
        <div class="form-wrapper">
            <label for="">Employee ID</label>
            <mat-form-field>
                <input matInput placeholder="Enter Employee ID" formControlName="branchName" />
            </mat-form-field>
        </div>
        <div class="form-wrapper">
            <label for="">Designation</label>
            <mat-form-field>
                <mat-select placeholder="Select Designation" formControlName="country">
                    <mat-option value="option1">Option 1</mat-option>
                    <mat-option value="option2">Option 2</mat-option>
                    <mat-option value="option3">Option 3</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="form-wrapper">
            <label for="">Select Employee Type</label>
            <mat-form-field>
                <mat-select placeholder="Select Employee Type" formControlName="country">
                    <mat-option value="option1">Option 1</mat-option>
                    <mat-option value="option2">Option 2</mat-option>
                    <mat-option value="option3">Option 3</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="form-wrapper">
            <label for="">Select Joining Date</label>
            <mat-form-field>
                <input matInput [matDatepicker]="picker2">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="form-wrapper">
            <label for="">Current CTC</label>
            <mat-form-field>
                <input matInput placeholder="Enter Current CTC" formControlName="branchName" />
            </mat-form-field>
        </div>
        <div class="form-wrapper">
            <label for="">Select Office Location</label>
            <mat-form-field>
                <input matInput [matDatepicker]="picker3">
                <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="submit-btn-wrapper">
        <button class="cmn-cancel-btn">Cancel</button>
        <button class="cmn-next-btn">Next</button>
    </div>
</div>