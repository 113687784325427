<app-layout
  ><app-punchInOut></app-punchInOut>
  <div class="dashboard-container">
    <div class="sidebar">
      <button
        (click)="selectTab('profile')"
        [ngClass]="{ active: activeTab === 'profile' }"
      >
        Profile
      </button>
      <button
        (click)="selectTab('attendance')"
        [ngClass]="{ active: activeTab === 'attendance' }"
      >
        Attendance
      </button>
      <button
        (click)="selectTab('projects')"
        [ngClass]="{ active: activeTab === 'projects' }"
      >
        Projects
      </button>
      <button
        (click)="selectTab('leaves')"
        [ngClass]="{ active: activeTab === 'leaves' }"
      >
        Leaves
      </button>
      <button
        (click)="selectTab('assets')"
        [ngClass]="{ active: activeTab === 'assets' }"
      >
        Assets
      </button>
    </div>

    <div class="content">
      <ng-container *ngIf="activeTab === 'profile'">
        <app-profile-settings></app-profile-settings>
      </ng-container>
      <ng-container *ngIf="activeTab === 'attendance'">
        <app-attendance></app-attendance>
      </ng-container>
      <ng-container *ngIf="activeTab === 'projects'">
        <app-projects></app-projects>
      </ng-container>
      <ng-container *ngIf="activeTab === 'leaves'">
        <app-leaves></app-leaves>
      </ng-container>
      <ng-container *ngIf="activeTab === 'assets'">
        <app-assets></app-assets>
      </ng-container>
    </div>
  </div>
</app-layout>
