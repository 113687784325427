import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, catchError, tap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loginApiUrl = `${environment.authUrl}/auth/login`;
  private isAuthenticated = new BehaviorSubject<boolean>(false);
  private token: string | null = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {}

  login(email: string, password: string): Observable<any> {
    return this.http.post(this.loginApiUrl, { email, password }).pipe(
      tap((response: any) => {
        if (response.access_token) {
          localStorage.setItem('AUTH_TOKEN', response.access_token);
          this.isAuthenticated.next(true);
        }
      }),
      catchError((error) => {
        let errorMessage = 'An unknown error occurred';
        if (error.error && error.error.message) {
          errorMessage = error.error.message;
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }

  logout() {
    localStorage.removeItem('AUTH_TOKEN');
    sessionStorage.removeItem('AUTH_TOKEN');
    this.toastr.success('You have been logged out', 'Session Ended');
    this.isAuthenticated.next(false);
    this.router.navigate(['/login']);
    window.location.reload();
  }

  isLoggedIn(): Observable<boolean> {
    return this.isAuthenticated.asObservable();
  }

  setToken(token: string) {
    this.token = token;
  }

  getToken(): string | null {
    return this.token;
  }

  handleInvalidToken() {
    this.logout();
    this.toastr.error(
      'Your session has expired. Please log in again.',
      'Invalid Token'
    );
  }
}
