<section class="sidebar-wrapper" [ngClass]="{'collapsedClass': sidebarData}">
    <div class="header">
        <img src="assets/images/logo.svg" alt="Logo">
    </div>
    <div class="nav-items-wrapper">
        <ul class="main-nav-list">
            <li class="nav" [ngClass]="{'active-class': isRouteActive('/dashboard')}"
                (click)="navigateTo('/dashboard')">
                <img src="assets/images/icons/icon-dashboard-2.svg" alt="icon Dashboard">
                <h3>Dashboard</h3>
            </li>
            <li class="nav">
                <img src="assets/images/icons/icon-inventory-2.svg" alt="icon Inventory">
                <h3>Inventory</h3>
            </li>
            <li class="nav">
                <img src="assets/images/icons/icon-bank-2.svg" alt="icon Bankinh">
                <h3>Banking</h3>
            </li>
            <li class="nav dropdown-nav ">
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header (click)="onPanelClick()">
                            <mat-panel-title> <img src="assets/images/icons/icon-sales.svg" alt="icon Sales">
                                <h3>Sales</h3>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sub-nav">
                            <li>Customers</li>
                            <li>Quotes</li>
                            <li>Project</li>
                            <li>Work Order</li>
                            <li>Invoice</li>
                            <li>Payment Received</li>
                            <li>Credit Note</li>
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>
            </li>
            <li class="nav dropdown-nav">
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header (click)="onPanelClick()">
                            <mat-panel-title> <img src="assets/images/icons/icon-purchase.svg" alt="icon Purchase">
                                <h3>Purchase</h3>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sub-nav">
                            <li>Vendors</li>
                            <li>Expenses</li>
                            <li>Purchase Order</li>
                            <li>Local Purchase Order</li>
                            <li>Bills</li>
                            <li>GRN</li>
                            <li>Payment Made</li>
                            <li>Debit Note</li>
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>
            </li>
            <li class="nav" [ngClass]="{'active-class': isRouteActive('/branch')}" (click)="navigateTo('/branch')">
                <img src="assets/images/icons/icon-branch.svg" alt="icon Branch">
                <h3>Branch</h3>
            </li>
            <li class="nav dropdown-nav" [ngClass]="{'active-class': isRouteActive2(['/employees' , '/add-employee'])}">
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header (click)="onPanelClick()">
                            <mat-panel-title> <img src="assets/images/icons/icon-hrms.svg" alt="icon HRMS">
                                <h3>HRMS</h3>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sub-nav">
                            <li [ngClass]="{'active': isRouteActive('/employees')}" (click)="navigateTo('/employees')">Employees</li>
                            <li>Attendance</li>
                            <li>Leave</li>
                            <li>Payroll</li>
                            <li>Holiday</li>
                            <li>Leave Policy</li>
                            <li>Vacation</li>
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>
            </li>
            <li class="nav">
                <img src="assets/images/icons/icon-approvals.svg" alt="icon Approvals">
                <h3>Approvals</h3>
            </li>
            <li class="nav">
                <img src="assets/images/icons/icon-assets.svg" alt="icon Assets">
                <h3>Assets</h3>
            </li>
            <li class="nav dropdown-nav">
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header (click)="onPanelClick()">
                            <mat-panel-title> <img src="assets/images/icons/icon-accounts.svg" alt="icon Accounts">
                                <h3>Accounts</h3>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sub-nav">
                            <li>Currency Adjustment</li>
                            <li>Manual Journal</li>
                            <li>Chart Of Accounts</li>
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>
            </li>
            <li class="nav">
                <img src="assets/images/icons/icon-report.svg" alt="icon Report">
                <h3>Report</h3>
            </li>
            <li class="nav">
                <img src="assets/images/icons/icon-roles.svg" alt="icon Roles">
                <h3>Roles & Permissions</h3>
            </li>
        </ul>
    </div>
</section>