import { gql } from 'apollo-angular';

export const CREATE_CURRENCY = gql`
  mutation createCurrency($createCurrencyInput: CreateCurrencyInput!) {
    createCurrency(createCurrencyInput: $createCurrencyInput) {
      id
      currencyCode
      currencyName
      currencySymbol
      exchangeRate
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
