<mat-dialog-content>
    <section class="payroll-processing-dialog">
        <div *ngIf= "data">
            <h2 class="cmn-popup-title-big">Processing Payroll</h2>
            <button class="cmn-close" mat-dialog-close></button>
            <div class="processing-progress">
                <h3>Updating Employee Payroll...</h3>
                <h4>68%</h4>
                <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
            </div>
        </div>
        <div *ngIf= "!data">
            <h2 class="cmn-popup-title-big">Payroll proceed Successfully!</h2>
            <button class="cmn-close" mat-dialog-close></button>
            <button class="cmn-popup-btn" mat-dialog-close>Back To Payroll</button>
        </div>
        
    </section>
</mat-dialog-content>