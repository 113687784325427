import { Component, Input, OnInit, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {


  @Input() sidebarData: any;
  @Output() triggerSidebarAction = new EventEmitter<void>();
   // Using ViewChildren to select all expansion panels
   @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;

  constructor(private router: Router) { }

  ngOnInit() {
    console.log('ngOnInit:', this.sidebarData);
  }

  onPanelClick() {
    this.triggerSidebarAction.emit();
  }


  // Toggle all expansion panels
  toggleExpansionPanel() {
    this.expansionPanels.forEach((panel) => {
      if (panel.expanded) {
        panel.close();
      }
    });
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  isRouteActive(route: string): boolean {
    return this.router.isActive(route, true);
  }

  isRouteActive2(routes: string[]): boolean {
    const currentRoute = this.router.url;
    return routes.some(route => currentRoute.startsWith(route));
  }



}
