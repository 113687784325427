import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { EmployeeService } from '../../../../features/employees/services/employee.service';
import {
  bankAccountValidator,
  passportValidator,
} from '../../../../shared/services/validations';

@Component({
  selector: 'app-documents',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './documents.component.html',
  styleUrl: '../../add-employee.component.scss',
})
export class DocumentsComponent implements OnInit, OnChanges {
  documentForm: FormGroup;
  public visaTypeData: any[] = [];
  today: Date = new Date();

  // This filter disables all dates after today
  dateFilter = (d: Date | null): boolean => {
    const currentDate = d || new Date();
    return currentDate <= this.today;
  };

  @Input() employee: any = {};
  @Input() isEditMode: boolean = false;
  @Input() employeeData: any = {};
  @Output() formSubmit = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private employeeService: EmployeeService
  ) {
    this.documentForm = this.fb.group({
      visaType: ['', Validators.required],
      visaIssueDate: [null, Validators.required],
      visaExpiryDate: [null, Validators.required],
      passportNumber: ['', [Validators.required, passportValidator()]],
      bankName: ['', Validators.required],
      accountNumber: ['', [Validators.required, bankAccountValidator()]],
      IFSCCode: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['employee']) {
      if (this.isEditMode && this.employee) {
        this.populateFormWithEmployeeData();
      }
    }
  }

  populateFormWithEmployeeData() {
    if (this.employee) {
      this.documentForm.patchValue({
        visaType: this.employee.visaType || '',
        visaIssueDate: this.employee.visaIssueDate
          ? new Date(Number(this.employee.visaIssueDate))
          : null,
        visaExpiryDate: this.employee.visaExpiryDate
          ? new Date(Number(this.employee.visaExpiryDate))
          : null,
        passportNumber: this.employee.passportNumber || '',
        bankName: this.employee.bankName || '',
        accountNumber: this.employee.bankAccountNumber || '',
        IFSCCode: this.employee.IFSCCode || '',
      });
    } else {
      console.error('Employee is undefined or null');
    }
  }

  ngOnInit(): void {
    this.loadVisaTypeData();
  }

  private loadVisaTypeData() {
    this.employeeService.getVisaTypes().subscribe({
      next: (visaTypes) => {
        this.visaTypeData = visaTypes;
      },
      error: (error) => console.error(error),
    });
  }

  onNext(): void {
    if (this.documentForm.invalid) {
      this.documentForm.markAllAsTouched();
      console.log('Form is Invalid');
    } else {
      console.log('document Info Submitted-----', this.documentForm.value);
      this.formSubmit.emit(this.documentForm.value);
    }
  }

  onCancel(): void {
    this.documentForm.reset();
  }
}
