import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  
  constructor() { }

  calculatePageSizeOptions(total: number): number[] {
    const initialSize = 10;
    const secondSize = 25;
    const step = 2;

    let options: number[] = [initialSize];

    if (secondSize <= total) {
      options.push(secondSize);
    }

    let currentSize = Math.max(initialSize, secondSize);
    while (currentSize < total) {
      currentSize *= step;
      options.push(currentSize);
    }

    if (options.length > 0) {
      const maxOption = options[options.length - 1];
      if (maxOption < total) {
        const nextOption = maxOption * step;
        options.push(nextOption);
      }
    }

    return options;
  }
}
