<app-layout>
  <section class="cmn-innerpage-wrapper add-currency">
    <h2 class="cmn-inner-heading">Update Currency Details</h2>
    <div class="inner-content">
      <form [formGroup]="addCurrencyForm" (ngSubmit)="onSubmit()">
        <div class="currency-adjustments-inputs">
          <div class="form-wrapper">
            <label for="">Code</label>
            <mat-form-field>
              <input
                matInput
                placeholder="USD"
                formControlName="currencyCode"
              />
              <mat-error
                *ngIf="
                  addCurrencyForm.get('currencyCode')!.touched &&
                  addCurrencyForm.get('currencyCode')!.hasError('required')
                "
                >Currency Code is required.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">Currency</label>
            <mat-form-field>
              <input
                matInput
                placeholder="US Dollar"
                formControlName="currencyName"
              />
              <mat-error
                *ngIf="
                  addCurrencyForm.get('currencyName')!.touched &&
                  addCurrencyForm.get('currencyName')!.hasError('required')
                "
                >Currency Name is required.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">Symbol</label>
            <mat-form-field>
              <input
                matInput
                placeholder="$"
                formControlName="currencySymbol"
              />
              <mat-error
                *ngIf="
                  addCurrencyForm.get('currencyCode')!.touched &&
                  addCurrencyForm.get('currencyCode')!.hasError('required')
                "
                >Currency Code is required.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">Exchange rate</label>
            <div class="split-parent">
              <div class="left-set">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="3.67"
                    formControlName="exchangeRate"
                  />
                  <mat-error
                    *ngIf="
                      addCurrencyForm.get('exchangeRate')!.touched &&
                      addCurrencyForm.get('exchangeRate')!.hasError('required')
                    "
                    >Exchange Rate is required.</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="right-set">
                <mat-form-field>
                  <input matInput placeholder="AED" value="AED" readonly />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="submit-btn-wrapper">
          <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
          <button class="cmn-next-btn" type="submit">Continue</button>
        </div>
      </form>
    </div>
  </section>
</app-layout>
