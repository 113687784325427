import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Assets } from '../models/assets.model';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  private projectUrl: string = 'assets/rose-json/assets.json';

  constructor(private http: HttpClient) {}

  getAssetsData(): Observable<Assets[]> {
    return this.http.get<Assets[]>(this.projectUrl);
  }
}
