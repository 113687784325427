import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { PaginationService } from '../../shared/pagination.service';
import { Project } from '../../models/projects.model';
import { ProjectService } from '../../services/projects.service';
import { MatFormField } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchComponent } from '../../../../shared/search/search.component';

@Component({
  selector: 'app-projects',
  standalone: true,
  imports: [
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatFormField,
    MatInputModule,
    ReactiveFormsModule,
    SearchComponent,
    FormsModule
  ],
  templateUrl: './projects.component.html',
  styleUrl: './projects.component.scss',
})
export class ProjectsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  displayedColumns: string[] = [
    'id',
    'projectName',
    'startDate',
    'finishDate',
    'status',
  ];
  dataSource = new MatTableDataSource<Project>();
  constructor(
    private projectService: ProjectService,
    private paginationService: PaginationService
  ) {}

  ngOnInit(): void {
    this.loadProjectsData();
  }
  loadProjectsData(): void {
    this.projectService.getProjectsData().subscribe((data) => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      const totalRecords = data.length;
      this.paginator.pageSizeOptions =
        this.paginationService.calculatePageSizeOptions(totalRecords);
    });
  }
  onSearchChange(filterValue: string) {
    this.dataSource.filter = filterValue;
  }
}
