<div class="form-container">
  <form [formGroup]="salaryInfoForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="annualPay">Annual Pay:</label>
      <input id="annualPay" formControlName="annualPay" class="form-control" />
      <div
        *ngIf="
          salaryInfoForm.get('annualPay')!.invalid &&
          salaryInfoForm.get('annualPay')!.touched
        "
        class="error"
      >
        Annual Pay is required.
      </div>
    </div>

    <div class="form-group">
      <label for="housingAllowance">Housing Allowance:</label>
      <input
        id="housingAllowance"
        formControlName="housingAllowance"
        class="form-control"
      />
      <div
        *ngIf="
          salaryInfoForm.get('housingAllowance')!.invalid &&
          salaryInfoForm.get('housingAllowance')!.touched
        "
        class="error"
      >
        Housing Allowance is required.
      </div>
    </div>

    <div class="form-group">
      <label for="transportAllowance">Transporting Allowance:</label>
      <input
        id="transportAllowance"
        formControlName="transportAllowance"
        class="form-control"
      />
      <div
        *ngIf="
          salaryInfoForm.get('transportAllowance')!.invalid &&
          salaryInfoForm.get('transportAllowance')!.touched
        "
        class="error"
      >
        Transporting Allowance is required.
      </div>
    </div>

    <div class="form-group">
      <label for="medicalInsurance">Medical Insurance:</label>
      <input
        id="medicalInsurance"
        formControlName="medicalInsurance"
        class="form-control"
      />
      <div
        *ngIf="
          salaryInfoForm.get('medicalInsurance')!.invalid &&
          salaryInfoForm.get('medicalInsurance')!.touched
        "
        class="error"
      >
        Medical Insurance is required.
      </div>
    </div>

    <div class="form-group">
      <label for="overtimePay">Overtime Pay:</label>
      <input
        id="overtimePay"
        formControlName="overtimePay"
        class="form-control"
      />
      <div
        *ngIf="
          salaryInfoForm.get('overtimePay')!.invalid &&
          salaryInfoForm.get('overtimePay')!.touched
        "
        class="error"
      >
        Overtime Pay is required.
      </div>
    </div>

    <div class="form-group">
      <label for="allowances">Other Allowances:</label>
      <input
        id="allowances"
        formControlName="allowances"
        class="form-control"
      />
      <div
        *ngIf="
          salaryInfoForm.get('allowances')!.invalid &&
          salaryInfoForm.get('allowances')!.touched
        "
        class="error"
      >
        Other Allowances is required.
      </div>
    </div>

    <button
      type="submit"
      [disabled]="salaryInfoForm.invalid"
      class="btn btn-primary"
    >
      Save
    </button>

    <div *ngIf="errorMessage" class="error">
      {{ errorMessage }}
    </div>
  </form>
</div>
