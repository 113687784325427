<mat-tab-group #tabGroup [(selectedIndex)]="selectedIndex">
  <mat-tab label="Personal Info">
    <app-profile-personal-info
      (formSubmitted)="onFormSubmitted()"
    ></app-profile-personal-info>
  </mat-tab>
  <mat-tab label="Professional Info">
    <app-profile-proffesional-info
      (formSubmitted)="onFormSubmitted()"
    ></app-profile-proffesional-info>
  </mat-tab>
  <mat-tab label="Documents">
    <app-profile-documents
      (formSubmitted)="onFormSubmitted()"
    ></app-profile-documents>
  </mat-tab>
  <mat-tab label="Branch Access">
    <app-profile-branch-access
      (formSubmitted)="onFormSubmitted()"
    ></app-profile-branch-access>
  </mat-tab>
  <mat-tab label="Salary Details">
    <app-profile-salary-details
      (formSubmitted)="onFormSubmitted()"
    ></app-profile-salary-details>
  </mat-tab>
</mat-tab-group>
