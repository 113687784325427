<mat-dialog-content>
  <section class="add-holiday-dialog-wrapper">
    <h2 class="cmn-popup-title">{{holidayData?.id ? 'Edit':'New'}} Holiday</h2>
    <button class="cmn-close" mat-dialog-close></button>
    <div>
      <form [formGroup]="holidayCalendarForm" (ngSubmit)="onSubmit()">
        <div class="holiday-form-wrapper">
          <div class="form-wrapper">
            <label for="">Occasion</label>
            <mat-form-field>
              <input
                matInput
                placeholder="Enter Occasion"
                formControlName="holidayName"
              />
              <mat-error
                *ngIf="
                holidayCalendarForm.get('holidayName')!.touched &&
                holidayCalendarForm.get('holidayName')!.hasError('required')
              "
                >Occasion is required.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">Holiday Date</label>
            <mat-form-field>
              <input
                matInput
                [matDatepicker]="picker10"
                placeholder="Choose Date"
                formControlName="holidayDate"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker10"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker10></mat-datepicker>

              <mat-error
                *ngIf="
                holidayCalendarForm.get('holidayDate')!.touched &&
                holidayCalendarForm.get('holidayDate')!.hasError('required')
              "
                >Holiday date type is required.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">Select Holiday Type</label>
            <mat-form-field>
              <mat-select
                placeholder="Select Holiday Type"
                formControlName="holidayType"
              >
                <mat-option
                  *ngFor="let type of holidayTypeData"
                  [value]="type.value"
                >
                  {{ type.label }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                holidayCalendarForm.get('holidayType')!.touched &&
                holidayCalendarForm.get('holidayType')!.hasError('required')
              "
                >Holiday type is required.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="form-wrapper full-width">
            <label for="">Description</label>
            <mat-form-field>
              <textarea
                matInput
                placeholder="Enter description"
                cdkTextareaAutosize
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="6"
                formControlName="description"
              ></textarea>
              <!-- <mat-error
                *ngIf="
                  holidayCalendarForm.get('description')!.touched &&
                  holidayCalendarForm.get('description')!.hasError('required')
                "
                >*Description is required.</mat-error
              > -->
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label class="checkbox-wrapper">
              <input type="checkbox" formControlName="isMoon" />Is Moon
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div class="btn-wrapper">
          <button type="submit" class="cmn-popup-btn">
            {{holidayData?.id ? 'Update':'Add Holiday'}}
          </button>
        </div>
      </form>
    </div>
  </section>
</mat-dialog-content>
