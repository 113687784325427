import { Component, Inject, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { VacationService } from '../../../../../../features/vacation/services/vacation.service';
import { ImageService } from '../../../../../../shared/services/image.service';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';

@Component({
    selector: 'app-vacation-history',
    standalone: true,
    imports: [
      CommonModule,
      AgGridAngular,
    ],
    templateUrl: './vacation-history.component.html',
    styleUrl: '../../../../employee-details.component.scss'
  })
  export class VacationHistoryComponent {
    public rowData: any[] | null = null;
    filter: any;

    constructor(
      private vacationService: VacationService,
      private imageService: ImageService,
      private toastr: ToastrService,
      private datePipe: DatePipe,
    ){}

    ngOnInit() {
      this.fetchVacations();
    }
    
      columnDefs = [
        { headerName: 'Start Date', field: 'startDate', flex: 1 },
        { headerName: 'End Date', field: 'endDate', flex: 1 },
        { headerName: 'Total Days', field: 'totalDays', flex: 1 },
        { headerName: 'Status', field: 'status', flex: 1,
        cellRenderer: (params: any) => {
          const status = params.data?.status;
          const statusClass =
            status === "Waiting for approval" ? 'yellow' : status === "Approved" ? 'green' : status === "Rejected" ? 'red' : '';
          return `<span class="${statusClass}">${status}</span>`;
        }
         },
      ];

      public defaultColDef: ColDef = {
        filter: 'agTextColumnFilter',
        floatingFilter: true,
      };
      public rowSelection: 'single' | 'multiple' = 'multiple';
      public paginationPageSize = 10;
      public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
      public themeClass: string = 'ag-theme-quartz';


      fetchVacations() {
        this.filter = { search: "" }
        this.vacationService.fetchVacations(this.filter).subscribe({
          next: async (data) => {
            this.rowData = await Promise.all(
              data
              .map( async (data: any) => {
                const approvers = data?.employee?.employeeApprovers?.[0] || null;
                let profilePicture;
                  if (data?.employee?.profilePicture) {
                    try {
                      profilePicture = await this.imageService.getImageAsBase64(
                        data?.employee.profilePicture
                      );
                    } catch (error) {
                      profilePicture = 'assets/images/dummy-profile-image.jpg';
                    }
                  } else {
                    profilePicture = 'assets/images/dummy-profile-image.jpg';
                  }
                return {
                  id: data?.id || 'N/A',
                  employeeId: data?.employeeId || 'N/A',
                  totalDays: data?.totalDays + ' days' || 'N/A',
                  startDate: this.datePipe.transform((data?.startDate), 'dd/MM/YYYY')  || 'N/A',
                  endDate: this.datePipe.transform((data?.endDate), 'dd/MM/YYYY')  || 'N/A',
                  status: data?.status === 'Pending' ? 'Waiting for approval' : (data?.status === 'Rejected' ? data?.status :  data?.vacationStatus)  || 'N/A',
                };
              })
            )
          },
          error: (error: any) => {
            this.toastr.error(error?.message);
          },
        });
      }

      readonly dialog = inject(MatDialog);

      openConfirm() {
        const dialogRef = this.dialog.open(EditVacationDialog);
      }
  }






@Component({
  selector: 'edit-vacation-dialog',
  templateUrl: './edit-vacation-dialog.html',
  styleUrls: ['../../../../employee-details.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatSelectModule
  ],
})
export class EditVacationDialog {
  vacationEditForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private vacationService: VacationService,
    private toastr: ToastrService,
    private dialog: MatDialogRef<EditVacationDialog>,
  ){}

  ngOnInit() {
    this.initForm();
    console.log(this.data);
  }

  initForm() {
    if(this.data){
      this.vacationEditForm = this.fb.group({
        id: [this.data?.vacationId || ''],
        employeeId: [this.data?.employeeId || ''],
        startDate: [this.data.leaveData?.startDate || '', Validators.required],
        endDate: [this.data.leaveData?.endDate || '', Validators.required],
        // firstSession: ['', Validators.required],
        // secondSession: ['', Validators.required],
        // employeeComment: [this.data.leaveData?.reason || ''],
      });
    }
  }

  onEdit(){
    if (this.vacationEditForm.invalid) {
      this.vacationEditForm.markAllAsTouched();
      return;
    }
    const vacationData = this.vacationEditForm.value;
    if (vacationData) {
      this.vacationService.updateVacationRequest(vacationData).subscribe(
        (response) => {
          if (response) {
            this.vacationEditForm.reset();
            this.dialog.close(response);
          } else if (response?.errors) {
            this.toastr.error(response?.errors[0]?.message, 'Error');
          }
        },
        (error) => {
          this.toastr.error(error?.message, 'Error');
        }
      );
    }

  }
}