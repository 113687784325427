<div class="profile-inner-main-wrapper">
  <div class="details-wrapper">
    <h2>Visa Type</h2>
    <h3>{{employeeData?.visaType}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Visa Isuue Date</h2>
    <h3>{{employeeData?.visaIssueDate | date: 'MMMM d, yyyy' }}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Visa Expiry Date</h2>
    <h3>{{employeeData?.visaExpiryDate | date: 'MMMM d, yyyy' }}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Passport Number</h2>
    <h3>{{employeeData?.passportNumber}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Bank Name</h2>
    <h3>{{employeeData?.bankName}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Account Number</h2>
    <h3>{{employeeData?.bankAccountNumber}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>IFSC Code</h2>
    <h3>{{employeeData?.IFSCCode}}</h3>
  </div>
</div>
