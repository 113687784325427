<div class="confirm-container">
  <h1 mat-dialog-title class="dialog-title">Confirmation</h1>
  <div mat-dialog-content class="dialog-content">
    <p>Are you sure?</p>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button mat-button (click)="onCancel()" class="cancel-button">
      Cancel
    </button>
    <button
      mat-button
      color="primary"
      (click)="onConfirm()"
      class="confirm-button"
    >
      Confirm
    </button>
  </div>
</div>
