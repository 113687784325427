<section class="login-wrapper">
  <div class="left-set">
    <div class="img-wrapper">
      <img src="assets/images/login-img.jpg" alt="Login Image" />
    </div>
  </div>
  <div class="right-set">
    <div class="logo-wrapper">
      <img src="assets/images/logo.svg" alt="Logo" />
    </div>
    <div class="form-wrapper">
      <h2 class="cmn-heading-1">Reset Password</h2>
      <div class="form-content">
        <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <mat-form-field>
              <mat-label>Enter new password</mat-label>
              <input
                matInput
                [type]="hide() ? 'password' : 'text'"
                id="newPassword"
                formControlName="newPassword"
              />
              <button
                mat-icon-button
                matSuffix
                (click)="clickEvent($event)"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide()"
                type="button"
              >
                <mat-icon>{{
                  hide() ? "visibility_off" : "visibility"
                }}</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field>
              <mat-label>Confirm password</mat-label>
              <input
                matInput
                [type]="hide() ? 'password' : 'text'"
                id="confirmPassword"
                formControlName="confirmPassword"
              />
            </mat-form-field>
          </div>
          <div class="form-sub-set">
            <h3 class="frgt-btn">
              Back to <span routerLink="/login">Login</span>
            </h3>
          </div>
          <div class="btn-wrapper">
            <button type="submit" [disabled]="loading">Change Password</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
