import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { BranchComponent } from './pages/branch/branch.component';
import { EmployeesComponent } from './pages/employees/employees.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AddEmployeeComponent } from './pages/add-employee/add-employee.component';
import { EmployeeDetailsComponent } from './pages/employee-details/employee-details.component';
import { ProfileManagementComponent } from './rose/features/profile-management/profile-management.component';
import { AttendanceManagementComponent } from './pages/attendance-management/attendance-management.component';
import { HolidayComponent } from './pages/holiday/holiday.component';
import { LeaveManagementComponent } from './pages/leave-management/leave-management.component';
import { LeavePolicyComponent } from './pages/leave-policy/leave-policy.component';
import { LeaveGrantComponent } from './pages/leave-grant/leave-grant.component';
import { PayrollListComponent } from './pages/payroll-list/payroll-list.component';
import { PayrollComponent } from './pages/payroll/payroll.component';
import { PayrollIndividualComponent } from './pages/payroll-individual/payroll-individual.component';
import { LeaveApprovalsComponent } from './pages/leave-approvals/leave-approvals.component';
import { PayrollSettingsComponent } from './pages/payroll-settings/payroll-settings.component';
import { VacationManagementComponent } from './pages/vacation-management/vacation-management.component';
import { VacationApprovalsComponent } from './pages/vacation-approvals/vacation-approvals.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { RolesComponent } from './pages/roles/roles.component';
import { RolesManagementComponent } from './pages/roles-management/roles-management.component';
import { CurrencyAdjustmentsComponent } from './pages/currency-adjustments/currency-adjustments.component';
import { AddCurrencyComponent } from './pages/add-currency/add-currency.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { ChartAccountsComponent } from './pages/chart-accounts/chart-accounts.component';
import { NewChartAccountComponent } from './pages/new-chart-account/new-chart-account.component';
import { ChartAccountsDetailsComponent } from './pages/chart-accounts-details/chart-accounts-details.component';

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  {
    path: 'notifications',
    component: NotificationComponent,
    data: { breadcrumb: 'Notifications' },
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { breadcrumb: 'Dashboard' },
    canActivate: [AuthGuard],
  },
  {
    path: 'branch',
    component: BranchComponent,
    data: { breadcrumb: 'Branches' },
    canActivate: [AuthGuard],
  },
  {
    path: 'add-employee',
    component: AddEmployeeComponent,
    data: { breadcrumb: 'Add Employee' },
    canActivate: [AuthGuard],
  },
  {
    path: 'attendance-management',
    component: AttendanceManagementComponent,
    data: { breadcrumb: 'Attendance' },
    canActivate: [AuthGuard],
  },
  {
    path: 'leave',
    component: LeaveManagementComponent,
    data: { breadcrumb: 'Leaves' },
    canActivate: [AuthGuard],
  },
  {
    path: 'leave-policy',
    component: LeavePolicyComponent,
    data: { breadcrumb: 'Leave Policy' },
    canActivate: [AuthGuard],
  },
  {
    path: 'leave-grant',
    component: LeaveGrantComponent,
    data: { breadcrumb: 'Leave Grant' },
    canActivate: [AuthGuard],
  },
  {
    path: 'payroll-list',
    component: PayrollListComponent,
    data: { breadcrumb: 'Payroll' },
    canActivate: [AuthGuard],
  },
  {
    path: 'payroll',
    component: PayrollComponent,
    data: { breadcrumb: 'Payroll' },
    canActivate: [AuthGuard],
  },
  {
    path: 'payroll-process/:id',
    component: PayrollIndividualComponent,
    data: { breadcrumb: 'Payroll Process' },
    canActivate: [AuthGuard],
  },
  {
    path: 'payroll-settings',
    component: PayrollSettingsComponent,
    data: { breadcrumb: 'Payroll Settings' },
    canActivate: [AuthGuard],
  },
  {
    path: 'leave-approvals/:id',
    component: LeaveApprovalsComponent,
    data: { breadcrumb: 'Leave Approval' },
    canActivate: [AuthGuard],
  },
  // { path: 'employee-details/:id', component: EmployeeDetailsComponent, canActivate: [AuthGuard] },
  // { path: 'edit-employee/:id', component: AddEmployeeComponent },
  {
    path: 'employees',
    component: EmployeesComponent,
    data: { breadcrumb: 'Employees' },
    canActivate: [AuthGuard],
  },
  {
    path: 'employees/detail/:id',
    component: EmployeeDetailsComponent,
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Employee Details' }, 
  },
  {
    path: 'my-profile/:id',
    component: EmployeeDetailsComponent,
    canActivate: [AuthGuard],
    data: { breadcrumb: 'My Profile' },
  },
  {
    path: 'employees/edit/:id',
    component: AddEmployeeComponent,
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Employee Edit' },
  },

  {
    path: 'holiday',
    component: HolidayComponent,
    data: { breadcrumb: 'Holiday' },
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'payroll-individual',
  //   component: PayrollIndividualComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'vacation-management',
    component: VacationManagementComponent,
    data: { breadcrumb: 'Vacation Management' },
    canActivate: [AuthGuard],
  },
  
  

  {
    path: 'vacation-approvals/:id',
    component: VacationApprovalsComponent,
    data: { breadcrumb: 'Vacation Approvals' },
    canActivate: [AuthGuard],
  },

  {
    path: 'roles',
    component: RolesComponent,
    data: { breadcrumb: 'Roles and Permission' },
    canActivate: [AuthGuard],
  },

  {
    path: 'roles-management',
    component: RolesManagementComponent,
    data: { breadcrumb: 'Roles Management' },
    canActivate: [AuthGuard],
  },

  {
    path: 'currency-adjustments',
    component: CurrencyAdjustmentsComponent,
    data: { breadcrumb: 'Currency Adjustments' },
    canActivate: [AuthGuard],
  },

  {
    path: 'currency-adjustments/add-currency',
    component: AddCurrencyComponent,
    data: { breadcrumb: 'Add Adjustment' },
    canActivate: [AuthGuard],
  },

  {
    path: 'currency-adjustments/adjustment/:id',
    component: AddCurrencyComponent,
    data: { breadcrumb: 'Adjustment' },
    canActivate: [AuthGuard],
  },

  {
    path: 'reports',
    component: ReportsComponent,
    data: { breadcrumb: 'Vacation Approvals' },
    canActivate: [AuthGuard],
  },

  {
    path: 'chart-of-accounts',
    component: ChartAccountsComponent,
    data: { breadcrumb: 'Chart Of Accounts' },
    canActivate: [AuthGuard],
  },

  {
    path: 'chart-of-accounts-details',
    component: ChartAccountsDetailsComponent,
    data: { breadcrumb: 'Chart Of Accounts Details' },
    canActivate: [AuthGuard],
  },

  {
    path: 'new-chart-of-accounts',
    component: NewChartAccountComponent,
    data: { breadcrumb: 'New Chart Of Accounts' },
    canActivate: [AuthGuard],
  },

  

  

  

  
  
  // {
  //   path: 'employees',
  //   component: EmployeesComponent,
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: 'employee',
  //   loadChildren: () =>
  //     import('./features/employees/employees.module').then(
  //       (m) => m.EmployeesModule
  //     ),
  // },
  { path: '**', redirectTo: '' },
];
