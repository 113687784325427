import { gql } from 'apollo-angular';

export const EXPORT_EMPLOYEES_TO_PDF = gql`
  mutation exportEmployeesToPDF($filter: EmployeeFilter, $fields: [String!]!) {
    exportEmployeesToPDF(filter: $filter, fields: $fields)
  }
`;

export const EXPORT_EMPLOYEES_TO_CSV = gql`
  mutation exportEmployeesToCSV($filter: EmployeeFilter, $fields: [String!]!) {
    exportEmployeesToCSV(filter: $filter, fields: $fields)
  }
`;

export const EXPORT_EMPLOYEES_TO_XLSx = gql`
  mutation exportEmployeesToXlsx($filter: EmployeeFilter, $fields: [String!]!) {
    exportEmployeesToXlsx(filter: $filter, fields: $fields)
  }
`;

export const CREATE_EMPLOYEE = gql`
  mutation createEmployee(
    $createEmployeeInput: CreateEmployeeDTO!
    $createUserDTO: CreateUserDTO
  ) {
    createEmployee(
      createEmployeeInput: $createEmployeeInput
      createUserDTO: $createUserDTO
    ) {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      isPortalUser
      departmentId
      department {
        id
        departmentName
        status
        createdAt
        updatedAt
        deletedAt
      }
      designationId
      designation {
        id
        designationName
        status
        createdAt
        updatedAt
        deletedAt
      }
      reportingManagerId
      branchId
      branch {
        id
        branchName
        address
        city
        state
        country
        zipCode
        phoneNumber
        branchCode
        isHeadOffice
        email
      }
      employeeSalaries {
        id
        employeeId
        basicSalary
        hra
        allowances
        deductions
        grossSalary
        netSalary
        remarks
      }
    }
  }
`;

export const CREATE_EMPLOYEE_EXIT = gql`
  mutation createEmployeeExit(
    $createEmployeeExitInput: CreateEmployeeExitInput!
  ) {
    createEmployeeExit(createEmployeeExitInput: $createEmployeeExitInput) {
      id
      employeeId
      exitDate
      exitType
      exitReason
      isNoticePeriod
      isExitInterview
      interviewDate
      interviewNotes
      isAssetReturned
      clearanceStatus
      clearanceDate
      settlementStatus
      settlementAmount
      settlementDate
      exitDocuments
      isAccountsDisabled
      lastWorkingDay
      isManagerApproval
      isHrApproval
      isFinalApproval
      feedbackScore
      isRehireEligibility
    }
  }
`;

export const CREATE_EMPLOYEE_SALARY = gql`
  mutation createEmployeeSalary($input: CreateEmployeeSalaryInput!) {
    createEmployeeSalary(createEmployeeSalaryInput: $input) {
      id
      employeeId
      basicSalary
      hra
      allowances
      deductions
      grossSalary
      netSalary
      remarks
    }
  }
`;

export const UPDATE_EMPLOYEE = gql`
  mutation updateEmployee(
    $id: Int!
    $updateEmployeeInput: UpdateEmployeeDTO!
    $createUserDTO: CreateUserDTO!
  ) {
    updateEmployee(
      id: $id
      updateEmployeeInput: $updateEmployeeInput
      createUserDTO: $createUserDTO
    ) {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      isPortalUser
      departmentId
      designationId
      reportingManagerId
      branchId
    }
  }
`;

export const UPDATE_EMPLOYEE_EXIT = gql`
  mutation updateEmployeeExit(
    $updateEmployeeExitInput: UpdateEmployeeExitInput!
  ) {
    updateEmployeeExit(updateEmployeeExitInput: $updateEmployeeExitInput) {
      id
      employeeId
      exitDate
      exitType
      exitReason
      isNoticePeriod
      isExitInterview
      interviewDate
      interviewNotes
      isAssetReturned
      clearanceStatus
      clearanceDate
      settlementStatus
      settlementAmount
      settlementDate
      exitDocuments
      isAccountsDisabled
      lastWorkingDay
      isManagerApproval
      isHrApproval
      isFinalApproval
      feedbackScore
      isRehireEligibility
    }
  }
`;

export const UPDATE_EMPLOYEE_SALARY = gql`
  mutation updateEmployeeSalary(
    $updateEmployeeSalaryInput: UpdateEmployeeSalaryInput!
  ) {
    updateEmployeeSalary(
      updateEmployeeSalaryInput: $updateEmployeeSalaryInput
    ) {
      id
      employeeId
      basicSalary
      hra
      allowances
      deductions
      grossSalary
      netSalary
      remarks
    }
  }
`;

export const REMOVE_EMPLOYEE = gql`
  mutation removeEmployee($id: Int!) {
    removeEmployee(id: $id)
  }
`;
