import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { GET_CURRENCIES } from '../graphql/querries/currency-adjustment.query';
import { HttpClient } from '@angular/common/http';
import { CREATE_CURRENCY } from '../graphql/mutations/currency-adjustment.mutation';

@Injectable({
  providedIn: 'root',
})
export class CurrencyAdjustmentService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  fetchCurrencies(search: string = '', filter: {}): Observable<any> {
    return this.apollo
      .use('shahid')
      .watchQuery<any>({
        query: GET_CURRENCIES,
        variables: { search, filter },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data?.currencies));
  }

  createCurrency(createCurrencyInput: any): Observable<any> {
    return this.apollo.use('shahid').mutate({
      mutation: CREATE_CURRENCY,
      variables: {
        createCurrencyInput: createCurrencyInput,
      },
    });
  }
}
