<mat-dialog-content>
    <section class="upload-attendance-wrapper">
        <h2 class="cmn-popup-title">Update Attendance</h2>
        <button class="cmn-close" mat-dialog-close></button>
        <div class="form-wrapper">
            <mat-form-field>
                <input matInput [matDatepicker]="picker" placeholder="Choose Date" (dateInput)="onDateChange($event)">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="cmn-upload-box">
            <img src="assets/images/upload-image.png" alt="Upload Image">
            <h3>Drag and drop your file here</h3>
            <h4>or</h4>
            <input #fileInput type="file" (change)="onFileSelected($event)" accept=".csv" style="display: none;"/>
            <button class="cmn-choose-file-btn" (click)="fileInput.click()"><img src="assets/images/icons/icon-add-color.svg" alt="Icon Add">Choose
                a File
            </button>
        </div>
        <div class="uploaded-file" *ngIf="csvFile">
            <h6>{{csvFile?.name}} <img src="assets/images/icons/icon-close.svg" alt="Close Icon" (click)="removeFile()"></h6>
        </div>
        <div class="upload-info">
            <h5>CSV Format only supported</h5>
            <a href="assets/sample-files/attendances.csv" class="download-sample" download="attendances.csv">Download Sample CSV</a>
        </div>
        <button class="cmn-upload-submit-btn active" (click)="onUpdate()">Update Attendance</button>
    </section>
</mat-dialog-content>