import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PersonalInfoComponent } from './components/personal-info/personal-info.component';
import { ProfessionalInfoComponent } from './components/professional-info/professional-info.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { BranchAccessComponent } from './components/branch-access/branch-access.component';
import { SalaryDetailsComponent } from './components/salary-details/salary-details.component';


@Component({
  selector: 'app-add-employee',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatTabsModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    PersonalInfoComponent,
    ProfessionalInfoComponent,
    DocumentsComponent,
    BranchAccessComponent,
    SalaryDetailsComponent
  ],
  templateUrl: './add-employee.component.html',
  styleUrl: './add-employee.component.scss'
})
export class AddEmployeeComponent {

}
