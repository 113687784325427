import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile-branch-access',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './profile-branch-access.component.html',
  styleUrl: './profile-branch-access.component.scss',
})
export class ProfileBranchAccessComponent {
  branchInfoForm: FormGroup;
  errorMessage: string | null = null;
  @Output() formSubmitted = new EventEmitter<void>();
  constructor(private fb: FormBuilder, private toastr: ToastrService) {
    this.branchInfoForm = this.fb.group({
      branch: ['', Validators.required],
      designation: ['', Validators.required],
      userName: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  onSubmit(): void {
    if (this.branchInfoForm.valid) {
      console.log('Branch Info:', this.branchInfoForm.value);
      console.log('Form submitted');
      this.toastr.success('Branch Info submitted successfully');
      this.formSubmitted.emit();
    } else {
      this.toastr.error('Please fill all required fields');
    }
  }
}
