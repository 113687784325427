import { InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { NamedOptions } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../../../environments/environment';

export const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('AUTH_TOKEN') ?? '';
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const apolloOptions = (httpLink: HttpLink): NamedOptions => {
  return {
    default: {
      cache: new InMemoryCache(),
      link: authLink.concat(
        httpLink.create({
          uri: environment.url.defaultUrl,
        })
      ),
      defaultOptions: { query: { fetchPolicy: 'network-only' } },
    },
    hrms: {
      cache: new InMemoryCache(),
      link: authLink.concat(
        httpLink.create({
          uri: environment.url.hrmsModuleUrl,
        })
      ),
    },
    project: {
      cache: new InMemoryCache(),
      link: authLink.concat(
        httpLink.create({
          uri: environment.url.projectModuleUrl,
        })
      ),
    },
    rinoj: {
      cache: new InMemoryCache(),
      link: authLink.concat(
        httpLink.create({
          uri: environment.url.rinoj,
        })
      ),
    },
    shahid: {
      cache: new InMemoryCache(),
      link: authLink.concat(
        httpLink.create({
          uri: environment.url.shahid,
        })
      ),
    },
  };
};
