import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import {
  GET_BRANCH_NAMES,
  GET_EMPLOYEES,
} from '../graphql/queries/employee.queries';
import { EXPORT_EMPLOYEES } from '../graphql/mutation/employee.mutation';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  constructor(private http: HttpClient, private apollo: Apollo) {}

  getEmployees(search: string = '', filter: {}): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_EMPLOYEES,
        variables: { search, filter },
      })
      .valueChanges.pipe(map((result) => result.data?.getEmployees || []));
  }

  // Fetch branches
  getBranches(): Observable<any[]> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_BRANCH_NAMES,
      })
      .valueChanges.pipe(map((result) => result?.data?.branches || []));
  }

  //Fetch employee types
  getEmployeeTypes(): Observable<any[]> {
    return this.http.get<any[]>('assets/employee-types.json');
  }

  //expoprting employee data
  exportEmployeesToPDF( fields: string[]): Observable<any> {
    return this.apollo
      .use('shahid')
      .mutate<any>({
        mutation: EXPORT_EMPLOYEES,
        variables: {
          fields,
        },
      })
      .pipe(map((result) => result.data?.exportEmployeesToPDF));
  }
}
