import { ColDef } from 'ag-grid-community';
import { Component, OnInit } from '@angular/core';
import { CommonModule, DatePipe, formatDate } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { AttendanceDataService } from './services/attendance.service';
import { ToastrService } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-attendance',
  standalone: true,
  imports: [CommonModule, AgGridAngular, FormsModule],
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss'],
  providers: [DatePipe],
})
export class AttendanceComponent implements OnInit {
  public rowData: any[] | null = null;
  public startDate: string | null = null;
  public endDate: string | null = null;

  public columnDefs: ColDef[] = [
    { field: 'date', headerName: 'Date', flex: 1 },
    { field: 'checkInTime', headerName: 'Check In', flex: 1 },
    {
      field: 'checkOutTime',
      headerName: 'Check Out',
      filter: 'agNumberColumnFilter',
      flex: 1,
    },
    {
      field: 'overtimeHours',
      headerName: 'OT',
      filter: 'agNumberColumnFilter',
      flex: 1,
    },
    {
      field: 'totalHoursWorked',
      headerName: 'Working Hours',
      filter: 'agNumberColumnFilter',
      flex: 1,
    },
    { field: 'attendanceStatus', headerName: 'Status', flex: 1 },
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';
  public loading: boolean = true;
  public error: any = null;
  constructor(
    private attendanceService: AttendanceDataService,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.fetchAttendanceData();
  }

  onDateFilterChange() {
    this.fetchAttendanceData();
  }

  private fetchAttendanceData() {
    this.loading = true;

    const filter =
      this.startDate && this.endDate
        ? {
            dateRange: {
              startDate: this.startDate,
              endDate: this.endDate,
            },
          }
        : {};

    this.attendanceService.fetchAttendances(filter).subscribe({
      next: (attendances) => {
        this.rowData = this.formatAttendanceData(attendances);
        this.loading = false;
      },
      error: (error) => {
        this.error = error;
        this.loading = false;
        console.error('Error fetching attendance data:', error);
      },
    });
  }

  // private formatDateToBackend(date: string | null): string | null {
  //   if (!date) {
  //     return null;
  //   }
  //   return formatDate(new Date(date), 'yyyy-MM-dd', 'en');
  // }

  private formatAttendanceData(attendances: any[]): any[] {
    return attendances.map((attendance) => ({
      ...attendance,
      date: this.datePipe.transform(
        new Date(Number(attendance.date)),
        'MMM d, y'
      ),
    }));
  }
}
