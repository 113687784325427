import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CurrencyAdjustmentService } from '../../features/currency-adjustments/services/currency-adjustment.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-currency',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './add-currency.component.html',
  styleUrl: './add-currency.component.scss',
})
export class AddCurrencyComponent implements OnInit {
  addCurrencyForm: FormGroup;
  successMessage: string | null = null;
  errorMessage: string | null = null;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private currencyService: CurrencyAdjustmentService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.initForm();
  }

  ngOnInit(): void {}

  initForm() {
    this.addCurrencyForm = this.fb.group({
      currencyCode: ['', Validators.required],
      currencyName: ['', Validators.required],
      currencySymbol: ['', Validators.required],
      exchangeRate: [
        '',
        [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)],
      ],
    });
  }

  onSubmit() {
    if (!this.addCurrencyForm.valid) {
      this.addCurrencyForm.markAllAsTouched();
    } else {
      const formData = {
        ...this.addCurrencyForm.value,
        exchangeRate: parseFloat(this.addCurrencyForm.value.exchangeRate),
      };

      this.currencyService.createCurrency(formData).subscribe({
        next: (response) => {
          this.successMessage =
            'Currency adjustment has been successfully created!';
          this.errorMessage = null;
          this.toastr.success(response.message || this.successMessage);
          this.router.navigate(['/currency-adjustments']);
          this.loading = false;
        },
        error: (error) => {
          console.error('Error creating currency adjustment:', error);

          if (error && error.message) {
            this.errorMessage = error.message;
          } else {
            this.errorMessage =
              'An unknown error occurred while creating currency adjustment.';
          }
          this.toastr.error(
            this.errorMessage ?? 'Failed to create currency adjustment'
          );
          this.loading = false;
        },
      });
    }
  }

  onCancel() {
    this.addCurrencyForm.reset();
  }
}
