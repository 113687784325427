<div class="profile-inner-main-wrapper">
  <div class="details-wrapper">
    <h2>Employee Type</h2>
    <h3>{{employeeData?.employeeType}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Employment Data</h2>
    <h3>{{employeeData?.employmentType}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Employee Status</h2>
    <h3>{{employeeData?.employeeStatus}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Department</h2>
    <h3>{{employeeData?.department?.departmentName}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Designation</h2>
    <h3>{{employeeData?.designation?.designationName}}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Joining Date</h2>
    <h3>{{employeeData?.dateOfJoining | date: 'MMMM d, yyyy' }}</h3>
  </div>
  <div class="details-wrapper">
    <h2>Current CTC</h2>
    <h3>{{employeeData?.salary}}</h3>
  </div>
</div>
