import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';

interface TreeNode {
  name: string;
  details: string;
  children?: TreeNode[];
  level?: number;  // Add the level property as optional
}

@Component({
  selector: 'app-reports',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatTabsModule,
    MatSelectModule
  ],
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.scss'
})
export class ReportsComponent {


  selectedOption = 'today';

  selectedTabIndex = 0;

  setTab(index: number) {
    this.selectedTabIndex = index;
  }

  data: TreeNode[] = [
    {
      name: 'Assets',
      details: '',
      children: [
        {
          name: 'Current Address',
          details: '',
          children: [
            {
              name: 'Cash', details: '',
              children: [
                { name: 'Total For Cash', details: '0.00' }
              ]
            },
            {
              name: 'Bank', details: '',
              children: [
                { name: 'Total For Bank', details: '0.00' }
              ]
            },
            {
              name: 'Other Current Assets', details: '',
              children: [
                { name: 'Total For Other Assets', details: '0.00' }
              ]
            }
          ]
        },
        { name: 'Child 1.2', details: 'Details 1.2' }
      ]
    },
    { name: 'Parent 2', details: 'Details 2' }
  ];

  // Recursive method to render the rows
  getTableRows(node: TreeNode, level: number = 0): TreeNode[] {
    let rows: TreeNode[] = [{ ...node, level }]; // Create a row with the level

    // If there are children, add them recursively
    if (node.children && node.children.length > 0) {
      for (let child of node.children) {
        rows = [...rows, ...this.getTableRows(child, level + 1)];
      }
    }

    return rows;
  }


  // Method to dynamically assign class based on the node or level
  getNodeClass(node: TreeNode): string | string[] {
    // Apply 'child' class for the first child, and 'child2' for the second, based on your requirements
    if (node.level === 0) {
      return 'title';
    } else if (node.level === 1) {
      return 'sub-title';
    } else if (node.level === 2) {
      return 'sub-title';
    } else if (node.level === 3) {
      return 'sub-total';
    }

    // You can add more conditions if necessary
    return '';
  }

}
