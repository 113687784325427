<mat-dialog-content>
  <section class="leave-edit-dialog-wrapper">
    <h2 class="cmn-popup-title">Edit Leave</h2>
    <h3> <span>For</span> {{ data.leaveData?.name}} </h3>
    <button class="cmn-close" mat-dialog-close (click)="onClose()"></button>
    <!-- form div -->
    <form [formGroup]="leaveEditForm" (ngSubmit)="onEdit()">
    <div>
      <div class="leave-edit-form-wrapper">
        <div class="form-wrapper">
          <label for="">From Date</label>
          <mat-form-field>
            <input matInput [matDatepicker]="pickerFrom" formControlName="startDate" placeholder="Choose date" />
            <mat-datepicker-toggle matIconSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </mat-form-field>
        </div>
        <!-- <div class="form-wrapper">
          <label for="">Session</label>
          <mat-form-field>
            <mat-select placeholder="Select session">
              <mat-option value="Single">Single</mat-option>
              <mat-option value="Married">Married</mat-option>
              <mat-option value="Divorced">Divorced</mat-option>
              <mat-option value="Widowed">Widowed</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <div class="form-wrapper">
          <label for="">To Date</label>
          <mat-form-field>
            <input matInput [matDatepicker]="pickerTo" formControlName="endDate" placeholder="Choose date" />
            <mat-datepicker-toggle matIconSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>

          </mat-form-field>
        </div>
        <!-- <div class="form-wrapper">
          <label for="">Session</label>
          <mat-form-field>
            <mat-select placeholder="Select session">
              <mat-option value="Single">Single</mat-option>
              <mat-option value="Married">Married</mat-option>
              <mat-option value="Divorced">Divorced</mat-option>
              <mat-option value="Widowed">Widowed</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <div class="form-wrapper">
          <label for="">Leave type</label>
          <mat-form-field>
            <mat-select placeholder="Select Type" formControlName="leaveType">
              <mat-option value="CL">Casual Leave</mat-option>
              <mat-option value="EL">Earned Leave</mat-option>
              <mat-option value="EML">Emergency Leave</mat-option>
              <mat-option value="ML">Marriage Leave</mat-option>
              <mat-option value="LOP">Loss Of Pay</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Reason for Leave</label>
          <mat-form-field>
            <input
            matInput
            placeholder="Enter Reason"
            formControlName="employeeComment"
          />
          </mat-form-field>
        </div>  
      </div>
    </div>
    <div class="upload-wrapper">
      <div class="cmn-upload-box">
        <h4>Upload Documents (optional)</h4>
        <input
        #fileInput
        type="file"
        (change)="onFileSelected($event)"
        style="display: none"
      />
        <button type="button" class="cmn-choose-file-btn" (click)="fileInput.click(); $event.stopPropagation()" >
          <img
            src="assets/images/icons/icon-add-color.svg"
            alt="Icon Add"
          />Choose a File
        </button>
      </div>
    </div>
    <div class="uploaded-file" *ngIf="csvFile.length > 0">
      <div *ngFor="let file of csvFile; let i = index">
        <h6>
          {{ file.name }}
          <img
            src="assets/images/icons/icon-close.svg"
            alt="Close Icon"
            (click)="removeFile(i)"
          />
        </h6>
      </div>
    </div>
    <div class="btn-wrapper">
      <button type="submit" class="cmn-popup-btn">Submit</button>
    </div>
    <!-- form div -->
  </form>
  </section>
</mat-dialog-content>