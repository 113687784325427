<app-layout>
  <section class="cmn-innerpage-wrapper currency-adjustments">
    <div class="head">
      <div class="search-wrapper">
        <input
          type="text"
          class="cmn-header-search"
          placeholder="Search"
          (input)="onSearch($event)"
        />
      </div>
      <button
        class="cmn-filter-btn"
        (click)="toggleDropdown($event)"
        [ngClass]="{ 'class-clicked': isDropdownOpen }"
      >
        <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
        <span>3</span>
      </button>
      <div
        [ngClass]="{ show: isDropdownOpen }"
        class="common-dropdown filter-position"
        #dropdown
      >
        <button
          class="close-dropdown"
          (click)="toggleDropdown($event)"
        ></button>
        <form>
          <h6 class="cmn-popup-heading">Filter <span>Clear All</span></h6>
          <div class="filter-inner-content">
            <h2>Branch</h2>
            <div class="check-wrapper">
              <div class="check-box">
                <label class="checkbox-wrapper">
                  <input type="checkbox" />
                  test
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="btn-wrapper">
            <button class="cmn-apply-btn" type="submit">Apply</button>
          </div>
        </form>
      </div>
      <button class="cmn-upload-btn" (click)="openDialog()">
        <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
        <span>Upload</span>
      </button>
      <button class="cmn-add-btn" (click)="navigateTo('currency-adjustments/add-currency')">
        <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Make an
        adjustment
      </button>
    </div>
    <div class="inner-content">
      <ag-grid-angular
        style="width: 100%"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [pagination]="true"
        [paginationPageSize]="paginationPageSize"
        [paginationPageSizeSelector]="paginationPageSizeSelector"
        [class]="themeClass"
        (rowClicked)="onRowClicked($event)"
      />
    </div>
  </section>
</app-layout>
