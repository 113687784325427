import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { EmployeeService } from '../../../../features/employees/services/employee.service';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-branch-access',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
  ],
  templateUrl: './branch-access.component.html',
  styleUrl: '../../add-employee.component.scss',
})
export class BranchAccessComponent implements OnInit, OnChanges {
  branchAccessForm: FormGroup;
  public userRolesData: any[] = [];
  public branchData: any[] = [];
  public isPortalUserEnabled: boolean = false;

  @Input() employee: any;
  @Input() isEditMode: boolean = false;
  @Input() employeeData: any = {};
  @Output() formSubmit = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private employeeService: EmployeeService
  ) {
    this.branchAccessForm = this.fb.group({
      branchName: ['', Validators.required],
      isPortalUser: [false],
      userRole: [''],
      // userName: [''],
      password: [''],
    });
  }

  ngOnInit(): void {
    this.loadUserRoles();
    this.loadBranches();

    this.branchAccessForm
      .get('isPortalUser')
      ?.valueChanges.subscribe((isPortalUser: boolean) => {
        this.togglePortalUserFields(isPortalUser);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['employee'] && this.isEditMode && this.employee) {
      this.populateFormWithEmployeeData();
    }
  }

  private loadUserRoles() {
    this.employeeService.getUserRoles().subscribe({
      next: (userRoles) => {
        this.userRolesData = userRoles;
      },
      error: (error) => console.error(error),
    });
  }

  private loadBranches() {
    this.employeeService.getBranches().subscribe({
      next: (branches) => {
        this.branchData = branches;
      },
      error: (error) => console.error(error),
    });
  }

  populateFormWithEmployeeData(): void {
    this.branchAccessForm.patchValue({
      branchName: this.employee.branch?.id,
      isPortalUser: this.employee.isPortalUser,
      userRole: this.employee.user?.profileId,
    });
    this.isPortalUserEnabled = this.employee.isPortalUser;
    this.togglePortalUserFields(this.employee.isPortalUser);
    console.log('isPortalUserEnabled::', this.isPortalUserEnabled);
  }

// Function to toggle validators for portal user fields
private togglePortalUserFields(isPortalUser: boolean) {
  this.isPortalUserEnabled = isPortalUser; // Update the isPortalUserEnabled value
  if (isPortalUser) {
    // If it's a portal user, make fields required
    this.branchAccessForm.get('userRole')?.setValidators([Validators.required]);
    this.branchAccessForm.get('password')?.setValidators([Validators.required]);
  } else {
    // If it's not a portal user, remove validators
    this.branchAccessForm.get('userRole')?.clearValidators();
    this.branchAccessForm.get('password')?.clearValidators();
  }

  // Update the validity state after changing validators
  this.branchAccessForm.get('userRole')?.updateValueAndValidity();
  this.branchAccessForm.get('password')?.updateValueAndValidity();

  console.log('isPortalUserEnabled updated to:', this.isPortalUserEnabled); // For debugging
}


  onNext(): void {
    if (this.branchAccessForm.invalid) {
      this.branchAccessForm.markAllAsTouched();
      console.log('Form is Invalid');
    } else {
      const formValue = this.branchAccessForm.value;
      if (!formValue.isPortalUser) {
        formValue.userRole = null;
        // formValue.userName = null;
        formValue.password = null;
      }

      console.log('Branch Data Submitted-----:', formValue);
      this.formSubmit.emit(formValue);
    }
  }

  onCancel(): void {
    this.branchAccessForm.reset();
  }
}
