import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile-proffesional-info',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './profile-proffesional-info.component.html',
  styleUrl: './profile-proffesional-info.component.scss',
})
export class ProfileProffesionalInfoComponent implements OnInit {
  professionalInfoForm: FormGroup;
  errorMessage: string | null = null;
  maxDate: string;
  @Output() formSubmitted = new EventEmitter<void>();
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private http: HttpClient
  ) {
    this.professionalInfoForm = this.fb.group({
      employeeId: ['', Validators.required],
      currentCTC: ['', Validators.required],
      employeeType: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      workingDays: ['', Validators.required],
      joiningDate: ['', Validators.required],
      officeLocation: ['', Validators.required],
    });
    this.maxDate = this.getTodayDate();
  }
  ngOnInit(): void {
  }
  
  private getTodayDate(): string {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = (today.getMonth() + 1).toString().padStart(2, '0');
    const dd = today.getDate().toString().padStart(2, '0');

    return `${yyyy}-${mm}-${dd}`;
  }

  onSubmit(): void {
    if (this.professionalInfoForm) {
      console.log('Professional Info------', this.professionalInfoForm.value);
      this.toastr.success('Professional info submitted successfully');
      this.formSubmitted.emit();
    } else {
      this.toastr.error('Someting went wrong');
    }
  }
}
