<div class="tab-content-wrapper">
    <div class="employee-form-wrapper">
        <div class="form-wrapper">
            <label for="">Visa Number</label>
            <mat-form-field>
                <input matInput placeholder="Enter Visa Number" formControlName="branchName" />
            </mat-form-field>
        </div>
        <div class="form-wrapper">
            <label for="">Passport Number</label>
            <mat-form-field>
                <input matInput placeholder="Enter Passport Number" formControlName="branchName" />
            </mat-form-field>
        </div>
        <div class="form-wrapper">
            <label for="">Bank Name</label>
            <mat-form-field>
                <input matInput placeholder="Enter Bank Name" formControlName="branchName" />
            </mat-form-field>
        </div>
        <div class="form-wrapper">
            <label for="">Account No.</label>
            <mat-form-field>
                <input matInput placeholder="Enter Account No." formControlName="branchName" />
            </mat-form-field>
        </div>
    </div>
    <div class="submit-btn-wrapper">
        <button class="cmn-cancel-btn">Cancel</button>
        <button class="cmn-next-btn">Next</button>
    </div>
</div>