import { Component } from '@angular/core';
import { RouterOutlet, RouterModule } from '@angular/router';
import { PunchInOutComponent } from './components/punchInOut/punchInOut.component';
import { ProfileComponent } from './components/profile/profile.component';
import { CommonModule } from '@angular/common';
import { AttendanceComponent } from "./components/attendance/attendance.component";
import { ProjectsComponent } from "./components/projects/projects.component";
import { LeavesComponent } from "./components/leaves/leaves.component";
import { AssetsComponent } from "./components/assets/assets.component";
import { LayoutComponent } from "../../../core/layout/layout.component";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    PunchInOutComponent,
    RouterOutlet,
    RouterModule,
    ProfileComponent,
    CommonModule,
    AttendanceComponent,
    ProjectsComponent,
    LeavesComponent,
    AssetsComponent,
    LayoutComponent
],
  templateUrl: './profile-management.component.html',
  styleUrl: './profile-management.component.scss',
})
export class ProfileManagementComponent {
  activeTab: string = 'profile';

  selectTab(tabName: string): void {
    this.activeTab = tabName;
  }
}
