import { Component, Inject, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { LeaveHistoryComponent } from './components/leave-history/leave-history.component';
import { LeaveService } from '../../features/leave/services/leave.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-leave-approvals',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    LeaveHistoryComponent
  ],
  templateUrl: './leave-approvals.component.html',
  styleUrl: './leave-approvals.component.scss'
})
export class LeaveApprovalsComponent {
  isDropdownOpen = false;
  employeeId: any = null;
  leaveData: any = null;
  leaveApprovers: any = null;
  casualLeave: any;
  earnedLeave: any;
  emergencyLeave: any;
  marriageLeave: any;
  lossOfPay: any;
  leaveApprovals: any;
  levelOneId: any;
  levelTwoId: any;
  levelThreeId: any;
  levelFourId: any;
  levelOneApproverStatus: any;
  levelTwoApproverStatus: any;
  levelThreeApproverStatus: any;
  levelFourApproverStatus: any;
  leaveId: string;
  activityType: any;
  search: any;
  timelines: any;

  constructor(
    private leaveService: LeaveService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ){}

  ngOnInit(){
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.leaveId = id;
      this.fetchLeaves(id);
    }
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
    if(this.isDropdownOpen){
      this.leaveService.fetchTimelines("LEAVE", Number(this.leaveId), this.activityType, this.search).subscribe({
        next: (data) => {
            this.timelines = data;
        },
        error: (error) => {
          this.toastr.error("Error in fetching timelines",'Error')
        }
      })
    }
  }

  fetchLeaves(id: any){
    this.leaveService.fetchLeaveById(id).subscribe({
      next: (data) => {
        const type = "LEAVE";
        this.employeeId = data?.employee?.id;
        this.getLeaveApprovals(data?.id, type);
        this.getLeaveCount(this.employeeId);
        this.leaveData = data;
        this.leaveApprovers = data?.employee?.employeeApprovers;
        console.log("this.leaveApprovers",this.leaveApprovers);
        
        this.leaveApprovers.map((leaveApprover: any) => {
            this.levelOneId = leaveApprover?.levelOneData?.id;
            this.levelTwoId = leaveApprover?.levelTwoData?.id;
            this.levelThreeId = leaveApprover?.levelThreeData?.id;
            this.levelFourId = leaveApprover?.levelFourData?.id;
        })
      },
      error: (error) => {
        this.toastr.error("Error in fetching leave",'Error')
      }
    })
  }

  getLeaveApprovals(leaveId: any, type: any) {
    this.leaveService.getLeaveApprovals(leaveId, type).subscribe({
      next: (response) => {
        response.map((data: any) => {
          if(this.levelOneId === data?.approverId){
            this.levelOneApproverStatus = data?.approvalStatus;
          } else if (this.levelTwoId === data?.approverId){
            this.levelTwoApproverStatus = data?.approvalStatus;
          } else if (this.levelThreeId === data?.approverId){
            this.levelThreeApproverStatus = data?.approvalStatus;
          } else if (this.levelFourId === data?.approverId){
            this.levelFourApproverStatus = data?.approvalStatus;
          } 
          this.leaveApprovals = response?.leaveApprovals || [];
          
        }) 
        
      },
      error: (error) => {
        this.toastr.error("Error in fetching approvals", 'Error');
      }
    });
  }

  getLeaveCount(employeeId: any){
    this.leaveService.getLeaveCount(employeeId).subscribe({
      next: (data) => {
        this.casualLeave = data?.CL;
        this.earnedLeave = data?.EL;
        this.emergencyLeave = data?.EML;
        this.marriageLeave = data?.ML;
        this.lossOfPay = data?.LOP;
      },
      error: (error) => {
        this.toastr.error("Error in fetching leave count",'Error')
      }
    })
  }

  readonly dialog = inject(MatDialog);


  onReject(){
    const status = false;
    const dialogRef = this.dialog.open(PayrollApprovalsDialog, {
      disableClose: true,
      data: {status: status, leaveId: this.leaveId},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(result){
        this.toastr.success('Leave request rejected successfully', 'Success');
      }
    });
  }

  onApprove(){
    const status = true;
    const dialogRef = this.dialog.open(PayrollApprovalsDialog, {
      disableClose: true,
      data: {status: status, leaveId: this.leaveId},
    });

    dialogRef.afterClosed().subscribe((result) => {
        if(result){
          this.toastr.success('Leave request approved successfully', 'Success');
        }
    });
  }
  
}


@Component({
  selector: 'leave-approvals-dialog',
  templateUrl: 'leave-approvals-dialog.html',
  styleUrls: ['./leave-approvals.component.scss'],
  standalone: true,
  imports: [
    CommonModule, 
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class PayrollApprovalsDialog {
  @ViewChild('LeaveApprovalsComponent')LeaveApprovalsComponent: LeaveApprovalsComponent;
  status: boolean;
  approvedStatus: string = '';
  leaveId: number;
  applyApprovalForm: FormGroup;
  rejectedStatus: string;

  constructor(
  @Inject(MAT_DIALOG_DATA) public data: any,
  private fb: FormBuilder,
  private leaveService: LeaveService,
  private toastr: ToastrService,
  public dialogRef: MatDialogRef<PayrollApprovalsDialog>,
  ){}

  ngOnInit(){
    this.status = this.data.status;
    this.approvedStatus = 'APPROVED';
    this.rejectedStatus = 'REJECTED';
    this.leaveId = this.data.leaveId;
    this.applyApprovalForm = this.fb.group({
      approvalNote: ['', Validators.required],  
    });
  }

  handleClick(){
    if (this.status) {
      this.onStatusUpdate(true); 
    }else {
      this.onStatusUpdate(false); 
    }
  }

  onStatusUpdate(status: any){
    if(status == true) {
      this.leaveService.updateLeaveStatus(this.leaveId, this.approvedStatus, this.applyApprovalForm?.value?.approvalNote).subscribe({
        next: (data) => {
          this.dialogRef.close(data);
          this.LeaveApprovalsComponent.leaveApprovals();
        },
        error: (error) => {
          this.toastr.error(error?.message, 'Error')
        }
      })
    } else if(status == false) {
      this.leaveService.updateLeaveStatus(this.leaveId, this.rejectedStatus, this.applyApprovalForm?.value?.approvalNote).subscribe({
        next: (data) => {
          this.dialogRef.close(data);
        },
        error: (error) => {
          this.toastr.error(error?.message, 'Error')
        }
      })
    }
    }

  }