<div class="tab-content-wrapper">
  <form [formGroup]="documentForm" (ngSubmit)="onNext()">
    <div class="employee-form-wrapper">
      <div class="form-wrapper">
        <label for="">Select Visa Type</label>
        <mat-form-field>
          <mat-select placeholder="Select Visa Type" formControlName="visaType">
            <mat-option
              *ngFor="let visaType of visaTypeData"
              [value]="visaType.visaType"
            >
              {{ visaType.visaType }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              documentForm.get('visaType')!.touched &&
              documentForm.get('visaType')!.hasError('required')
            "
            >Visa Type is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Select Visa Issue Date</label>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker3"
            formControlName="visaIssueDate"
            [matDatepickerFilter]="dateFilter"
            placeholder="Enter Visa Issue Date"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker3"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker3></mat-datepicker>
          <mat-error
            *ngIf="
              documentForm.get('visaIssueDate')!.touched &&
              documentForm.get('visaIssueDate')!.hasError('required')
            "
            >Visa Issue Date is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Select Visa Expiry Date</label>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker4"
            formControlName="visaExpiryDate"
            placeholder="Enter Visa Expiry Date"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker4"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker4></mat-datepicker>
          <mat-error
            *ngIf="
              documentForm.get('visaExpiryDate')!.touched &&
              documentForm.get('visaExpiryDate')!.hasError('required')
            "
            >Visa Expiry Date is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Passport Number</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Passport Number"
            formControlName="passportNumber"
            maxlength="15"
          /><mat-error
            *ngIf="
              documentForm.get('passportNumber')?.hasError('required') &&
              documentForm.get('passportNumber')?.touched
            "
          >
            Passport Number is required
          </mat-error>
          <mat-error
            *ngIf="
              documentForm
                .get('passportNumber')
                ?.hasError('invalidPassportNumber') &&
              !documentForm.get('passportNumber')?.hasError('required') &&
              documentForm.get('passportNumber')?.touched
            "
          >
            Please enter a valid Passport Number
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Bank Name</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Bank Name"
            formControlName="bankName"
          /><mat-error
            *ngIf="
              documentForm.get('bankName')!.touched &&
              documentForm.get('bankName')!.hasError('required')
            "
            >Bank Name is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Account Number</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Account No."
            formControlName="accountNumber"
            maxlength="34"
          />
          <mat-error
            *ngIf="
              documentForm.get('accountNumber')?.hasError('required') &&
              documentForm.get('accountNumber')?.touched
            "
          >
            Account Number is required
          </mat-error>
          <mat-error
            *ngIf="
              documentForm
                .get('accountNumber')
                ?.hasError('invalidBankAccount') &&
              !documentForm.get('accountNumber')?.hasError('required') &&
              documentForm.get('accountNumber')?.touched
            "
          >
            Please enter a valid Account Number
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">IFSC Code</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter IFSC Code"
            formControlName="IFSCCode"
          /><mat-error
            *ngIf="
              documentForm.get('IFSCCode')!.touched &&
              documentForm.get('IFSCCode')!.hasError('required')
            "
            >IFSC Code is required.</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
      <button class="cmn-next-btn" type="submit">Next</button>
    </div>
  </form>
</div>
