import {
  Component,
  inject,
  HostListener,
  ElementRef,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { EmployeeService } from '../../features/employees/services/employee.service';
import { Subscription } from 'rxjs';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-employees',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    AgGridAngular,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './employees.component.html',
  styleUrl: './employees.component.scss',
})
export class EmployeesComponent implements OnInit {
  isDropdownOpen = false;
  isExportOpen = false;
  rowData: any[] = [];
  public loading: boolean = true;
  public error: any = null;
  public branchData: any[] = [];
  public employeeTypeData: any[] = [];
  public designationData: any[] = [];

  selectedFields: string[] = [];

  filterForm: FormGroup;
  exportForm: FormGroup;

  constructor(
    private elementRef: ElementRef,
    private employeeService: EmployeeService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.filterForm = this.fb.group({
      branches: this.fb.array([]),
      employeeTypes: this.fb.array([]),
    });

    this.exportForm = this.fb.group({
      fields: this.fb.array([]),
    });
  }

  columnDefs = [
    {
      headerName: 'Employee Name',
      field: 'employeeName',
      flex: 1,
      cellRenderer: (params: any) => {
        return `
          <div style="display: flex; align-items: center;">
            <img src="${params.data.profilePicture}" alt="Profile Image" style="width: 35px; height: 35px; margin-right: 10px; border-radius: 50%; object-fit: cover;">
            <span>${params.data.employeeName}</span>
          </div>
        `;
      },
    },
    { headerName: 'Employee ID', field: 'employeeId', flex: 1 },
    { headerName: 'Branch Name', field: 'branchName', flex: 1 },
    { headerName: 'Designation', field: 'designation', flex: 1 },
    { headerName: 'Type', field: 'employeeType', flex: 1 },
    {
      headerName: 'Status',
      field: 'employeeStatus',
      flex: 1,
      cellRenderer: (params: any) => {
        const status = params.data.employeeStatus;
        const statusClass = status === 'On Leave' ? 'on-leave' : 'working'; // Apply class if "On Leave"
        return `<span class="${statusClass}">${status}</span>`;
      },
    },
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  readonly dialog = inject(MatDialog);
  private dataSubscription: Subscription;

  ngOnInit(): void {
    this.loadEmployees();

    this.filterForm = this.fb.group({
      branches: this.fb.array([]),
      employeeTypes: this.fb.array([]),
      designations: this.fb.array([]),
    });

    this.loadBranchData();
    this.loadEmployeeTypeData();
  }

  private loadEmployees(search: string = '', filter: any = {}) {
    this.loading = true;

    this.employeeService.getEmployees(search, filter).subscribe({
      next: (employees) => {
        this.rowData = employees
          .filter((employee: any) => {
            const branchMatch =
              !filter.branchNames?.length ||
              filter.branchNames.includes(employee?.branch?.branchName);
            const employeeTypeMatch =
              !filter.employeeTypeNames?.length ||
              filter.employeeTypeNames.includes(employee?.employeeType);

            return branchMatch && employeeTypeMatch;
          })
          .map((employee: any) => {
            return {
              employeeName: `${employee?.firstName || 'N/A'} ${
                employee?.lastName || ''
              }`,
              employeeId: employee?.id || 'N/A',
              profilePicture:
                employee?.profilePicture ||
                'assets/images/dummy-profile-image.jpg',
              branchName: employee?.branch?.branchName || 'N/A',
              designation: employee?.designation?.designationName || 'N/A',
              employeeType: employee?.employeeType || 'N/A',
              employeeStatus: employee?.employeeStatus || 'N/A',
            };
          });

        this.loading = false;
      },
      error: (error) => {
        this.toastr.error(
          'Failed to load employees. Please try again.',
          'Error'
        );
        this.loading = false;
      },
    });
  }

  get branchesFormArray() {
    return this.filterForm.get('branches') as FormArray;
  }

  // Create a getter for the export form fields array
  get exportFieldsArray() {
    return this.exportForm.get('fields') as FormArray;
  }

  onFieldCheckboxChange(event: any, fieldName: string) {
    const fieldsArray: FormArray = this.exportForm.get('fields') as FormArray;

    if (event.target.checked) {
      fieldsArray.push(new FormControl(fieldName)); 
    } else {
      const index = fieldsArray.controls.findIndex((control) => control.value === fieldName);
      if (index >= 0) {
        fieldsArray.removeAt(index); 
      }
    }
  }

  // Method to trigger export functionality
  exportEmployees() {
    const selectedFields = this.exportForm.value.fields;
    // const filters = this.getSelectedFilters();

    if (selectedFields.length === 0) {
      this.toastr.error('Please select at least one field to export.', 'Error');
      return;
    }

    // Call the export service method
    this.employeeService.exportEmployeesToPDF( selectedFields).subscribe({
      next: (response) => {
        this.toastr.success('Employees exported successfully!', 'Success');
        window.open(response, '_blank');
      },
      error: (error) => {
        this.toastr.error('Failed to export employees. Please try again.', 'Error');
      },
    });
  }

  // Fetch selected filter data (for branches and employee types)
  // getSelectedFilters() {
  //   const selectedBranches = this.filterForm.value.branches
  //     .map((checked: boolean, i: number) =>
  //       checked ? this.branchData[i].branchName : null
  //     )
  //     .filter((value: string | null) => value !== null);

  //   const selectedEmployeeTypes = this.filterForm.value.employeeTypes
  //     .map((checked: boolean, i: number) =>
  //       checked ? this.employeeTypeData[i].employeeType : null
  //     )
  //     .filter((value: string | null) => value !== null);

  //   return {
  //     branch: selectedBranches,
  //     employeeType: selectedEmployeeTypes,
  //   };
  // }

  private addControls(formArrayName: string, data: any[]) {
    const formArray = this.filterForm.get(formArrayName) as FormArray;
    formArray.clear();

    data.forEach(() => {
      formArray.push(new FormControl(false));
    });
  }

  private loadBranchData() {
    this.employeeService.getBranches().subscribe({
      next: (branches) => {
        this.branchData = branches;
        this.addControls('branches', this.branchData);
      },
      error: (error) => console.error(error),
    });
  }

  private loadEmployeeTypeData() {
    this.employeeService.getEmployeeTypes().subscribe({
      next: (employeeTypes) => {
        this.employeeTypeData = employeeTypes;
        this.addControls('employeeTypes', this.employeeTypeData);
      },
      error: (error) => console.error('Error fetching employee types:', error),
    });
  }

  onSubmit() {
    const selectedBranches = this.filterForm.value.branches
      .map((checked: boolean, i: number) =>
        checked ? this.branchData[i].branchName : null
      )
      .filter((value: string | null) => value !== null);

    const selectedEmployeeTypes = this.filterForm.value.employeeTypes
      .map((checked: boolean, i: number) =>
        checked ? this.employeeTypeData[i].employeeType : null
      )
      .filter((value: string | null) => value !== null);

    const filters = {
      branch: selectedBranches,
      employeeType: selectedEmployeeTypes,
    };

    if (selectedBranches.length === 0 && selectedEmployeeTypes.length === 0) {
      this.toastr.error('Please select at least one filter to apply.', 'Error');
      return;
    }

    this.loadEmployees('', filters);
    this.toggleDropdown();
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadEmployeesDialog);
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleExport(event: MouseEvent) {
    event.stopPropagation();
    this.isExportOpen = !this.isExportOpen;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
      this.isExportOpen = false;
    }
  }

  handleDropdownClick(event: MouseEvent) {
    event.stopPropagation(); // Prevents click from propagating to the document
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe(); // Clean up subscription
    }
  }
}

const gridDiv = document.querySelector<HTMLElement>('#myGrid')!;

@Component({
  selector: 'upload-employees-dialog',
  templateUrl: 'upload-employees-dialog.html',
  styleUrls: ['./employees.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
})
export class UploadEmployeesDialog {
  csvFile: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      // Handle the file, e.g., upload it or read it
      console.log('Selected file:', file);
      this.csvFile = file;
    }
  }
}
