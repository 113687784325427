import { gql } from "apollo-angular";

export const GET_LEAVES = gql`
  query leaves($filter: LeaveManagementFilter) {
    leaves(filter: $filter) {
    id
    employeeId
    employee {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      employeeApprovers{
        levelOne
        levelOneData{
          id
          firstName
          lastName
        }
        levelTwo
        levelTwoData{
          id
          firstName
          lastName
        }
        levelThree
        levelThreeData{
          id
          firstName
          lastName
        }
        levelFour
        levelFourData{
          id
          firstName
          lastName
        }
      }
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      isPortalUser
      departmentId
    }
    leaveType
    startDate
    endDate
    totalLeaveDays
    leaveStatus
    employeeComment
    supportingDocuments
    createdAt
    updatedAt
    deletedAt
  }
  }
`;

export const GET_LEAVES_BY_ID = gql`
  query leave($id: Int!) {
    leave(id: $id) {
    id
    employeeId
    employee {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      employeeApprovers{
        levelOneData{
          id
          firstName
          lastName
        }
        levelTwoData{
          id
          firstName
          lastName
        }
        levelThreeData{
          id
          firstName
          lastName
        }
        levelFourData{
          id
          firstName
          lastName
        }
      }
      designation{
        designationName
      }
      employeeApprovers{
        employee{
          firstName
          lastName
        }
      }
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      departmentId
      reportingManager{
        id
        firstName
        lastName
      }
    }
    leaveType
    startDate
    endDate
    totalLeaveDays
    leaveStatus
    employeeComment
    supportingDocuments
    createdAt
    updatedAt
    deletedAt
    }
  }
`;

export const GET_LEAVE_COUNT = gql`
  query getLeaveCount($employeeId: Int!) {
    getLeaveCount(employeeId: $employeeId) {
    CL {
      applied
      pending
    }
    EL {
      applied
      pending
    }
    ML {
      applied
      pending
    }
    EML {
      applied
      pending
    }
    LOP {
      applied
      pending
    }
  }
  }
`;

export const LEAVE_APPROVALS = gql`
  query leaveApprovals($requestId: Int!, $type: LeaveApproverTypesEnum!) {
    leaveApprovals(requestId: $requestId, type: $type) {
    id
    type
    requestId
    approverId
    approver {
      id
      firstName
      lastName
      middleName
      dateOfBirth
      gender
      employeeApprovers{
        levelOne
        levelTwo
        levelThree
        levelFour
      }
      email
      phoneNumber
      alternatePhoneNumber
      address
      permanentAddress
      dateOfJoining
      employmentType
      employeeType
      employeeStatus
      profilePicture
      maritalStatus
      nationality
      passportNumber
      visaType
      visaIssueDate
      visaExpiryDate
      salary
      bankName
      bankAccountNumber
      IFSCCode
      isPortalUser
      departmentId
    }
    vacationRequestData {
      id
      employeeId
      requestDate
      startDate
      endDate
      status
      additionalSalary
    }
    leaveData {
      id
      employeeId
      leaveType
      startDate
      endDate
      totalLeaveDays
      leaveStatus
      employeeComment
      supportingDocuments
      createdAt
      updatedAt
      deletedAt
    }
    approvalStatus
    approvalDate
    comments
    createdAt
    updatedAt
    deletedAt
  }
  }
`;

export const TIMELINES = gql`
  query timelines($type: TimelineTypeEnum, $refId: Int, $activityType: TimelineActivityTypeEnum, $search: String) {
    timelines(type: $type, refId: $refId, activityType: $activityType, search: $search) {
    id
    type
    refId
    activityType
    note
    createdAt
    updatedAt
    deletedAt
  }
  }
`;