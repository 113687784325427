<mat-dialog-content>
    <section class="payroll-approvals-dialog-wrapper">
        <h2 class="cmn-popup-title">{{status ? 'Approval':'Rejection'}} Note</h2>
        <button class="cmn-close" mat-dialog-close ></button>
        <!-- form div -->
        <div>
            <div class="payroll-form-wrapper">
                <div class="form-wrapper">
                    <label for="">Notes</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Reason for {{status ? 'Approval':'Rejection'}}"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="btn-wrapper">
            <button type="submit" class="cmn-popup-btn" (click)="handleClick()">
                {{ status ? 'Approve' : 'Reject' }}
              </button>
              
          </div>
        <!-- form div -->
    </section>
</mat-dialog-content>