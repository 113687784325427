import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { LayoutComponent } from '../../core/layout/layout.component';
import { AddEmployeeComponent } from '../add-employee/add-employee.component';
import { AgGridAngular } from 'ag-grid-angular'; // Angular Data Grid Component
import { ColDef } from 'ag-grid-community'; // Column Definition Type Interface

@Component({
  selector: 'app-employee-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatTabsModule,
    AddEmployeeComponent,
    AgGridAngular,
  ],
  templateUrl: './employee-details.component.html',
  styleUrl: './employee-details.component.scss'
})
export class EmployeeDetailsComponent {

  selectedTabIndex = 0;

  setTab(index: number) {
    this.selectedTabIndex = index;
  }

  public themeClass: string = 'ag-theme-quartz';

  public rowData: any[] | null = [
    {
      make: "Tesla",
      model: "Model Y", 
      price: 64950,
      month: "June",
    }
  ];
  public columnDefs: ColDef[] = [
    { field: "make", flex:1},
    { field: "model", flex:1},
    { field: "price", flex:1},
    {field: "month", flex:1},
  ];
  public defaultColDef: ColDef = {
    filter: "agTextColumnFilter",
    floatingFilter: true,
  };
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];

  

}
