<div class="form-container">
  <form [formGroup]="professionalInfoForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="employeeId">Employee ID:</label>
      <input
        id="employeeId"
        formControlName="employeeId"
        class="form-control"
      />
      <div
        *ngIf="
          professionalInfoForm.get('employeeId')!.invalid &&
          professionalInfoForm.get('employeeId')!.touched
        "
        class="error"
      >
        Employee ID is required.
      </div>
    </div>

    <div class="form-group">
      <label for="currentCTC">Current CTC:</label>
      <input
        id="currentCTC"
        formControlName="currentCTC"
        class="form-control"
      />
      <div
        *ngIf="
          professionalInfoForm.get('currentCTC')!.invalid &&
          professionalInfoForm.get('currentCTC')!.touched
        "
        class="error"
      >
        Current CTC is required.
      </div>
    </div>

    <div class="form-group">
      <label for="employeeType">Employee Type:</label>
      <select
        id="employeeType"
        formControlName="employeeType"
        class="form-control"
      >
        <option value="" disabled>Select Employee Type</option>
        <option value="office">Office</option>
        <option value="contract">Contract</option>
        <option value="part-time">Part-Time</option>
        <option value="temporary">Temporary</option>
      </select>
      <div
        *ngIf="
          professionalInfoForm.get('employeeType')!.invalid &&
          professionalInfoForm.get('employeeType')!.touched
        "
        class="error"
      >
        Employee Type is required.
      </div>
    </div>

    <div class="form-group">
      <label for="email">Email Address:</label>
      <input id="email" formControlName="email" class="form-control" />
      <div
        *ngIf="
          professionalInfoForm.get('email')!.invalid &&
          professionalInfoForm.get('email')!.touched
        "
        class="error"
      >
        Email Address is required.
      </div>
    </div>

    <div class="form-group">
      <label for="workingDays">Working Days:</label>
      <select
        id="workingDays"
        formControlName="workingDays"
        class="form-control"
      >
        <option value="" disabled>Select Working Days</option>
        <option value="male">5</option>
        <option value="female">6</option>
        <option value="other">7</option>
      </select>
      <div
        *ngIf="
          professionalInfoForm.get('workingDays')!.invalid &&
          professionalInfoForm.get('workingDays')!.touched
        "
        class="error"
      >
        Working Days is required.
      </div>
    </div>

    <div class="form-group">
      <label for="joiningDate">Joining Date:</label>
      <input
        id="joiningDate"
        formControlName="joiningDate"
        type="date"
        class="form-control"
        [max]="maxDate"
      />
      <div
        *ngIf="
          professionalInfoForm.get('joiningDate')!.invalid &&
          professionalInfoForm.get('joiningDate')!.touched
        "
        class="error"
      >
        Joining Date is required.
      </div>
    </div>

    <div class="form-group">
      <label for="officeLocation">Office Location:</label>
      <input
        id="officeLocation"
        formControlName="officeLocation"
        class="form-control"
      />
      <div
        *ngIf="
          professionalInfoForm.get('officeLocation')!.invalid &&
          professionalInfoForm.get('officeLocation')!.touched
        "
        class="error"
      >
        Office Location is required.
      </div>
    </div>

    <button
      type="submit"
      [disabled]="professionalInfoForm.invalid"
      class="btn btn-primary"
    >
      Save
    </button>

    <div *ngIf="errorMessage" class="error">
      {{ errorMessage }}
    </div>
  </form>
</div>
