import { gql } from "apollo-angular";

export const UPDATE_PAYROLL = gql`
  mutation updatePayroll( $updatePayrollInput: UpdatePayrollInput!) {
    updatePayroll(updatePayrollInput: $updatePayrollInput) 
    {
    id
    month
    year
    grossPay
    netPay
    deductions
    workDays
    totalEmployees
    additions
    separations
    lockPayroll
    lockInputs
    isProcessed
    employeePayrolls {
      id
      payrollId
      employeeId
      employees{
        firstName
        lastName
      }
      payPeriodStart
      payPeriodEnd
      basicSalary
      overtimePay
      bonuses
      deductions
      netPay
      paymentDate
      paymentMethod
      payslipURL
    }
  }
}
`;

export const EMPLOYEE_PROCESS_PAYROLL = gql`
  mutation processPayrollForEmployee( $employeeId: Int!, $year: Int!, $month: PayrollMonths!) {
    processPayrollForEmployee(employeeId: $employeeId, year: $year, month: $month) 
    {
    employeeId
    payrollId
    basicSalary
    grossPay
    totalBonuses
    totalDeductions
    netPay
  }
}
`;

export const PROCESS_PAYROLL = gql`
  mutation processPayroll($year: Int!, $month: PayrollMonths!) {
    processPayroll(year: $year, month: $month)
}
`;