import {
  ActivatedRouteSnapshot,
  CanActivate,
  GuardResult,
  MaybeAsync,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.authService.isLoggedIn().pipe(
      map(
        (isLoggedIn: boolean) =>
          !!localStorage.getItem('AUTH_TOKEN') ||
          !!sessionStorage.getItem('AUTH_TOKEN')
      ),
      tap((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          localStorage.removeItem('AUTH_TOKEN');
          sessionStorage.removeItem('AUTH_TOKEN');
          this.router.navigate(['/login']);
        }
      })
    );
  }
}
